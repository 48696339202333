import CheckboxSelectAll from "stimulus-checkbox-select-all"

export default class extends CheckboxSelectAll {
    static targets = ["new_options", "list_name", "list_name_message", "all_pages", "existed_options", "dropdown_options", "list_type", "existed_list_val",
        "list_select_message", 'select_introducer_val', 'introducer_dropdown', 'introducer_name_message']

    connect() {
        super.connect()
        console.log("checkbox controller")

        // Get all checked checkboxes
        this.checked

        // Get all unchecked checkboxes
        this.unchecked

        //list name message
        //console.log(this.list_name_messageTarget)

        //list name field
        //console.log(this.list_nameTarget)
    }

    cancelExistedfield(){
        this.existed_optionsTarget.setAttribute("style", "display: none;");
    }
    cancelNew_filed(){
        this.new_optionsTarget.setAttribute("style", "display: none;");
    }

    showOptions(){
        if (this.optionsTarget.style.display === 'none'){
            this.optionsTarget.setAttribute("style", "display: block;");
        }
    }

    showExistedListDropdown(){
        if (this.list_typeTarget.value == "new list"){
            this.existed_list_valTarget.value = ""
            this.existed_optionsTarget.setAttribute("style", "display: none;");
            this.new_optionsTarget.setAttribute("style", "display: block;");
        }else if(this.list_typeTarget.value == "existed list")
        {   this.new_optionsTarget.setAttribute("style", "display: none;");
            this.list_nameTarget.value = ""
            this.existed_optionsTarget.setAttribute("style", "display: block;");
        }else{
            this.new_optionsTarget.setAttribute("style", "display: none;");
            this.existed_optionsTarget.setAttribute("style", "display: none;");
        }
        // show introducer drodown button
        if (window.location.href.includes('all_clients')){
            this.introducer_dropdownTarget.setAttribute("style", "display: block;");
        }
    }

    showDropDownOptions(){
        if (this.dropdown_optionsTarget.style.display === 'none'){
            this.dropdown_optionsTarget.setAttribute("style", "display: block;");
        }
    }

    showListOnSelectedIntroducer(){
        let selectBox = document.getElementById(`existed_list_vals`)
        var introducer_id = this.select_introducer_valTarget.value 
        if (this.select_introducer_valTarget.value != ''){
            this.introducer_name_messageTarget.setAttribute("style", "display: none;")
            Rails.ajax({
                url: `/get_lists_by_introducer/${introducer_id}`,
                type: 'GET',
                dataType: "script",
                error: function (jqXHR, textStatus, errorThrown) {
                    console.log("AJAX Error:", textStatus)
                    alert('There was an error.')
                },
                success: data => {
                    while (selectBox.options.length > 0) {
                        selectBox.remove(0);
                    }
                    const blank = document.createElement('option');
                    blank.value = '';
                    blank.innerHTML = 'Select A List';
                    selectBox.appendChild(blank);
                    let positions = data['introducer_lists'];
                    for (let i = 0; i < positions.length; i++) {
                        const opt = document.createElement('option');
                        opt.value = positions[i]["id"];
                        opt.innerHTML = positions[i]["name"];
                        selectBox.appendChild(opt);
                    }
                }

            })
        }else{
            this.introducer_name_messageTarget.setAttribute("style", "display: block;")
        }

    }
    updateExistedList(){
        event.preventDefault()
        // this.checkIntroducer()
        if(this.existed_list_valTarget.value.length <= 0){
            this.list_select_messageTarget.innerHTML = "Please select list name from dropdown"
            this.list_select_messageTarget.setAttribute("style", "display: block;")
        }
        else if (window.location.href.includes('all_clients') && (this.select_introducer_valTarget.value == '')){
            this.introducer_name_messageTarget.innerHTML = "Please select Introducer name from dropdown."
            this.introducer_name_messageTarget.setAttribute("style", "display: block;")
        }
        else if (window.location.search.length < 3){
            this.list_select_messageTarget.innerHTML = "Please apply some filters first."
            this.list_select_messageTarget.setAttribute("style", "display: block;")
        }
        else {
            this.list_select_messageTarget.setAttribute("style", "display: none;")
            if (this.checked.length < 1){
                this.list_select_messageTarget.innerHTML = "Please check at least one client."
                this.list_select_messageTarget.setAttribute("style", "display: block;")
            }
            else {
                this.list_select_messageTarget.setAttribute("style", "display: none;")
                console.log("passed validation",this.existed_list_valTarget.value, this.checked)
                this.postList()
            }
        }
    }
    createList(){
        event.preventDefault()
        if (this.list_nameTarget.value.length < 3){
            this.list_name_messageTarget.innerHTML = "Please enter a list name for creation."
            this.list_name_messageTarget.setAttribute("style", "display: block;")
            console.log(this.list_name_messageTarget.innerHTML)
        }
        else if (window.location.href.includes('all_clients') && (this.select_introducer_valTarget.value == '')){
            this.introducer_name_messageTarget.innerHTML = "Please select Introducer name from dropdown."
            this.introducer_name_messageTarget.setAttribute("style", "display: block;")
        }
        else if (window.location.search.length < 3){
            this.list_name_messageTarget.innerHTML = "Please apply some filters first."
            this.list_name_messageTarget.setAttribute("style", "display: block;")
        }
        else {
            this.list_name_messageTarget.setAttribute("style", "display: none;")
            if (this.checked.length < 1){
                this.list_name_messageTarget.innerHTML = "Please check at least one client."
                this.list_name_messageTarget.setAttribute("style", "display: block;")
            }
            else {
                this.list_name_messageTarget.setAttribute("style", "display: none;")
                console.log("passed validation",this.list_nameTarget.value, this.checked)
                this.postList()
            }
        }
    }

    postList(){
        let data = new FormData();
        const urlParams = window.location.search.split("?")[1];
        const params = urlParams.split("&");
        let selected_status_ids = []
        let selected_mobile_network_providers = []

        for (let i = 0; i < params.length; i++) {
            let name = `${params[i].split("=")[0]}`;
            if (name.includes('mobile_network_provider')){
                selected_mobile_network_providers.push(params[i].split("=")[1]);
            } else if(name.includes('status_id')){
                selected_status_ids.push(params[i].split("=")[1]);
            } 
            else {
                let form_value = params[i].split("=")[1];
                data.append(name, form_value);
            }
        }

        console.log(selected_status_ids);
        console.log(selected_mobile_network_providers);


        if(selected_mobile_network_providers.length >= 1){
            selected_mobile_network_providers.forEach((value, index) => {
                data.append('mobile_network_provider[]', value)
            });
        }

        if(selected_status_ids.length >= 1){
            selected_status_ids.forEach((value, index) => {
                data.append('status_id[]', value)
            })
        }

        if (this.existed_list_valTarget.value.length > 0) {
            data.append('name', this.existed_list_valTarget.value);
        } else {
            data.append('name', this.list_nameTarget.value);
        }
        if (data.get('email') != ''){
            data.set('email' , data.get('email').replace('%40', '@'))
        }

        data.append('all_pages', 'true');
        data.append('filtered', 'true');

        if (window.location.href.includes('all_clients')){
            data.append('introducer_id', this.select_introducer_valTarget.value)
            Rails.ajax({
                url: 'add_clients_to_introducer_list',
                type: 'POST',
                data: data
            })
        } 
        else {
            Rails.ajax({
                url: 'add_clients_to_list',
                type: 'POST',
                data: data
            })

        }

    }
}