import { Controller } from "stimulus"
export default class extends Controller {
    static targets = ["input", "message", "submit"];

    connect(){
        console.log('post-pack form')
    }

    checkCode(e){
        e.preventDefault();
        console.log('checkCode')

        var code_input = this.inputTarget
        if (code_input.value.length > 8) {
            this.messageTarget.style.display = "none";
            this.inputTarget.classList.remove('border-red-500')
            this.submitTarget.submit()

        }
        else{
            this.inputTarget.classList.add('border-red-500')
            //this.messageTarget.style.display == 'block';
        }

    }
}