
import { Controller } from "stimulus"
export default class extends Controller {
    static targets = [ 'source' ]


    connect() {

        if (document.queryCommandSupported("copy")) {
            console.log('clipboard supported')

        } else {
            document.getElementById('copy-btn').classList.add('hidden')
        }
    }


    copy() {
            var textArea = document.createElement("textarea");
            textArea.value = this.sourceTarget.value
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                console.log('Copying text command was ' + msg);
            } catch (err) {
                console.log('Oops, unable to copy');
            }
            document.body.removeChild(textArea);
        document.getElementById('copy-icon').classList.add('hidden')
        document.getElementById('copy-btn-text').classList.remove('hidden')
        //event.preventDefault()
        //this.sourceTarget.select()
        //document.execCommand("copy")
        //const copyBtnValue = this.sourceTarget.value
        //console.log(copyBtnValue)
        //this.sourceTarget.select();
        //document.execCommand('copy');


    }
};

