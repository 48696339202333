import { Controller } from "stimulus"
export default class extends Controller {
    static targets = ["mobile_phone"];



    connect(){
        console.log('mobile phone validation loaded')
    }
    //client number validation
   checkMobile(e) {



       if (this.mobile_phoneTarget.value.length > 10) {
           Rails.ajax({

               type: 'GET',
               dataType: 'script',
               url: `/validate_number?country_code=gb&number=${number}`,

               error: function (jqXHR, textStatus, errorThrown) {
                   console.log("AJAX Error:", textStatus)
               },
               success: function (data, textStatus, jqXHR) {
                   console.log(data)

               }
           })
       } else {
           console.log('enter more digits for mobile')
       }

   }



}