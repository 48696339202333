// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { Autocomplete } from "stimulus-autocomplete"
import { definitionsFromContext } from "stimulus/webpack-helpers"
// import Flatpickr
import Flatpickr from 'stimulus-flatpickr'
import Reveal from "stimulus-reveal-controller"
import NestedForm from 'stimulus-rails-nested-form'
import AnimatedNumber from 'stimulus-animated-number'

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

import { Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
import Alert from './alert_controller'
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('slideover', Slideover)
application.register('alert', Alert)
application.register("reveal", Reveal)
application.register("autocomplete", Autocomplete)
application.register('nested-form', NestedForm)
// Manually register Flatpickr as a stimulus controller
application.register('flatpickr', Flatpickr)
// Number Animation
application.register('animated-number', AnimatedNumber)

