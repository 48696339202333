import { Controller } from 'stimulus';
import SignaturePad from "signature_pad";
import { PostcodeLookup } from "@ideal-postcodes/postcode-lookup";

export default class extends Controller {
  static targets = ['claim', 'cardDetails', 'checkQuestionModal', 'spouseAnnualIncomeModal', 'annualIncomeModal', 'annualIncomeBtn', 'annualIncome2018',
                     'annualIncome2019', 'annualIncome2020', 'annualIncome2021', 'spouseAnnualIncomeModal', 'spouseAnnualIncomeBtn', 'spouseAnnualIncome2018',
                     'spouseAnnualIncome2019', 'spouseAnnualIncome2020', 'spouseAnnualIncome2021', 'partnerCardModal', 'partnerTitle', 'partnerFirstName', 'partnerLastName',
                     'partnerEmailAddress', 'partnerPhoneNumber', 'cardModal', 'personalDetailsModal', 'addressModal', 'insuranceModal', 'signatureModal',
                     'tryAgainModal', 'marriedAnswer', 'marriageDate', 'selectMarriageDay', 'selectMarriageMonth', 'selectMarriageYear', 'claimProcessStartBtn',
                     'addressDetails', 'selectDobYear', 'selectMonth', 'selectDay', 'addressSelector', 'addressLine1', 'addressLine2', 'addressLine3', 'addressPostTown',
                     'addressPostCode', 'line1', 'line2', 'line3', 'postTown', 'postCode', 'firstName', 'lastName', 'emailAddress', 'phoneNumber', 'questionsModal',
                     'error_text', 'error_text_container', 'enableInsuranceBtn', 'signHerePlaceholder', 'thanksModal', 'claimerName', 'selectYear','modal',
                     'selectedYears', 'selectBank', 'selectedBanks', 'amountReceived', 'submitCompensationBtn', 'selectedEmployment', 'findPostAddress', 'postalAddress',
                     'selectedWashYes', 'selectedWashNo' ,'selectedHomeYes', 'selectedHomeNo', 'selectedPetrolYes', 'selectedPetrolNo' , 'currentEmployment',
                     'selectedPetrolNa', 'selectedMarriedNo', 'selectedMarriedYes', 'ni_error_text', 'closePlus', 'openMinus', 'amountReceivedError', 'signatureError',
                     'submitPostalAddressBtn', 'submitClientDetailsBtn', 'submitClientParterDetailsBtn', 'submitQualifiersBtn', 'checkMarried', 'checkWashUniform',
                     'checkWfh', 'checkPetrol', 'detailsconsentModal', 'checkConsentOne', 'checkConsentTwo', 'checkConsentThree', 'checkConsentFour', 
                     'submitDetailConsentBtn', 'clientFullName', 'title'];
  // connect() {

  // }

    
    openDialog() {
      var dialog = document.getElementById('dialog');
      var closeButton = document.getElementById('close');
      var overlay = document.getElementById('overlay');
      dialog.classList.remove('hidden');
      overlay.classList.remove('hidden');   
    }
 
    closeDialog() {
      var dialog = document.getElementById('dialog');
      var overlay = document.getElementById('overlay');
      dialog.classList.add('hidden');
      overlay.classList.add('hidden');
    }

    openPrivacyPolicy(){
      var dialogPrivacyPolicy = document.getElementById('dialogPrivacyPolicy');
      var overlayPrivacyPolicy = document.getElementById('overlayPrivacyPolicy');
      dialogPrivacyPolicy.classList.remove('hidden');
      overlayPrivacyPolicy.classList.remove('hidden');   
    }

    closePrivacyPolicy(){
      var dialogPrivacyPolicy = document.getElementById('dialogPrivacyPolicy');
      var overlayPrivacyPolicy = document.getElementById('overlayPrivacyPolicy');
      dialogPrivacyPolicy.classList.add('hidden');
      overlayPrivacyPolicy.classList.add('hidden');
    }

    openTermsConditions(){
      var dialogTermsConditions = document.getElementById('dialogTermsConditions');
      var overlayTermsConditions = document.getElementById('overlayTermsConditions');
      dialogTermsConditions.classList.remove('hidden');
      overlayTermsConditions.classList.remove('hidden');   
    }

    closeTermsConditions(){
      var dialogTermsConditions = document.getElementById('dialogTermsConditions');
      var overlayTermsConditions = document.getElementById('overlayTermsConditions');
      dialogTermsConditions.classList.add('hidden');
      overlayTermsConditions.classList.add('hidden');
    }

    openAuthoriseForm(){
      var authoriseForm = document.getElementById('authoriseForm');
      var authoriseOverlay = document.getElementById('authoriseOverlay');
      authoriseForm.classList.remove('hidden');
      authoriseOverlay.classList.remove('hidden');  
    }

    closeAuthoriseForm() {
      var authoriseForm = document.getElementById('authoriseForm');
      var authoriseOverlay = document.getElementById('authoriseOverlay');
      authoriseForm.classList.add('hidden');
      authoriseOverlay.classList.add('hidden');
    }

  checkQuestion() {
    this.scrollAbc();
    this.claimTarget.style.display ='none';
    this.checkQuestionModalTarget.style.display = 'block';
    document.getElementById('checkQuestionForm').scrollIntoView({
      behavior: "smooth",
      block: 'center'
    });
  }

  goToTopAndStart(){
    this.scrollAbc();
    if (document.getElementById("claimBtn").style.display != "none") {
      document.getElementById("claimBtn").style.display ='none';
      document.getElementById("checkQuestionForm").style.display = 'block';
      document.getElementById('checkQuestionForm').scrollIntoView({
        behavior: "smooth",
        block: 'center'
      });
    }
  }

  marriedSelector(e){
    let day = this.selectMarriageDayTarget.value;
    let month = this.selectMarriageMonthTarget.value;
    let year = this.selectMarriageYearTarget.value;
    const selectedMarried = document.querySelectorAll('.selected-button');
    if(selectedMarried.length > 0) {
      selectedMarried.forEach(answer => {
        answer.classList.remove('selected-button');
      });
    }

    if(e.currentTarget.innerHTML === "Yes"){
      this.marriageDateTarget.classList.remove("hidden");
      if(day != '' || month != '' || year != ''){
        this.claimProcessStartBtnTarget.removeAttribute('disabled');
        this.claimProcessStartBtnTarget.classList.add('enabled-button');
        this.claimProcessStartBtnTarget.classList.remove('disabled-button');
      } else {
        this.claimProcessStartBtnTarget.setAttribute('disabled','disabled');
        this.claimProcessStartBtnTarget.classList.add('disabled-button');
        this.claimProcessStartBtnTarget.classList.remove('enabled-button');
      }
    } else {
      this.marriageDateTarget.classList.add("hidden");
      this.claimProcessStartBtnTarget.removeAttribute('disabled');
      this.claimProcessStartBtnTarget.classList.add('enabled-button');
      this.claimProcessStartBtnTarget.classList.remove('disabled-button');
    }
    e.currentTarget.classList.add('selected-button');
  }

  claimProcess() {
    let day = this.selectMarriageDayTarget.value;
    let month = this.selectMarriageMonthTarget.value;
    let year = this.selectMarriageYearTarget.value;
    let marriageDate = new Date(`${year}-${month}-${day}`);
    let data = new FormData();
    let urlAddress = window.location.href.split('/');
    let templateId = window.location.href.split('/').pop();
    let clientPostPackCode = urlAddress[urlAddress.length -2];

    let checkQuestionModalTarget = this.checkQuestionModalTarget;
    let annualIncomeModalTarget = this.annualIncomeModalTarget;

    if(dob != '' && this.marriedAnswerTargets[1].classList.contains('selected-button') != true) {
      // console.log(marriageDate);
      // data.append('template_id', templateId);
      // data.append('marriage_date', marriageDate);
      // data.append('step', 1);
      // console.log(data);
      // Rails.ajax({
      //   url: `/onboarding_ttrt_marriage_update/${clientPostPackCode}`,
      //   type: "patch",
      //   data: data,
      //   success: function(data) {
      //     console.log(data);
          checkQuestionModalTarget.style.display ='none';
          annualIncomeModalTarget.style.display = 'block';
          document.getElementById('annualIncomeForm').scrollIntoView({
            behavior: "smooth",
            block: 'center'
          });
      //   },
      //   error: function(data) {}
      // })
    } else {
      checkQuestionModalTarget.style.display ='none';
      this.tryAgainModalTarget.style.display = 'block';
      document.getElementById('tryAgainForm').scrollIntoView({
        behavior: "smooth",
        block: 'center'
      });
    }
  }

  tryAgainBtn(){
    this.tryAgainModalTarget.style.display = 'none';
    this.checkQuestionModalTarget.style.display = 'block';
    document.getElementById('checkQuestionForm').scrollIntoView({
      behavior: "smooth",
      block: 'center'
    });
  }

  submitAnnualIncome(){
    this.annualIncomeModalTarget.style.display = 'none';
    this.spouseAnnualIncomeModalTarget.style.display = 'block';
    document.getElementById('spouseAnnualIncomeForm').scrollIntoView({
      behavior: "smooth",
      block: 'center'
    });
    
    window.PostcodeLookup = PostcodeLookup;
    PostcodeLookup.setup({
      context: "#postcode_lookup_field",
      apiKey: "ak_l3jx9ksxXzVXkIr1BMiX1AVixM721",
      outputFields: {
        line_1: "#line_1",
        line_2: "#line_2",
        line_3: "#line_3",
        post_town: "#post_town",
        postcode: "#postcode"
      }
    });
  }

  submitSpouseAnnualIncome(){
    this.spouseAnnualIncomeModalTarget.style.display = 'none';
    this.cardModalTarget.style.display = 'block';
    document.getElementById('spouseAnnualIncomeForm').scrollIntoView({
        behavior: "smooth",
        block: 'center'
      });
  }

  submitDetailConsent(){
    this.detailsconsentModalTarget.style.display ='none';
    this.signatureModalTarget.style.display = 'block';
    document.getElementById('signatureForm').scrollIntoView({
      behavior: "smooth",
      block: 'start'
    });
    this.scrollAbc();
  }
  submitPersonalDetails() {
    let firstName = this.firstNameTarget.value;
    let lastName = this.lastNameTarget.value;
    let emailAddress = this.emailAddressTarget.value;
    let phoneNumber = this.phoneNumberTarget.value;
    let day = this.selectDayTarget.value;
    let month = this.selectMonthTarget.value;
    let year = this.selectDobYearTarget.value;
    let title = this.titleTarget.value;
    let dob = new Date(`${year}-${month}-${day}`);
    let termsCheckBox = document.querySelector('.checkmark-input').checked;
    let data = new FormData();
    let urlAddress = window.location.href.split('/');
    let templateId = window.location.href.split('/').pop();
    let clientPostPackCode = urlAddress[urlAddress.length -2];
    this.claimerNameTargets.forEach( (elem) => { elem.innerHTML = firstName } )


    let cardModal = this.cardModalTarget;
    let addressModal = this.addressModalTarget;


    if(emailAddress != '' && /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(emailAddress) && firstName != '' && lastName != '' && /^(01|07)\d{9}$/.test(phoneNumber) && phoneNumber != '' && dob != '' && termsCheckBox == true) {

      data.append('title', title);
      data.append('first_name', firstName);
      data.append('last_name', lastName);
      data.append('email', emailAddress);
      data.append('mobile_phone', phoneNumber);
      data.append('template_id', templateId);
      data.append('date_of_birth', dob);
      data.append('step', 4);

      Rails.ajax({
        url: `/onboarding_ttrt_marriage_update/${clientPostPackCode}`,
        type: "patch",
        data: data,
        success: function(data) {
          console.log(data);
          cardModal.style.display = 'none';
          addressModal.style.display = 'block';
          document.getElementById('postalCodeForm').scrollIntoView({
            behavior: "smooth",
            block: "center"
          });
        },
        error: function(data) {}
      })
      document.querySelector('.idpc-button').setAttribute('data-action', 'onboarding-ttrt-marriage#displaySearchAddress');
      document.querySelector('.idpc-button').setAttribute('data-target', 'onboarding-ttrt-marriage.findPostAddress');
      document.querySelector('.idpc-input').classList.add('w-full');
      document.querySelector('.idpc-input').setAttribute('data-action', 'onboarding-ttrt-marriage#handlePostalAddress');
      document.querySelector('.idpc-input').setAttribute('data-target', 'onboarding-ttrt-marriage.postalAddress');
      document.querySelector('.idpc-input').style.cssText = "--tw-ring-color: #F8F8F8; border-color: darkgray; padding-top: 15px; padding-bottom: 15px; border-radius: 4px;";
      document.querySelector('.idpc-button').classList.add('inline-flex', 'items-center',
                                                           'my-5', 'px-4', 'py-3', 'border', 'border-transparent',
                                                           'text-xs', 'font-bold', 'rounded-lg', 'text-white',
                                                           'focus:outline-none');
      document.querySelector('.idpc-button').style.cssText = "background-color: #6998ab;";
      let spanElement = document.createElement('span');
      spanElement.classList.add('lg:ml-10', 'ml-22', '-mr-1', 'h-5', 'w-5', 'mb-1', 'pt-1.5', 'text-right');
      let fontElement = document.createElement('i');
      fontElement.classList.add('fas', 'fa-arrow-right');
      spanElement.appendChild(fontElement);
      document.querySelector('.idpc-button').appendChild(spanElement);
    }
  }

  submitPartnerDetails() {
    let firstName = this.partnerFirstNameTarget.value;
    let lastName = this.partnerLastNameTarget.value;
    let emailAddress = this.partnerEmailAddressTarget.value;
    let phoneNumber = this.partnerPhoneNumberTarget.value;
    let title = this.partnerTitleTarget.value;
    let data = new FormData();
    let urlAddress = window.location.href.split('/');
    let templateId = window.location.href.split('/').pop();
    let clientPostPackCode = urlAddress[urlAddress.length -2];
    this.claimerNameTargets.forEach( (elem) => { elem.innerHTML = firstName } )


    let partnerCardModal = this.partnerCardModalTarget;
    let insuranceModal = this.insuranceModalTarget;


    if(emailAddress != '' && /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(emailAddress) && firstName != '' && lastName != '' && /^(01|07)\d{9}$/.test(phoneNumber) && phoneNumber != '') {

      // data.append('partner_title', title);
      // data.append('partner_first_name', firstName);
      // data.append('partner_last_name', lastName);
      // data.append('partner_email', emailAddress);
      // data.append('partner_mobile_phone', phoneNumber);
      // data.append('template_id', templateId);
      // data.append('step', 5);

      // Rails.ajax({
      //   url: `/onboarding_ttrt_marriage_update/${clientPostPackCode}`,
      //   type: "patch",
      //   data: data,
      //   success: function(data) {
      //     console.log(data);
          partnerCardModal.style.display = 'none';
          insuranceModal.style.display = 'block';
          document.getElementById('insuranceForm').scrollIntoView({
            behavior: "smooth",
            block: "center"
          });
      //   },
      //   error: function(data) {}
      // })
    }
  }

  displaySearchAddress()
  {
    document.querySelector('.idpc-button').click();
    this.waitForElement('.idpc-select').then((elm) => {
      document.querySelector('.idpc-select').setAttribute('data-action', 'onboarding-ttrt-marriage#populateAddress');
      document.querySelector('.idpc-select').classList.add('w-full', 'mt-4');
      document.querySelector('.idpc-select').style.cssText = "--tw-ring-color: #F8F8F8; border-color: darkgray; padding-top: 15px; padding-bottom: 15px; border-radius: 4px;";
      document.querySelector('.idpc-button').style.cssText = "background-color: #6998ab;";
    });

    if(!(document.querySelector('.idpc-select'))) {
      this.clearPostalAddress();
    }

    this.waitForElement('.idpc-button').then((element) => {
      let select_address = document.querySelector('.idpc-select-container');
      this.swapFields(element, select_address);
      element.style.cssText = "background-color: #A1BFC6;";
      document.querySelector('.idpc-button').style.cssText = "background-color: #6998ab;";
      let spanElement = document.createElement('span');
      spanElement.classList.add('lg:ml-10', 'ml-22', '-mr-1', 'h-5', 'w-5', 'mb-1', 'pt-1.5', 'text-right');
      let fontElement = document.createElement('i');
      fontElement.classList.add('fas', 'fa-arrow-right');
      spanElement.appendChild(fontElement);
      if (element.childElementCount > 0) {
        element.removeChild(spanElement);
      }
      this.findPostAddressTarget.appendChild(spanElement);
      element.appendChild(spanElement);
    });
    // document.querySelector('.idpc-select').setAttribute('data-onboarding-ppi-target', 'addressSelector');
  }

  scrollAbc() {
    window.scrollTo({top: 100, behavior: 'smooth'});
    // this.checkQuestion();
  }

  waitForElement(selector) {
    return new Promise(resolve => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(mutations => {
            if (document.querySelector(selector)) {
                resolve(document.querySelector(selector));
                observer.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
  }

  populateAddress(){
    this.addressLine1Target.innerHTML = this.line1Target.value;
    this.addressLine2Target.innerHTML = this.line2Target.value;
    this.addressLine3Target.innerHTML = this.line3Target.value;
    this.addressPostTownTarget.innerHTML = this.postTownTarget.value;
    this.addressPostCodeTarget.innerHTML = this.postCodeTarget.value;
    this.submitPostalAddressBtnTarget.removeAttribute('disabled');
    this.submitPostalAddressBtnTarget.classList.remove('disabled-button');
    this.submitPostalAddressBtnTarget.classList.add('enabled-button');  
  }

  clearPostalAddress(){
    this.addressLine1Target.innerHTML = ''
    this.addressLine2Target.innerHTML = ''
    this.addressLine3Target.innerHTML = ''
    this.addressPostTownTarget.innerHTML = ''
    this.addressPostCodeTarget.innerHTML = ''
  }

  handlePostalAddress(){
    if(document.querySelector('.idpc-select')){
      document.querySelector('.idpc-select').style.display = 'none'
      this.addressLine1Target.innerHTML = ''
      this.addressLine2Target.innerHTML = ''
      this.addressLine3Target.innerHTML = ''
      this.addressPostTownTarget.innerHTML = ''
      this.addressPostCodeTarget.innerHTML = ''
      this.submitPostalAddressBtnTarget.setAttribute('disabled', true);
      this.submitPostalAddressBtnTarget.classList.add('disabled-button');
      this.submitPostalAddressBtnTarget.classList.remove('enabled-button');
    }
  }

  submitPostalDetails() {
    if(document.querySelector('.idpc-error').style.display == 'none'){
      let addressModal = this.addressModalTarget;
      let insuranceModal = this.insuranceModalTarget;
      let data = new FormData();
      let urlAddress = window.location.href.split('/');
      let templateId = window.location.href.split('/').pop();
      let clientPostPackCode = urlAddress[urlAddress.length -2];
      let address1 = this.addressLine1Target.innerHTML;
      let address2 = this.addressLine2Target.innerHTML + ' ' + this.addressLine3Target.innerHTML;
      let postCode = this.addressPostCodeTarget.innerHTML;
      // let postCity = document.getElementById('address_post_town').innerHTML;
      let postCity = this.addressPostTownTarget.innerHTML;
      
      data.append('address1', address1);
      data.append('address2', address2);
      data.append('postcode', postCode);
      data.append('city', postCity);
      data.append('step', 5);
      data.append('template_id', templateId);
      Rails.ajax({
        url: `/onboarding_ttrt_marriage_update/${clientPostPackCode}`,
        type: 'patch',
        data: data,
        success: function(data) {
          console.log(data);
          addressModal.style.display = 'none';
          insuranceModal.style.display = 'block';
          document.getElementById('insuranceForm').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        },
        error: function(data) {}
      })
    }
  }

  showContent(e) {
    if(e.currentTarget.closest('dl').childNodes[3].classList.contains('hidden')) {
      e.currentTarget.closest('dl > dt > button').childNodes[1].style.color="#E1A122"
      e.currentTarget.closest('dl > dt > button').style.color="#E1A122"
      e.currentTarget.closest('dl > dt > button').childNodes[3].classList.add('hidden')
      e.currentTarget.closest('dl > dt > button').childNodes[5].classList.remove('hidden')
      e.currentTarget.closest('dl').childNodes[3].classList.remove('hidden')
    }
    else {
      e.currentTarget.closest('dl > dt > button').childNodes[1].style.color="#233E97"
      e.currentTarget.closest('dl > dt > button').style.color="#233E97"
      e.currentTarget.closest('dl > dt > button').childNodes[3].classList.remove('hidden')
      e.currentTarget.closest('dl > dt > button').childNodes[5].classList.add('hidden')
      e.currentTarget.closest('dl').childNodes[3].classList.add('hidden')
    }
  }

  multiBankSelector(e) {

    if(this.selectedBanksTarget.value.split(',').indexOf(e.currentTarget.innerHTML) === -1) {
      e.currentTarget.classList.add('selected-button');
      this.selectedBanksTarget.value = this.selectedBanksTarget.value + ',' + e.currentTarget.innerHTML
    }
    else {
      e.currentTarget.classList.remove('selected-button');
      let result = this.removeArrayElement(this.selectedBanksTarget.value.split(','), e.currentTarget.innerHTML)
      this.selectedBanksTarget.value = result.join(',')
    }

    if(this.selectedBanksTarget.value.length > 2) {
      this.employmentBtnTarget.removeAttribute('disabled');
      this.employmentBtnTarget.classList.remove('disabled-button');
      this.employmentBtnTarget.classList.add('enabled-button');
    }
    else {
      this.employmentBtnTarget.setAttribute('disabled', true);
      this.employmentBtnTarget.classList.add('disabled-button');
      this.employmentBtnTarget.classList.remove('enabled-button');
    }
    if (this.selectedBanksTarget.value.includes('Other')){
      if(window.screen <= 768){
      this.lenderOtherBankModalTarget.style.display = 'block';}
      else {
      this.lenderOtherBankMobileModalTarget.style.display = 'block';}
    }else {
      if(window.screen <= 768){
      this.lenderOtherBankModalTarget.style.display = 'none';}
      else {
      this.lenderOtherBankMobileModalTarget.style.display = 'none';}
    }
    console.log(this.selectedBanksTarget.value) // Selected Banks
  }

  multiYearSelector(e) {
    const selectedEmployments = document.querySelectorAll('.selected-button');
    if(selectedEmployments.length > 0) {
      selectedEmployments.forEach(employment => {
        employment.classList.remove('selected-button');
      });
    }
    
    if(Object.values(e.currentTarget.classList).indexOf('selected-button') === -1) {
      e.currentTarget.classList.add('selected-button');
      this.selectedYearsTarget.value = e.currentTarget.innerHTML;
    }

    if((this.amountReceivedTargets[0].value.length > 0 || this.amountReceivedTargets[1].value.length > 0) && this.selectedYearsTarget.value.length > 2) {
      this.submitCompensationBtnTarget.removeAttribute('disabled');
      this.submitCompensationBtnTarget.classList.add('enabled-button');
      this.submitCompensationBtnTarget.classList.remove('disabled-button');
    }
    else {
      this.submitCompensationBtnTarget.setAttribute('disabled', true);
      this.submitCompensationBtnTarget.classList.add('disabled-button');
      this.submitCompensationBtnTarget.classList.remove('enabled-button');
    }
  }

  amountField(e) {
    let net = 0.00
    if (e.target.value.length > 0){
      let tax = (e.target.value) * (11 / 100)
      // let gross = tax * 5
      // net = gross - tax
      // this.amountDeductedTarget.value = net.toFixed(2)
      this.amountDeductedTarget.value = tax.toFixed(2)

    }else{
      this.amountDeductedTarget.value = ''
    }
    if(e.target.value.length > 0 && this.selectedYearsTarget.value.length > 2) {
      this.submitCompensationBtnTarget.removeAttribute('disabled');
      this.submitCompensationBtnTarget.classList.add('enabled-button');
      this.submitCompensationBtnTarget.classList.remove('disabled-button');
    }
    else {
      this.submitCompensationBtnTarget.setAttribute('disabled', true);
      this.submitCompensationBtnTarget.classList.add('disabled-button');
      this.submitCompensationBtnTarget.classList.remove('enabled-button');
    }
  }

  validateMarriageDetails(e) {
    let allDetailsVerified = true;
    let day = this.selectMarriageDayTarget.value;
    let month = this.selectMarriageMonthTarget.value;
    let year = this.selectMarriageYearTarget.value;
    switch (e.currentTarget.id) {
      case "marriageDay":
        this.selectMarriageDayTarget.classList.add("selected-option");
      break;
      case "marriageMonth":
        this.selectMarriageMonthTarget.classList.add("selected-option");
      break;
      case "marriageYear":
        this.selectMarriageYearTarget.classList.add("selected-option");
      break;
    }

    if (day == '' || month == '' || year == '') {
      allDetailsVerified = false;
    } 

    if (allDetailsVerified == false){
      this.claimProcessStartBtnTarget.setAttribute('disabled', true);
      this.claimProcessStartBtnTarget.classList.add('disabled-button');
      this.claimProcessStartBtnTarget.classList.remove('enabled-button');
    }
    else {
      console.log('good to go');
      this.claimProcessStartBtnTarget.removeAttribute('disabled');
      this.claimProcessStartBtnTarget.classList.remove('disabled-button');
      this.claimProcessStartBtnTarget.classList.add('enabled-button');
    }
  }

  validateAnnualIncome(e) {
    let allDetailsVerified = true;
    let y2018 = this.annualIncome2018Target.value;
    let y2019 = this.annualIncome2019Target.value;
    let y2020 = this.annualIncome2020Target.value;
    let y2021 = this.annualIncome2021Target.value;
    switch (e.currentTarget.id) {
      case "annualIncome2018":
        this.annualIncome2018Target.classList.add("selected-option");
      break;
      case "annualIncome2019":
        this.annualIncome2019Target.classList.add("selected-option");
      break;
      case "annualIncome2020":
        this.annualIncome2020Target.classList.add("selected-option");
      break;
      case "annualIncome2021":
        this.annualIncome2021Target.classList.add("selected-option");
      break;
    }

    if (y2018 == '' || y2019 == '' || y2020 == '' || y2021 == '') {
      allDetailsVerified = false;
    } 

    if (allDetailsVerified == false){
      this.annualIncomeBtnTarget.setAttribute('disabled', true);
      this.annualIncomeBtnTarget.classList.add('disabled-button');
      this.annualIncomeBtnTarget.classList.remove('enabled-button');
    }
    else {
      console.log('good to go');
      this.annualIncomeBtnTarget.removeAttribute('disabled');
      this.annualIncomeBtnTarget.classList.remove('disabled-button');
      this.annualIncomeBtnTarget.classList.add('enabled-button');
    }
  }

  validateSpouseAnnualIncome(e) {
    let allDetailsVerified = true;
    let y2018 = this.spouseAnnualIncome2018Target.value;
    let y2019 = this.spouseAnnualIncome2019Target.value;
    let y2020 = this.spouseAnnualIncome2020Target.value;
    let y2021 = this.spouseAnnualIncome2021Target.value;
    switch (e.currentTarget.id) {
      case "spouseAnnualIncome2018":
        this.spouseAnnualIncome2018Target.classList.add("selected-option");
      break;
      case "spouseAnnualIncome2019":
        this.spouseAnnualIncome2019Target.classList.add("selected-option");
      break;
      case "spouseAnnualIncome2020":
        this.spouseAnnualIncome2020Target.classList.add("selected-option");
      break;
      case "spouseAnnualIncome2021":
        this.spouseAnnualIncome2021Target.classList.add("selected-option");
      break;
    }

    if (y2018 == '' || y2019 == '' || y2020 == '' || y2021 == '') {
      allDetailsVerified = false;
    } 

    if (allDetailsVerified == false){
      this.spouseAnnualIncomeBtnTarget.setAttribute('disabled', true);
      this.spouseAnnualIncomeBtnTarget.classList.add('disabled-button');
      this.spouseAnnualIncomeBtnTarget.classList.remove('enabled-button');
    }
    else {
      console.log('good to go');
      this.spouseAnnualIncomeBtnTarget.removeAttribute('disabled');
      this.spouseAnnualIncomeBtnTarget.classList.remove('disabled-button');
      this.spouseAnnualIncomeBtnTarget.classList.add('enabled-button');
    }
  }

  validateDetailConsent() {
    let allDetailsVerified = true;
    let checkConsentone = this.checkConsentOneTarget.checked
    let checkConsentwo = this.checkConsentTwoTarget.checked;
    let checkConsentthree = this.checkConsentThreeTarget.checked;
    let checkConsentfour = this.checkConsentFourTarget.checked;

    if (checkConsentone && checkConsentwo && checkConsentthree && checkConsentfour){
      console.log('good to go');
      this.submitDetailConsentBtnTarget.removeAttribute('disabled');
      this.submitDetailConsentBtnTarget.classList.remove('disabled-button');
      this.submitDetailConsentBtnTarget.classList.add('enabled-button');
    }
    else {
      this.submitDetailConsentBtnTarget.setAttribute('disabled', true);
      this.submitDetailConsentBtnTarget.classList.add('disabled-button');
      this.submitDetailConsentBtnTarget.classList.remove('enabled-button');
    }
  }


  validateClientDetails(e) {
    let allDetailsVerified = true;
    let firstName = this.firstNameTarget.value;
    let lastName = this.lastNameTarget.value;
    let emailAddress = this.emailAddressTarget.value;
    let phoneNumber = this.phoneNumberTarget.value;
    let day = this.selectDayTarget.value;
    let month = this.selectMonthTarget.value;
    let year = this.selectDobYearTarget.value;
    let title = this.titleTarget.value;
    switch (e.currentTarget.id) {
      case "title":
        this.titleTarget.classList.add("selected-option");
      break;
      case "day":
        this.selectDayTarget.classList.add("selected-option");
      break;
      case "month":
        this.selectMonthTarget.classList.add("selected-option");
      break;
      case "year":
        this.selectDobYearTarget.classList.add("selected-option");
      break;
    }

    if (firstName == '' || lastName == '' || day == '' || month == '' || year == '' || title == '') {
      allDetailsVerified = false;
    } 

    if (emailAddress == '' || !(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(emailAddress))) {
      allDetailsVerified = false;
    }

    if (phoneNumber == '' || !(/^(01|07)\d{9}$/.test(phoneNumber))) {
      allDetailsVerified = false;
    }

    if(!(document.querySelector('.checkmark-input').checked)) {
      allDetailsVerified = false;
    }

    if (allDetailsVerified == false){
      this.submitClientDetailsBtnTarget.setAttribute('disabled', true);
      this.submitClientDetailsBtnTarget.classList.add('disabled-button');
      this.submitClientDetailsBtnTarget.classList.remove('enabled-button');
    }
    else {
      console.log('good to go');
      this.submitClientDetailsBtnTarget.removeAttribute('disabled');
      this.submitClientDetailsBtnTarget.classList.remove('disabled-button');
      this.submitClientDetailsBtnTarget.classList.add('enabled-button');
    }
  }

  validateEmailAddress(e) {
    let emailAddress = e.target.value;
    let firstName = this.firstNameTarget.value;
    let lastName = this.lastNameTarget.value;
    let phoneNumber = this.phoneNumberTarget.value;
    let day = this.selectDayTarget.value;
    let month = this.selectMonthTarget.value;
    let year = this.selectDobYearTarget.value;
    let allDetailsVerified = true;

    document.querySelector('.required-email-error').innerHTML = 'Hello';
    document.querySelector('.required-email-error').style.cssText = 'opacity: 0';
    setTimeout(() => {  
      if(emailAddress == '') {
      document.querySelector('.required-email-error').style.cssText = 'opacity: 1';
      document.querySelector('.required-email-error').style.cssText = 'color: #E10000;';
      document.querySelector('.required-email-error').innerHTML = 'Please, enter your email';
    }
    else if(!(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(emailAddress))) {
      document.querySelector('.required-email-error').style.cssText = 'opacity: 1';
      document.querySelector('.required-email-error').style.cssText = 'color: #E10000;';
      document.querySelector('.required-email-error').innerHTML = 'Email is not correct';
    }
    else {
      document.querySelector('.required-email-error').innerHTML = 'Hello';
      document.querySelector('.required-email-error').style.cssText = 'opacity: 0';
    }
     }, 1000);

    if (firstName == '' || lastName == '' || day == '' || month == '' || year == '') {
      allDetailsVerified = false;
    } 

    if (emailAddress == '' || !(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(emailAddress))) {
      allDetailsVerified = false;
    }

    if (phoneNumber == '' || !(/^(01|07)\d{9}$/.test(phoneNumber))) {
      allDetailsVerified = false;
    }

    if(!(document.querySelector('.checkmark-input').checked)) {
      allDetailsVerified = false;
    }

    if (allDetailsVerified == false){
      this.submitClientDetailsBtnTarget.setAttribute('disabled', true);
      this.submitClientDetailsBtnTarget.classList.add('disabled-button');
      this.submitClientDetailsBtnTarget.classList.remove('enabled-button');
    }
    else {
      console.log('good to go');
      this.submitClientDetailsBtnTarget.removeAttribute('disabled');
      this.submitClientDetailsBtnTarget.classList.remove('disabled-button');
      this.submitClientDetailsBtnTarget.classList.add('enabled-button');
    }
  }

  validatePhoneNumber(e) {
    let phoneNumber = e.target.value;
    let firstName = this.firstNameTarget.value;
    let lastName = this.lastNameTarget.value;
    let emailAddress = this.emailAddressTarget.value;
    let day = this.selectDayTarget.value;
    let month = this.selectMonthTarget.value;
    let year = this.selectDobYearTarget.value;
    let allDetailsVerified = true;


    document.querySelector('.user-phonenumber-error').innerHTML = 'Hello';
    document.querySelector('.user-phonenumber-error').style.cssText = 'opacity: 0';

    setTimeout(() => {  
      if(phoneNumber == '' || !(/^(01|07)\d{9}$/.test(phoneNumber))) {
      document.querySelector('.user-phonenumber-error').style.cssText = 'opacity: 1';
      document.querySelector('.user-phonenumber-error').style.cssText = 'color: #E10000;';
      document.querySelector('.user-phonenumber-error').innerHTML = 'Please enter a valid phone number';
    }
    else {
      document.querySelector('.user-phonenumber-error').innerHTML = 'Hello';
      document.querySelector('.user-phonenumber-error').style.cssText = 'opacity: 0';
    }
     }, 1000);

    if (firstName == '' || lastName == '' || day == '' || month == '' || year == '') {
      allDetailsVerified = false;
    } 

    if (emailAddress == '' || !(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(emailAddress))) {
      allDetailsVerified = false;
    }

    if (phoneNumber == '' || !(/^(01|07)\d{9}$/.test(phoneNumber))) {
      allDetailsVerified = false;
    }

    if(!(document.querySelector('.checkmark-input').checked)) {
      allDetailsVerified = false;
    }

    if (allDetailsVerified == false){
      this.submitClientDetailsBtnTarget.setAttribute('disabled', true);
      this.submitClientDetailsBtnTarget.classList.add('disabled-button');
      this.submitClientDetailsBtnTarget.classList.remove('enabled-button');
    }
    else {
      console.log('good to go');
      this.submitClientDetailsBtnTarget.removeAttribute('disabled');
      this.submitClientDetailsBtnTarget.classList.remove('disabled-button');
      this.submitClientDetailsBtnTarget.classList.add('enabled-button');
    }
  }

  validateNiNumber(e){
    let regex = new RegExp("^(?!BG|GB|KN|NK|NT|TN|ZZ)[^DFIQUV][^DFIQUVo][0-9]{6}[ABCD]$")

    if (e.target.value.length == 2) {
      let class_lists = Object.values(e.target.classList);
      let class_list = class_lists.find(val => val.includes('inputs'));
      let val = parseInt(class_list.split('_').pop()) + 1;
      document.querySelector(`.inputs_${val}`).focus();
    }

    let ni_number = document.querySelector('#nin-char-2').value + document.querySelector('#nin-number-1').value +
                     document.querySelector('#nin-number-2').value + document.querySelector('#nin-number-3').value +
                     document.querySelector('#nin-char-1').value;

    if(ni_number.length === 9 && this.stripNi(ni_number).match(regex)){
     this.enableInsuranceBtnTarget.removeAttribute('disabled');
     this.enableInsuranceBtnTarget.classList.remove('disabled-button');
     this.enableInsuranceBtnTarget.classList.add('enabled-button'); 
    }
    else {
      this.enableInsuranceBtnTarget.setAttribute('disabled', true);
      this.enableInsuranceBtnTarget.classList.add('disabled-button');
      this.enableInsuranceBtnTarget.classList.remove('enabled-button');
    }
  }

  submitInsuranceDetails(e) {
    let insuranceModal = this.insuranceModalTarget;
    let detailsconsentModal = this.detailsconsentModalTarget;
    let clientFullName = this.clientFullNameTarget;


    let data = new FormData();
    
    let urlAddress = window.location.href.split('/');
    let templateId = window.location.href.split('/').pop();
    let clientPostPackCode = urlAddress[urlAddress.length - 2];
    data.append('step', 6);
    data.append('template_id', templateId);
    let ni_number = document.querySelector('#nin-char-2').value + document.querySelector('#nin-number-1').value +
                     document.querySelector('#nin-number-2').value + document.querySelector('#nin-number-3').value +
                     document.querySelector('#nin-char-1').value;

    data.append('ni_number', ni_number.toUpperCase().replace(/ /g,''));

    Rails.ajax({
      url: `/onboarding_ttrt_marriage_update/${clientPostPackCode}`,
      type: 'patch',
      data: data,
      success: function(data) {
        console.log(document.getElementById('detailspostcode'));
        console.log(document.getElementById('detailspostcity'));
        insuranceModal.style.display = 'none';
        detailsconsentModal.style.display = 'block';
        let title =  data['title'] != '' ? data['title'] : "" ;
        let first_name =  data['first_name'] != '' ? data['first_name'] : "" ;
        let last_name =  data['last_name'] != '' ? data['last_name'] : "" ;
        let dob = data['date_of_birth'].split('-');
        dob = `${dob[2]}/${dob[1]}/${dob[0]}`;
        document.getElementById('full_name').textContent = title + ' ' + first_name + ' ' + last_name ;
        document.getElementById('address1').textContent = data['address1'] ;
        document.getElementById('address2').textContent = data['address2'] ;
        document.getElementById('detailspostcity').textContent = data['city'];
        document.getElementById('detailspostcode').textContent = data['postcode'];
        document.getElementById('dob').textContent = dob ;
        document.getElementById('ni_number').textContent = data['ni_number'];

        // display here ppis tax claims
        data['ppis'].forEach(function (item, index) {
          console.log(item, index);
          let itemDate = item['offer_date'].split('-')[0];
          let itemTax = parseFloat(item['net']).toFixed(2);
          document.getElementById('marriage-claim').innerHTML += "<span class='font-semibold'>Marriage date:</span> <span class='font-normal' >" + item['marriage_date'] + "</span><br><br>"
          document.getElementById('marriage-claim').innerHTML += "<span class='font-semibold'>Applicable tax years:</span><br><span class='font-normal' >"+ item['offer_amount'] + "</span><br><span class='font-normal' >"+ item['offer_amount'] + "</span><br><br>"
          document.getElementById('marriage-claim').innerHTML += "<span class='font-semibold'>Partner’s name:</span> <span class='font-normal' >"+ itemTax + "</span><br><br>"  
        });
        document.getElementById('detailsconsentForm').scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      },
      error: function(data) {
        
      }
    })

    //  Initialize signature
    const canvas = document.querySelector('canvas');
    if (canvas){
         //this.resizeCanvas(canvas);
       this.signature_pad = new SignaturePad(canvas, {drawOnly:true});
    }
  }

  canvasDraw(){
    // this.signatureBtnTarget.classList.remove('disabled-button');
    // this.signatureBtnTarget.classList.add('enabled-button');
    // this.signatureBtnTarget.removeAttribute('disabled');
    this.signHerePlaceholderTarget.classList.add('hidden');
  }

  stripNi(ni_value){
    return ni_value.toUpperCase().replace(/ /g,'')
  }

  signature(event){
    let dots = this.signature_pad.toData()
    let signatureModal = this.signatureModalTarget;
    let questionsModal = this.questionsModalTarget;
    let thanksModal = this.thanksModalTarget;
    let data = new FormData();
    let urlAddress = window.location.href.split('/');
    let templateId = window.location.href.split('/').pop();
    let clientPostPackCode = urlAddress[urlAddress.length - 2];
    data.append('step', 7);
    data.append('template_id', templateId);

    if(this.signature_pad.isEmpty()) {
      event.preventDefault();
      this.signatureErrorTarget.classList.remove('hide')
      this.signatureErrorTarget.innerHTML = 'Please sign before proceeding'
    }
    else if (dots && dots[0].points.length <= 10) {
      event.preventDefault();
      this.signatureErrorTarget.classList.remove('hide')
      this.signatureErrorTarget.innerHTML = 'Please add a full signature. Clear and try again'
     }
     else if(!document.querySelector('.signature-checkbox').checked){
      event.preventDefault();
      this.signatureErrorTarget.classList.remove('hide');
      this.signatureErrorTarget.innerHTML = 'Please tick the box below to proceed';
     }
     else {
      event.preventDefault();
      data.append('signature', this.signature_pad.toDataURL());

      Rails.ajax({
        url: `/onboarding_ttrt_marriage_update/${clientPostPackCode}`,
        type: 'patch',
        data: data,
        success: function(data) {
          signatureModal.style.display = 'none';
          thanksModal.style.display = 'block';
          document.getElementById('thanksForm').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        },
        error: function(data) {}
      })
    }
  }

  clear() {
    this.signature_pad.clear();
    this.signHerePlaceholderTarget.classList.remove('hidden');
  }

  submitQuestionaire() {
    let questionsModal = this.questionsModalTarget;
    let thanksModal = this.thanksModalTarget;
    let married, required_uniform, asked_to_work_from_home, vehicle_required;
    let data = new FormData();
    let urlAddress = window.location.href.split('/');
    let templateId = window.location.href.split('/').pop();
    let clientPostPackCode = urlAddress[urlAddress.length - 2];

    if (this.checkMarriedTargets[0].checked){
      married = true;
    } else{
      married = false;
    }
    if (this.checkWashUniformTargets[0].checked){
      required_uniform = true;
    } else{
      required_uniform = false;
    }
    if(this.checkWfhTargets[0].checked){
      asked_to_work_from_home = true;
    } else{
      asked_to_work_from_home = false;
    }
    if(this.checkPetrolTargets[0].checked){
      vehicle_required = true;
    } else{
      vehicle_required = false;
    }

    data.append('template_id', templateId);
    data.append('step', 8);
    data.append('married', married);
    data.append('required_uniform', required_uniform);
    data.append('asked_to_work_from_home', asked_to_work_from_home);
    data.append('vehicle_required', vehicle_required);


    Rails.ajax({
      url: `/onboarding_ttrt_marriage_update/${clientPostPackCode}`,
      type: 'patch',
      data: data,
      success: function(data) {
        questionsModal.style.display = 'none';
        thanksModal.style.display = 'block';
        document.getElementById('thanksForm').scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      },
      error: function(data) {}
    })
  }

  annualIncome(e) {
    const selectedIncomes = document.querySelectorAll('.annual-income-button');

    if(selectedIncomes.length > 0) {
      selectedIncomes.forEach(income => {
        income.classList.remove('annual-income-button');
      });
    }

    if(Object.values(e.currentTarget.classList).indexOf('annual-income-button') === -1) {
      e.currentTarget.classList.add('annual-income-button');
    }

    if(document.querySelectorAll('.employment-button').length > 0 && document.querySelectorAll('.annual-income-button').length > 0) {
      this.cardDetailsTarget.classList.remove('disabled-button');
      this.cardDetailsTarget.classList.add('enabled-button');
      this.cardDetailsTarget.removeAttribute('disabled');
    }
    else {
      this.cardDetailsTarget.classList.add('disabled-button');
      this.cardDetailsTarget.classList.remove('enabled-button');
      this.cardDetailsTarget.setAttribute('disabled', 'true');
    }
  }

  selectedWashButton(e) {
    if(e.currentTarget.classList.contains('washer-yes')){
      this.selectedWashYesTarget.style.backgroundColor = '#E1A122';
      this.selectedWashYesTarget.style.color = '#fff';
      this.selectedWashNoTarget.style.backgroundColor = '#fff';
      this.selectedWashNoTarget.style.color = '#000';
    }
    else
    {
      this.selectedWashYesTarget.style.backgroundColor = '#fff';
      this.selectedWashYesTarget.style.color = '#000';
      this.selectedWashNoTarget.style.backgroundColor = '#E1A122';
      this.selectedWashNoTarget.style.color = '#fff';
    }
    if((this.checkMarriedTargets[0].checked || this.checkMarriedTargets[1].checked) && (this.checkWashUniformTargets[0].checked || this.checkWashUniformTargets[1].checked) &&
        (this.checkWfhTargets[0].checked || this.checkWfhTargets[1].checked) && (this.checkPetrolTargets[0].checked || this.checkPetrolTargets[1].checked || this.checkPetrolTargets[2].checked))
    {
      this.submitQualifiersBtnTarget.removeAttribute('disabled');
      this.submitQualifiersBtnTarget.classList.remove('disabled-button');
      this.submitQualifiersBtnTarget.classList.add('enabled-button');
    }
    else {
      this.submitQualifiersBtnTarget.setAttribute('disabled', true);
      this.submitQualifiersBtnTarget.classList.add('disabled-button');
      this.submitQualifiersBtnTarget.classList.remove('enabled-button');
    }
  }

  selectedHome(e) {
    if(e.currentTarget.classList.contains('home-yes')){
      this.selectedHomeYesTarget.style.backgroundColor = '#E1A122';
      this.selectedHomeYesTarget.style.color = '#fff';
      this.selectedHomeNoTarget.style.backgroundColor = '#fff';
      this.selectedHomeNoTarget.style.color = '#000';
    }
    else
    {
      this.selectedHomeYesTarget.style.backgroundColor = '#fff';
      this.selectedHomeYesTarget.style.color = '#000';
      this.selectedHomeNoTarget.style.backgroundColor = '#E1A122';
      this.selectedHomeNoTarget.style.color = '#fff';
    }
    if((this.checkMarriedTargets[0].checked || this.checkMarriedTargets[1].checked) && (this.checkWashUniformTargets[0].checked || this.checkWashUniformTargets[1].checked) &&
        (this.checkWfhTargets[0].checked || this.checkWfhTargets[1].checked) && (this.checkPetrolTargets[0].checked || this.checkPetrolTargets[1].checked || this.checkPetrolTargets[2].checked))
    {
      this.submitQualifiersBtnTarget.removeAttribute('disabled');
      this.submitQualifiersBtnTarget.classList.remove('disabled-button');
      this.submitQualifiersBtnTarget.classList.add('enabled-button');
    }
    else {
      this.submitQualifiersBtnTarget.setAttribute('disabled', true);
      this.submitQualifiersBtnTarget.classList.add('disabled-button');
      this.submitQualifiersBtnTarget.classList.remove('enabled-button');
    }
  }

  selectedPetrol(e) {
    if(e.currentTarget.classList.contains('petrol-yes')){
      this.selectedPetrolYesTarget.style.backgroundColor = '#E1A122';
      this.selectedPetrolYesTarget.style.color = '#fff';
      this.selectedPetrolNoTarget.style.backgroundColor = '#fff';
      this.selectedPetrolNoTarget.style.color = '#000';
      this.selectedPetrolNaTarget.style.backgroundColor = '#fff';
      this.selectedPetrolNaTarget.style.color = '#000';
    }
    else if(e.currentTarget.classList.contains('petrol-no'))
    {
      this.selectedPetrolYesTarget.style.backgroundColor = '#fff';
      this.selectedPetrolYesTarget.style.color = '#000';
      this.selectedPetrolNoTarget.style.backgroundColor = '#E1A122';
      this.selectedPetrolNoTarget.style.color = '#fff';
      this.selectedPetrolNaTarget.style.backgroundColor = '#fff';
      this.selectedPetrolNaTarget.style.color = '#000';
    }
    else {
      this.selectedPetrolYesTarget.style.backgroundColor = '#fff';
      this.selectedPetrolYesTarget.style.color = '#000';
      this.selectedPetrolNoTarget.style.backgroundColor = '#fff';
      this.selectedPetrolNoTarget.style.color = '#000';
      this.selectedPetrolNaTarget.style.backgroundColor = '#E1A122';
      this.selectedPetrolNaTarget.style.color = '#fff';
    }
    if((this.checkMarriedTargets[0].checked || this.checkMarriedTargets[1].checked) && (this.checkWashUniformTargets[0].checked || this.checkWashUniformTargets[1].checked) &&
        (this.checkWfhTargets[0].checked || this.checkWfhTargets[1].checked) && (this.checkPetrolTargets[0].checked || this.checkPetrolTargets[1].checked || this.checkPetrolTargets[2].checked))
    {
      this.submitQualifiersBtnTarget.removeAttribute('disabled');
      this.submitQualifiersBtnTarget.classList.remove('disabled-button');
      this.submitQualifiersBtnTarget.classList.add('enabled-button');
    }
    else {
      this.submitQualifiersBtnTarget.setAttribute('disabled', true);
      this.submitQualifiersBtnTarget.classList.add('disabled-button');
      this.submitQualifiersBtnTarget.classList.remove('enabled-button');
    }
  }

  selectedMarried(e) {
    if(e.currentTarget.classList.contains('married-yes')){
      this.selectedMarriedYesTarget.style.backgroundColor = '#E1A122';
      this.selectedMarriedYesTarget.style.color = '#fff';
      this.selectedMarriedNoTarget.style.backgroundColor = '#fff';
      this.selectedMarriedNoTarget.style.color = '#000';
    }
    else
    {
      this.selectedMarriedYesTarget.style.backgroundColor = '#fff';
      this.selectedMarriedYesTarget.style.color = '#000';
      this.selectedMarriedNoTarget.style.backgroundColor = '#E1A122';
      this.selectedMarriedNoTarget.style.color = '#fff';
    }
    if((this.checkMarriedTargets[0].checked || this.checkMarriedTargets[1].checked) && (this.checkWashUniformTargets[0].checked || this.checkWashUniformTargets[1].checked) &&
        (this.checkWfhTargets[0].checked || this.checkWfhTargets[1].checked) && (this.checkPetrolTargets[0].checked || this.checkPetrolTargets[1].checked || this.checkPetrolTargets[2].checked))
    {
      this.submitQualifiersBtnTarget.removeAttribute('disabled');
      this.submitQualifiersBtnTarget.classList.remove('disabled-button');
      this.submitQualifiersBtnTarget.classList.add('enabled-button');
    }
    else {
      this.submitQualifiersBtnTarget.setAttribute('disabled', true);
      this.submitQualifiersBtnTarget.classList.add('disabled-button');
      this.submitQualifiersBtnTarget.classList.remove('enabled-button');
    }
  }

  employmentStatus(e) {
    const selectedEmployments = document.querySelectorAll('.employment-button');

    if(selectedEmployments.length > 0) {
      selectedEmployments.forEach(employment => {
        employment.classList.remove('employment-button');
      });
    }
    if(Object.values(e.currentTarget.classList).indexOf('employment-button') === -1) {
      e.currentTarget.classList.add('employment-button');
      this.currentEmploymentTarget.value = e.currentTarget.innerHTML;
    }

    if(document.querySelectorAll('.employment-button').length > 0 && document.querySelectorAll('.annual-income-button').length > 0) {
      this.cardDetailsTarget.classList.remove('disabled-button');
      this.cardDetailsTarget.classList.add('enabled-button');
      this.cardDetailsTarget.removeAttribute('disabled');
    }
    else {
      this.cardDetailsTarget.classList.add('disabled-button');
      this.cardDetailsTarget.classList.remove('enabled-button');
      this.cardDetailsTarget.setAttribute('disabled', 'true');
    }
  }

  removeArrayElement(arr, value) {
    return arr.filter(function(ele){
        return ele != value;
    });
  }

  swapFields(element1, element2) {
    element2.parentNode.insertBefore(element2, element1);
  }
}
