import { Controller } from "stimulus"
export default class extends Controller {
    static targets = ["has_ppi", "is_married", "has_wfh", "has_uniform", "show_ppi", "has_vehicle_required", "show_partner", "show_wfh", "show_uniform", "show_emp", "show_mileage", 
                      "mobile_phone","mobile_phone_error", "partner_mobile_phone", "partner_mobile_phone_error", "paye", "title", "first_name", "last_name", "email", "phone", "address1",
                      "city", "postcode", "dob", "income_bracket_2022"];

    connect(){
        console.log('claim builder')

    }

    showAll(e){
        e.preventDefault();
        // this.show_empTarget.style.display = 'block'
        this.show_ppiTarget.style.display = 'block'
        // this.show_partnerTarget.style.display = 'block'
        // this.show_wfhTarget.style.display = 'block'
        // this.show_mileageTarget.style.display = 'block'

    }
    offerDateChange(event){
        if (event.target.parentElement.children[10].value == '2019' && event.target.parentElement.children[9].value <= 4 ){
            event.target.parentElement.children[9].value = 4
            if (event.target.parentElement.children[8].value <= 5){
                event.target.parentElement.children[8].value = 6
            }
        }else if(event.target.parentElement.children[10].value == '2022' && event.target.parentElement.children[9].value >= 4 ){
            event.target.parentElement.children[9].value = 4
            if (event.target.parentElement.children[8].value >= 5){
                event.target.parentElement.children[8].value = 5
            }
        }
    }

    checkCode(e){
        e.preventDefault();
        console.log('checkCode')

        var code_input = this.inputTarget
        if (code_input.value.length > 8) {
            this.messageTarget.style.display = "none";
            this.inputTarget.classList.remove('border-red-500')
            this.submitTarget.submit()

        }
        else{
            this.inputTarget.classList.add('border-red-500')
            //this.messageTarget.style.display == 'block';
        }

    }

    showPpi(){
        if (this.has_ppiTarget.checked){
            console.log('checked')
            this.show_ppiTarget.style.display = 'block'
        } else {
            console.log('not checked')
            this.show_ppiTarget.style.display = 'none'
        }
    }

    // showEmp(){
    //     if (this.is_marriedTarget.checked || this.has_uniformTarget.checked || this.has_wfhTarget.checked){
    //         console.log('checked')
    //         this.show_empTarget.style.display = 'block'
    //     } else {
    //         console.log('not checked')
    //         this.show_empTarget.style.display = 'none'
    //     }
    // }

    showWfh(){
        if (this.has_wfhTarget.checked){
            console.log('checked')
            this.show_wfhTarget.style.display = 'block'
        } else {
            console.log('not checked')
            this.show_wfhTarget.style.display = 'none'
        }
    }

    showMileage(){
        if (this.has_mileageTarget.checked){
            console.log('checked')
            this.show_mileageTarget.style.display = 'block'
        } else {
            console.log('not checked')
            this.show_mileageTarget.style.display = 'none'
        }
    }

    showPartner(){
        if (this.is_marriedTarget.checked){
            console.log('checked')
            this.show_partnerTarget.style.display = 'block'
        } else {
            console.log('not checked')
            this.show_partnerTarget.style.display = 'none'
        }
    }


    copyClientAddress(e){
        e.preventDefault()
        let address1 = document.querySelector('#client_address1').value
        // let address2 = document.querySelector('#client_address2').value
        let city = document.querySelector('#client_city').value
        // let county = document.querySelector('#client_county').value
        let postcode = document.querySelector('#client_postcode').value
        document.querySelector('#client_partner_attributes_address1').value = address1
        // document.querySelector('#client_partner_attributes_address2').value = address2
        document.querySelector('#client_partner_attributes_city').value = city
        // document.querySelector('#client_partner_attributes_county').value = county
        document.querySelector('#client_partner_attributes_postcode').value = postcode
    }

    calcMileages(){

    }

    checkMobile(e) {
        let mobile_phone_input = this.mobile_phoneTarget
        mobile_phone_input.classList.remove('danger')
        mobile_phone_input.classList.remove('error')
        this.mobile_phone_errorTarget.innerHTML = ""
        this.mobile_phone_errorTarget.style.display = "none"
        let number = mobile_phone_input.value
        if (this.mobile_phoneTarget.value.length > 10) {
            let error_div = this.mobile_phone_errorTarget
            console.log(error_div)

            Rails.ajax({

                type: 'GET',
                dataType: 'script',
                url: `/validate_number?country_code=gb&number=${number}`,

                error: function (jqXHR, textStatus, errorThrown) {
                    console.log("AJAX Error:", textStatus)
                },
                success: function (data, textStatus, jqXHR) {
                    console.log("numverify run", data)
                    console.log("numverify valid?", data.valid)
                    if (data.valid == true) {

                        error_div.style.display = "block"
                        mobile_phone_input.classList.add("success")
                        mobile_phone_input.classList.add("success")
                    } else {
                        error_div.innerHTML = `Invalid UK Mobile`
                        error_div.style.display = "block"
                        mobile_phone_input.classList.add("error")
                    }

                }
            })
        } else {
            console.log('enter more digits for mobile')
            this.mobile_phone_errorTarget.innerHTML = 'Check digits for mobile'
            this.mobile_phone_errorTarget.style.display = "block"
        }

    }

    checkPartnerMobile(e) {
        let partner_phone_input = this.partner_mobile_phoneTarget
        partner_phone_input.classList.remove('danger')
        partner_phone_input.classList.remove('error')
        this.partner_mobile_phone_errorTarget.innerHTML = ""
        this.partner_mobile_phone_errorTarget.style.display = "none"
        let number = partner_phone_input.value
        if (this.partner_mobile_phoneTarget.value.length > 10) {
            let error_div = this.partner_mobile_phone_errorTarget
            console.log(error_div)

            Rails.ajax({

                type: 'GET',
                dataType: 'script',
                url: `/validate_number?country_code=gb&number=${number}`,

                error: function (jqXHR, textStatus, errorThrown) {
                    console.log("AJAX Error:", textStatus)
                },
                success: function (data, textStatus, jqXHR) {
                    console.log("numverify run", data)
                    console.log("numverify valid?", data.valid)
                    if (data.valid == true) {

                        error_div.style.display = "block"
                        partner_phone_input.classList.add("success")
                    } else {
                        error_div.innerHTML = `Invalid UK Mobile`
                        error_div.style.display = "block"
                        partner_phone_input.classList.add("error")
                    }

                }
            })
        } else {
            console.log('enter more digits for mobile')
            this.partner_mobile_phone_errorTarget.innerHTML = 'Check digits for mobile'
            this.partner_mobile_phone_errorTarget.style.display = "block"
        }

    }

    validateForm(e){
        e.preventDefault();

        // let partOne = this.payeTarget.value.split('/')[0];
        // let partTwo = this.payeTarget.value.split('/')[1];
        let ppi_lender = false
        let ppi_offer_amount = false
        let ppi_offer_date = false

        document.querySelectorAll('.lender_name').forEach(function(e) {if (e.parentElement.style.display != "none"){if (e.value == ""){ppi_lender = true}}});
        document.querySelectorAll('.offer_amount').forEach(function(e) {if (e.parentElement.style.display != "none"){if (e.value == ""){ppi_offer_amount = true}}});
        document.querySelectorAll('.offer_date').forEach(function(e) {if (e.parentElement.style.display != "none"){if (e.value == ""){ppi_offer_date = true}}});

        if (ppi_lender == true){
            document.getElementById('error-body').innerHTML = "Ppi Lender name must be present!"
            document.getElementById('error-body').style.display = 'block'
           
        }else if (ppi_offer_amount == true){
            document.getElementById('error-body').innerHTML = "Ppi Offer Amount must be present!"
            document.getElementById('error-body').style.display = 'block'
           
        }else if (ppi_offer_date == true){
            document.getElementById('error-body').innerHTML = "Ppi Offer Date must be present!"
            document.getElementById('error-body').style.display = 'block'
           
        }else if (this.first_nameTarget.value == ""){
            document.getElementById('error-body').innerHTML = "First Name must be present!"
            document.getElementById('error-body').style.display = 'block'
           
        }else if (this.last_nameTarget.value == ""){
            document.getElementById('error-body').innerHTML = "Last name must be present!"
            document.getElementById('error-body').style.display = 'block'
           
        }else if (this.emailTarget.value == "" && this.mobile_phoneTarget.value == ""){
            document.getElementById('error-body').innerHTML = "Email or Phone Number must be present!"
            document.getElementById('error-body').style.display = 'block'
           
        }else if (this.address1Target.value == ""){
            document.getElementById('error-body').innerHTML = "Address 1 must be present!"
            document.getElementById('error-body').style.display = 'block'
           
        }else if (this.cityTarget.value == ""){
            document.getElementById('error-body').innerHTML = "City must be present!"
            document.getElementById('error-body').style.display = 'block'
           
        }else if (this.postcodeTarget.value == ""){
            document.getElementById('error-body').innerHTML = "Postcode must be present!"
            document.getElementById('error-body').style.display = 'block'
           
        }else if (this.dobTarget.value == ""){
            document.getElementById('error-body').innerHTML = "Date Of Birth must be present!"
            document.getElementById('error-body').style.display = 'block'
           
        }else if (this.income_bracket_2022Target.value == ""){
            document.getElementById('error-body').innerHTML = "Please select Income bracket 2022"
            document.getElementById('error-body').style.display = 'block'
           
        }else if (document.getElementById('pp-claim') == null){
            document.getElementById('error-body').innerHTML = "Ppi Claim must be present!"
            document.getElementById('error-body').style.display = 'block'
           
        }else{
            this.income_bracket_2022Target.closest('form').submit();
        }

        // if(this.payeTarget.value != ''){
        //     if(this.payeTarget.value.includes('/') == true && (partOne.match(/^([0-9]{3})$/) && partTwo.match(/^(\w{3,10})$/))){
        //         document.getElementById('error-body').innerHTML = ""
        //         document.getElementById('error-body').style.display = 'none'
        //         this.payeTarget.closest('form').submit();
        //     }
        //     else {
        //         document.getElementById('error-body').innerHTML = "Please check PAYE format is correct"
        //         document.getElementById('error-body').style.display = 'block'
        //     }
        // }
        // else if(this.payeTarget.value == ''){
        //     document.getElementById('error-body').innerHTML = ""
        //     document.getElementById('error-body').style.display = 'none'
        //     this.payeTarget.closest('form').submit();
        // }
    }

}