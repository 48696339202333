import { Controller } from 'stimulus';
import SignaturePad from "signature_pad";
import { PostcodeLookup } from "@ideal-postcodes/postcode-lookup";

let step1Data = new FormData();
let step2Data = new FormData();
let step3Data = new FormData();
let step4Data = new FormData();
let step5Data = new FormData();
let step6Data = new FormData();
let step7Data = new FormData();
let step8Data = new FormData();


let urlAddress = window.location.href.split('/');
let templateId = window.location.href.split('/').pop();
let clientPostPackCode = urlAddress[urlAddress.length -2];
let extraInfo = "";
let lenderNames;
let lenderLoop = false;
let i = 1;

let currentPage;
let BdLander = {};
export default class extends Controller {
  static targets = ['claim', 'employmentBtn', 'cardDetails', 'checkQuestionModal', 'compensationModal', 'lenderModal', 'employmentModal',
                     'cardModal', 'personalDetailsModal', 'addressModal', 'insuranceModal', 'signatureModal', 'tryAgainModal', 'totalAmountReceived', 'totalAmountReceivedError', 'claimProcessStartBtn', 'annualIncomeToggle', 'signatureBtn', 'claimBtnArrow',
                     'employmentBtnArrow', 'cardBtnArrow', 'signatureBtnArrow', 'insuranceBtnArrow', 'lenderBtnArrow', 'compensationBtnArrow', 'claimBtnSpinner',
                     'employmentBtnSpinner', 'cardBtnSpinner', 'signatureBtnSpinner', 'insuranceBtnSpinner', 'lenderBtnSpinner', 'compensationBtnSpinner',
                     'addressDetails', 'selectDobYear', 'selectMonth', 'selectDay', 'addressSelector', 'addressLine1',
                     'addressLine2', 'addressLine3', 'addressPostTown', 'addressPostCode', 'line1', 'line2', 'line3',
                     'postTown', 'postCode', 'firstName', 'lastName', 'emailAddress', 'phoneNumber', 'questionsModal',
                     'error_text', 'error_text_container', 'enableInsuranceBtn', 'signHerePlaceholder', 'thanksModal', 'claimerName', 'selectYear','modal',
                     'selectedYears', 'selectBank', 'selectedBanks', 'amountReceived', 'submitCompensationBtn',
                     'selectedEmployment', 'findPostAddress', 'postalAddress', 'selectedWashYes', 'selectedWashNo' ,'selectedHomeYes',
                     'selectedHomeNo', 'selectedPetrolYes', 'selectedPetrolNo' , 'currentEmployment', 'currentSalaryBracket', 'selectedPetrolNa', 'selectedMarriedNo',
                     'selectedMarriedYes', 'ni_error_text', 'closePlus', 'openMinus', 'amountReceivedError', 'signatureError', 'submitPostalAddressBtn', 'submitClientDetailsBtn', 'submitQualifiersBtn',
                     'checkMarried', 'checkWashUniform', 'checkWfh', 'checkPetrol', 'detailsconsentModal', 'checkConsentOne', 'checkConsentTwo', 'checkConsentThree', 'checkConsentFour', 
                     'submitDetailConsentBtn', 'clientFullName', 'title', 'lenderBankName', 'amountDeducted', 'otherBank', 'lenderOtherBankModal', 'lenderOtherBankMobileModal', 'otherBankField', 'employmentStatusToggle', 'addressWrapper'];
  

    connect() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    BdLander = JSON.parse(localStorage.getItem("BdLander"));
    if(!BdLander){
      let localCache = {};
      currentPage = 0;
      localCache.currentPage = currentPage;
      localStorage.setItem("BdLander",JSON.stringify(localCache));
    } else {
      switch (BdLander.currentPage) {
        case 1:
          this.goToTopAndStart();
          break;
        case 2:
          document.getElementById("claimBtn").style.display ='none';
          this.claimProcess();
          break;
        case 3:
          document.getElementById("claimBtn").style.display ='none';
          this.postcodeSetup();
          setTimeout(this.submitEmploymentDetails, 1200);
          break;
        case 4:
          document.getElementById("claimBtn").style.display ='none';
          this.submitPersonalDetails();
        break;
        case 5:
          document.getElementById("claimBtn").style.display ='none';
          this.claimerNameTargets.forEach( (elem) => { elem.innerHTML = BdLander.firstName } );
          this.signature();
        break;
        case 6:
          document.getElementById("claimBtn").style.display ='none';
          this.claimerNameTargets.forEach( (elem) => { elem.innerHTML = BdLander.firstName } );
          this.submitInsuranceDetails();
        break;
        case 7:
          document.getElementById("claimBtn").style.display ='none';
          this.claimerNameTargets.forEach( (elem) => { elem.innerHTML = BdLander.firstName } );
          this.submitEmployment();
        break;
        case 8:
          document.getElementById("claimBtn").style.display ='none';
          this.claimerNameTargets.forEach( (elem) => { elem.innerHTML = BdLander.firstName } );
          this.submitCompensation();
        break;
        // case 9:
        //   this.claimerNameTargets.forEach( (elem) => { elem.innerHTML = BdLander.firstName } );
        //   document.getElementById("claimBtn").style.display ='none';
        //   document.getElementById('thanksForm').style.display = 'block';
        //   document.getElementById('thanksForm').scrollIntoView({
        //     behavior: "smooth",
        //     block: 'center'
        //   });
        // break;
        default:
          break;
      }
    }
    this.carousel();
  }

  carousel() {
    const delay = 5000; //ms

    const slides = document.querySelector(".slides");
    const slidesCount = slides.childElementCount;
    const maxLeft = (slidesCount - 1) * 100 * -1;

    let current = 0;

    function changeSlide(next = true) {
      if (next) {
        current += current > maxLeft ? -100 : current * -1;
      }
      slides.style.left = current + "%";
    }

    setInterval(changeSlide, delay);
  }

  postcodeSetup(){
    window.PostcodeLookup = PostcodeLookup;
    PostcodeLookup.setup({
      context: "#postcode_lookup_field",
      apiKey: "ak_l3jx9ksxXzVXkIr1BMiX1AVixM721",
      outputFields: {
        line_1: "#line_1",
        line_2: "#line_2",
        line_3: "#line_3",
        post_town: "#post_town",
        postcode: "#postcode"
      }
    });
  }
  
    openDialog() {
      var dialog = document.getElementById('dialog');
      var closeButton = document.getElementById('close');
      var overlay = document.getElementById('overlay');
      dialog.classList.remove('hidden');
      overlay.classList.remove('hidden');   
    }
 
    closeDialog() {
      var dialog = document.getElementById('dialog');
      var overlay = document.getElementById('overlay');
      dialog.classList.add('hidden');
      overlay.classList.add('hidden');
    }

    openPrivacyPolicy(){
      var dialogPrivacyPolicy = document.getElementById('dialogPrivacyPolicy');
      var overlayPrivacyPolicy = document.getElementById('overlayPrivacyPolicy');
      dialogPrivacyPolicy.classList.remove('hidden');
      overlayPrivacyPolicy.classList.remove('hidden');   
    }

    closePrivacyPolicy(){
      var dialogPrivacyPolicy = document.getElementById('dialogPrivacyPolicy');
      var overlayPrivacyPolicy = document.getElementById('overlayPrivacyPolicy');
      dialogPrivacyPolicy.classList.add('hidden');
      overlayPrivacyPolicy.classList.add('hidden');
    }

    openTermsConditions(){
      var dialogTermsConditions = document.getElementById('dialogTermsConditions');
      var overlayTermsConditions = document.getElementById('overlayTermsConditions');
      dialogTermsConditions.classList.remove('hidden');
      overlayTermsConditions.classList.remove('hidden');   
    }

    closeTermsConditions(){
      var dialogTermsConditions = document.getElementById('dialogTermsConditions');
      var overlayTermsConditions = document.getElementById('overlayTermsConditions');
      dialogTermsConditions.classList.add('hidden');
      overlayTermsConditions.classList.add('hidden');
    }

    openAuthoriseForm(e){
      if (e.currentTarget.id === "r40"){
        document.getElementById('r40-Form').classList.remove("hidden");
        document.getElementById('64-8-Form').classList.add("hidden");
      } else {
        document.getElementById('64-8-Form').classList.remove("hidden");
        document.getElementById('r40-Form').classList.add("hidden");
      }
      var authoriseForm = document.getElementById('authoriseForm');
      var authoriseOverlay = document.getElementById('authoriseOverlay');
      authoriseForm.classList.remove('hidden');
      authoriseOverlay.classList.remove('hidden');  
    }

    closeAuthoriseForm() {
      var authoriseForm = document.getElementById('authoriseForm');
      var authoriseOverlay = document.getElementById('authoriseOverlay');
      authoriseForm.classList.add('hidden');
      authoriseOverlay.classList.add('hidden');
    }

  checkQuestion() {
    this.scrollAbc();
    BdLander = JSON.parse(localStorage.getItem("BdLander"));
    console.log(BdLander)
    if(BdLander){
      currentPage = 1;
      BdLander.currentPage = currentPage;
      localStorage.setItem("BdLander",JSON.stringify(BdLander))
    }
    let homePage =  this.claimTarget
    let ppi =       this.checkQuestionModalTarget
    step1Data.append('template_id', templateId);
    step1Data.append('step', "1");
    Rails.ajax({
      url: `/onboarding_bd_update/${clientPostPackCode}`,
      type: "patch",
      data: step1Data,
      success: function(data) {
        console.log(data);
        homePage.style.display ='none';
        ppi.style.display = 'block';
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      error: function(data) {}
    });
  }

  goToTopAndStart(){
    this.scrollAbc();
    if (document.getElementById("claimBtn").style.display != "none") {
      document.getElementById("claimBtn").style.display ='none';
      document.getElementById("checkQuestionForm").style.display = 'block';
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  compenstionPaymentSelector(e){
    const selectedCompenstionPayment = document.querySelectorAll('.selected-button');
    if(selectedCompenstionPayment.length > 0) {
      selectedCompenstionPayment.forEach(answer => {
        answer.classList.remove('selected-button');
      });
    }
    
    e.currentTarget.classList.add('selected-button');

    this.claimProcessStartBtnTarget.removeAttribute('disabled');
    this.claimProcessStartBtnTarget.classList.add('enabled-button');
    this.claimProcessStartBtnTarget.classList.remove('disabled-button');
  }

  claimProcess() {
    let checkQuestionModalTarget;
    let employmentModalTarget;
    let totalAmountReceived;
    let net = 0.00;
    let tax = 0.00;
    let gross = 0.00;

    BdLander = JSON.parse(localStorage.getItem("BdLander"));
    if (BdLander.totalAmountReceived){
      totalAmountReceived = BdLander.totalAmountReceived;
      tax = (totalAmountReceived * (11 / 100)).toFixed(2)
      gross = (tax * 5).toFixed(2);
      net = (gross - tax).toFixed(2);
      step4Data.append('lenders', 'Marketing');
      step4Data.append('offer_amount', totalAmountReceived);
      step4Data.append('tax', tax);
      step4Data.append('gross', gross);
      step4Data.append('net', net);
      document.getElementById('checkQuestionForm').style.display = 'none';
      document.getElementById('employmentForm').style.display = 'block';
    } else {
      totalAmountReceived = this.totalAmountReceivedTarget.value;
      if (totalAmountReceived.split('.')[0].length <= 5 ){
        BdLander.totalAmountReceived = totalAmountReceived;
        localStorage.setItem("BdLander",JSON.stringify(BdLander));
        checkQuestionModalTarget = this.checkQuestionModalTarget;
        employmentModalTarget = this.employmentModalTarget;
        this.claimBtnArrowTarget.classList.add("hidden");
        this.claimBtnSpinnerTarget.classList.remove("hidden");
      }

      if (totalAmountReceived > 0){
        tax = (totalAmountReceived * (11 / 100)).toFixed(2)
        gross = (tax * 5).toFixed(2);
        net = (gross - tax).toFixed(2);
      }

      if (totalAmountReceived.split('.')[0].length <= 5 ){
        step4Data.append('lenders', 'Marketing');
        step4Data.append('offer_amount', totalAmountReceived);
        step4Data.append('tax', tax);
        step4Data.append('gross', gross);
        step4Data.append('net', net);

        step2Data.append('template_id', templateId);
        step2Data.append('step', "2");
        Rails.ajax({
          url: `/onboarding_bd_update/${clientPostPackCode}`,
          type: "patch",
          data: step2Data,
          success: function(data) {
            checkQuestionModalTarget.style.display ='none';
            employmentModalTarget.style.display = 'block';
            if(BdLander){
              currentPage = 2;
              BdLander.currentPage = currentPage;
              localStorage.setItem("BdLander",JSON.stringify(BdLander));
            }
            window.scrollTo({ top: 0, behavior: "smooth" });
          },
          error: function(data) {}
        });
      }
      else {
        this.totalAmountReceivedErrorTarget.classList.remove('hide')
        this.totalAmountReceivedErrorTarget.innerHTML = 'Please enter 5 digits or less for amount received to continue'
      }
    }

    this.postcodeSetup();
  }

  tryAgainBtn(){
    this.tryAgainModalTarget.style.display = 'none';
    this.checkQuestionModalTarget.style.display = 'block';
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  submitEmploymentDetails() {
    let employmentStatus;
    let incomeBracket2022;
    let totalAmountReceived, tax, gross, net;
    
    BdLander = JSON.parse(localStorage.getItem("BdLander"));
    if (BdLander.employmentStatus && BdLander.incomeBracket2022){
      employmentStatus = BdLander.employmentStatus;
      incomeBracket2022 = BdLander.incomeBracket2022;
      totalAmountReceived = BdLander.totalAmountReceived;
      tax = (totalAmountReceived * (11 / 100)).toFixed(2)
      gross = (tax * 5).toFixed(2);
      net = (gross - tax).toFixed(2);
      step4Data.append('lenders', 'Marketing');
      step4Data.append('offer_amount', totalAmountReceived);
      step4Data.append('tax', tax);
      step4Data.append('gross', gross);
      step4Data.append('net', net);
      step4Data.append('employment_status', employmentStatus);
      step4Data.append('income_bracket_2022', incomeBracket2022);
      document.getElementById('employmentForm').style.display = "none";
      document.getElementById('clientForm').style.display = 'block';
    } else {
      // this.employmentBtnArrowTarget.classList.add("hidden");
      // this.employmentBtnSpinnerTarget.classList.remove("hidden");
      employmentStatus = this.currentEmploymentTarget.value.split('-').map(value => value.charAt(0).toUpperCase() + value.slice(1)).join(' ');
      incomeBracket2022 = this.currentSalaryBracketTarget.value;
      BdLander.employmentStatus = employmentStatus;
      BdLander.incomeBracket2022 = incomeBracket2022;
      localStorage.setItem("BdLander",JSON.stringify(BdLander));
      let employmentModal = this.employmentModalTarget;
      let cardModal = this.cardModalTarget;
      step4Data.append('employment_status', employmentStatus);
      step4Data.append('income_bracket_2022', incomeBracket2022);
      step3Data.append('template_id', templateId);
      step3Data.append('step', "3");
      Rails.ajax({
        url: `/onboarding_bd_update/${clientPostPackCode}`,
        type: "patch",
        data: step3Data,
        success: function(data) {
          console.log(data);
          if(BdLander){
            currentPage = 3;
            BdLander.currentPage = currentPage;
            localStorage.setItem("BdLander",JSON.stringify(BdLander));
          }      
          employmentModal.style.display = 'none';
          cardModal.style.display = 'block';
          window.scrollTo({ top: 0, behavior: "smooth" });
          // document.getElementById('clientForm').scrollIntoView({
          //   behavior: "smooth",
          //   block: 'center'
          // });

        },
        error: function(data) {}
      });
    }

    document.querySelector('.idpc-button').setAttribute('data-action', 'onboarding-bd#displaySearchAddress');
    document.querySelector('.idpc-button').setAttribute('data-onboarding-bd-target', 'findPostAddress');
    document.querySelector('.idpc-input').setAttribute('data-action', 'onboarding-bd#handlePostalAddress');
    document.querySelector('.idpc-input').setAttribute('data-onboarding-bd-target', 'postalAddress');
    document.querySelector('.idpc-input').style.cssText = "--tw-ring-color: #F8F8F8; border-color: darkgray; padding-top: 15px; padding-bottom: 15px; border-radius: 4px;     width: 85%;";
    document.querySelector('.idpc-button').classList.add('inline-flex', 'items-center',
                                                          'my-5', 'px-4', 'py-3', 'border', 'border-transparent',
                                                          'text-xs', 'font-bold', 'rounded-lg', 'text-white',
                                                          'focus:outline-none');
    document.querySelector('.idpc-button').style.cssText = "background-color: #6998ab;";
    if(!document.querySelector('.idpc-button').innerHTML.includes('span')){
      let spanElement = document.createElement('span');
      spanElement.classList.add('lg:ml-10', 'ml-22', '-mr-1', 'h-5', 'w-5', 'mb-1', 'pt-1.5', 'text-right');
      let fontElement = document.createElement('i');
      fontElement.classList.add('fas', 'fa-arrow-right');
      spanElement.appendChild(fontElement);
      document.querySelector('.idpc-button').appendChild(spanElement);
    }
    document.querySelector('.idpc-error').style.cssText = "color: #E10000";
    document.querySelector('.idpc-error').classList.add('text-xs');

  }

  submitPersonalDetails() {
    // let firstName, lastName, emailAddress, phoneNumber, title, dob, address1, address2, postCode, postCity, termsCheckBox;
    let firstName, lastName, emailAddress, phoneNumber, title, dob, address1, address2, postCode, postCity, date;

    BdLander = JSON.parse(localStorage.getItem("BdLander"));

    if (BdLander.firstName && BdLander.lastName && BdLander.emailAddress && BdLander.phoneNumber && BdLander.title && BdLander.dob && BdLander.address1 && BdLander.address2 && BdLander.postCity && BdLander.postCode){  
      document.getElementById('clientForm').style.display = "none";
      document.getElementById('signatureForm').style.display = 'block';
      window.scrollTo({ top: 0, behavior: "smooth" });
      // document.getElementById('signatureForm').scrollIntoView({
      //   behavior: "smooth",
      //   block: "center"
      // });
    } else {
      this.cardBtnArrowTarget.classList.add("hidden");
      this.cardBtnSpinnerTarget.classList.remove("hidden");
      firstName = this.firstNameTarget.value;
      lastName = this.lastNameTarget.value;
      emailAddress = this.emailAddressTarget.value;
      phoneNumber = this.phoneNumberTarget.value;

      firstName = firstName.replace(/\s{2,}/g, ' ').trim()
      lastName = lastName.replace(/\s{2,}/g, ' ').trim()

      let day = this.selectDayTarget.value;
      let month = this.selectMonthTarget.value;
      let year = this.selectDobYearTarget.value;
      title = this.titleTarget.value;
      dob = `${year}-${month}-${day}`;
      // termsCheckBox = document.querySelector('.checkmark-input').checked;
      BdLander.firstName = firstName;
      BdLander.lastName = lastName;
      BdLander.emailAddress = emailAddress;
      BdLander.phoneNumber = phoneNumber;
      BdLander.title = title;
      BdLander.dob = dob;
      localStorage.setItem("BdLander",JSON.stringify(BdLander));
      let cardModal = this.cardModalTarget;
      let signatureModal = this.signatureModalTarget;
      cardModal.style.display = 'none';
      signatureModal.style.display = 'block';
      this.claimerNameTargets.forEach( (elem) => { elem.innerHTML = firstName } );

      if(emailAddress != '' && /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(emailAddress) && firstName != '' && lastName != '' && /^(01|07)\d{9}$/.test(phoneNumber) && phoneNumber != '' && dob != '' && document.querySelector('.idpc-error').style.display == 'none') {
        address1 = this.addressLine1Target.innerHTML;
        address2 = this.addressLine2Target.innerHTML + ' ' + this.addressLine3Target.innerHTML;
        postCode = this.addressPostCodeTarget.innerHTML;
        postCity = this.addressPostTownTarget.innerHTML;
        
        BdLander.address1 = address1;
        BdLander.address2 = address2;
        BdLander.postCode = postCode;
        BdLander.postCity = postCity;
        localStorage.setItem("BdLander",JSON.stringify(BdLander));
        extraInfo = dob;
        step4Data.append('extra_info', extraInfo);
        step4Data.append('title', title);
        step4Data.append('first_name', firstName);
        step4Data.append('last_name', lastName);
        step4Data.append('email', emailAddress);
        step4Data.append('mobile_phone', phoneNumber);
        step4Data.append('date_of_birth', dob);
        step4Data.append('address1', address1);
        step4Data.append('address2', address2);
        step4Data.append('postcode', postCode);
        step4Data.append('city', postCity);
        step4Data.append('template_id', templateId);
        step4Data.append('step', "4");
        currentPage = 4;
        BdLander.currentPage = currentPage;
        localStorage.setItem("BdLander",JSON.stringify(BdLander));
  
        Rails.ajax({
          url: `/onboarding_bd_update/${clientPostPackCode}`,
          type: "patch",
          data: step4Data,
          success: function(data) {
            console.log(data);
        
            document.getElementById('clientForm').style.display = "none";
            document.getElementById('signatureForm').style.display = 'block';
            window.scrollTo({ top: 0, behavior: "smooth" });
            // document.getElementById('signatureForm').scrollIntoView({
            //   behavior: "smooth",
            //   block: "center"
            // });
          },
          error: function(data) {}
        })
        
      }
    }

    //  Initialize signature
    const canvas = document.querySelector('canvas');
    if (canvas){
         //this.resizeCanvas(canvas);
       this.signature_pad = new SignaturePad(canvas, {drawOnly:true});
    }
  }

  // submitPostalDetails() {
  //   if(document.querySelector('.idpc-error').style.display == 'none'){
  //     let addressModal = this.addressModalTarget;
  //     let signatureModal = this.signatureModalTarget;
  //     let urlAddress = window.location.href.split('/');
  //     let templateId = window.location.href.split('/').pop();
  //     let clientPostPackCode = urlAddress[urlAddress.length -2];
  //     let address1 = this.addressLine1Target.innerHTML;
  //     let address2 = this.addressLine2Target.innerHTML + ' ' + this.addressLine3Target.innerHTML;
  //     let postCode = this.addressPostCodeTarget.innerHTML;
  //     // let postCity = document.getElementById('address_post_town').innerHTML;
  //     let postCity = this.addressPostTownTarget.innerHTML;

  //     step2Data.append('address1', address1);
  //     step2Data.append('address2', address2);
  //     step2Data.append('postcode', postCode);
  //     step2Data.append('city', postCity);
  //     step2Data.append('step', "2");
  //     step2Data.append('template_id', templateId);

  //     Rails.ajax({
  //       url: `/onboarding_bd_update/${clientPostPackCode}`,
  //       type: "patch",
  //       data: step2Data,
  //       success: function(data) {
  //         addressModal.style.display = 'none';
  //         signatureModal.style.display = 'block';
  //         document.getElementById('signatureForm').scrollIntoView({
  //           behavior: 'smooth',
  //           block: 'center'
  //         });
  //       },
  //       error: function(data) {
  //         console.log(data)
  //       }
  //     })
  //   }

  //   //  Initialize signature
  //   const canvas = document.querySelector('canvas');
  //   if (canvas){
  //     //this.resizeCanvas(canvas);
  //     this.signature_pad = new SignaturePad(canvas, {drawOnly:true});
  //   }
  // }

  signature(event){
    
    BdLander = JSON.parse(localStorage.getItem("BdLander"));
    if (BdLander.signature){
      let signature = BdLander.signature;      
      document.getElementById('signatureForm').style.display = "none";
      document.getElementById('insuranceForm').style.display = 'block';
    } else {
      let signatureModal = this.signatureModalTarget;
      let insuranceModal = this.insuranceModalTarget;
      let dots = this.signature_pad.toData()

      // let checkConsentone = this.checkConsentOneTarget.checked
      // let checkConsentwo = this.checkConsentTwoTarget.checked;
      // let checkConsentthree = this.checkConsentThreeTarget.checked;
      // let checkConsentfour = this.checkConsentFourTarget.checked;

      if(this.signature_pad.isEmpty()) {
        event.preventDefault();
        this.signatureErrorTarget.classList.remove('hide')
        this.signatureErrorTarget.innerHTML = 'Please sign before proceeding'
      }
      else if (dots && dots[0].points.length <= 10) {
        event.preventDefault();
        this.signatureErrorTarget.classList.remove('hide')
        this.signatureErrorTarget.innerHTML = 'Please add a full signature. Clear and try again'
      }
      // else if (!checkConsentone && !checkConsentwo && !checkConsentthree && !checkConsentfour){
      //   event.preventDefault();
      //   this.signatureErrorTarget.classList.remove('hide');
      //   this.signatureErrorTarget.innerHTML = 'Please tick all the boxes below to proceed';
      // }
      // else if(!document.querySelector('.signature-checkbox').checked){
      //   event.preventDefault();
      //   this.signatureErrorTarget.classList.remove('hide');
      //   this.signatureErrorTarget.innerHTML = 'Please tick the box below to proceed';
      // }
      else {
        this.signatureBtnArrowTarget.classList.add("hidden");
        this.signatureBtnSpinnerTarget.classList.remove("hidden");
        event.preventDefault();
        BdLander.signature = this.signature_pad.toDataURL();
        localStorage.setItem("BdLander",JSON.stringify(BdLander));
        step5Data.append('signature', this.signature_pad.toDataURL());
        step5Data.append('template_id', templateId);
        step5Data.append('step', "5");
        Rails.ajax({
          url: `/onboarding_bd_update/${clientPostPackCode}`,
          type: "patch",
          data: step5Data,
          success: function(data) {
            if(BdLander){
              currentPage = 5;
              BdLander.currentPage = currentPage;
              localStorage.setItem("BdLander",JSON.stringify(BdLander));
            } 
            signatureModal.style.display = 'none';
            insuranceModal.style.display = 'block';
            window.scrollTo({ top: 0, behavior: "smooth" });
            // document.getElementById('insuranceForm').scrollIntoView({
            //   behavior: 'smooth',
            //   block: 'center'
            // });
          },
          error: function(data) {
            console.log(data)
          }
        })

      }
    }
    
  }

  submitInsuranceDetails(e) {
    let ni_number;
    let insuranceModal;
    let lenderModal;
    
    BdLander = JSON.parse(localStorage.getItem("BdLander"));

    if (BdLander.ni_number){
      ni_number = BdLander.ni_number;
      document.getElementById('insuranceForm').style.display = 'none';
      document.getElementById('lenderForm').style.display = 'block';
    } else {
      this.insuranceBtnArrowTarget.classList.add("hidden");
      this.insuranceBtnSpinnerTarget.classList.remove("hidden");
      ni_number = document.querySelector('#nin-char-2').value + document.querySelector('#nin-number-1').value +
        document.querySelector('#nin-number-2').value + document.querySelector('#nin-number-3').value +
        document.querySelector('#nin-char-1').value;
      BdLander.ni_number = ni_number;
      localStorage.setItem("BdLander",JSON.stringify(BdLander));

      insuranceModal = this.insuranceModalTarget;
      lenderModal = this.lenderModalTarget;

      // let clientFullName = this.clientFullNameTarget;
      
      step6Data.append('ni_number', ni_number.toUpperCase().replace(/ /g,''));
      step6Data.append('template_id', templateId);
      step6Data.append('step', "6");

      Rails.ajax({
        url: `/onboarding_bd_update/${clientPostPackCode}`,
        type: "patch",
        data: step6Data,
        success: function(data) {
          if(BdLander){
            currentPage = 6;
            BdLander.currentPage = currentPage;
            localStorage.setItem("BdLander",JSON.stringify(BdLander));
          }
          // if (!BdLander.ni_number){
            insuranceModal.style.display = 'none';
            lenderModal.style.display = 'block';
          // } else {
          //   document.getElementById('insuranceForm').style.display = 'none';
          //   document.getElementById('lenderForm').style.display = 'block';
          // }
          window.scrollTo({ top: 0, behavior: "smooth" });

        },
        error: function(data) {
          console.log(data)
        }
      })
  }

    // let title =  step1Data.get('title') != '' ? step1Data.get('title') : "" ;
    // let first_name =  step1Data.get('first_name') != '' ? step1Data.get('first_name') : "" ;
    // let last_name =  step1Data.get('last_name') != '' ? step1Data.get('last_name') : "" ;
    // let dob = new Date(step1Data.get('date_of_birth'));
    // let day = dob.getDate();
    // day = day < 10 ? '0' + day : '' + day;
    // let month = dob.getMonth() + 1;
    // month = month < 10 ? '0' + month : '' + month;
    // let year = dob.getFullYear();
    // document.getElementById('full_name').textContent = title + ' ' + first_name + ' ' + last_name ;
    // document.getElementById('address1').textContent = step1Data.get('address1') ;
    // document.getElementById('address2').textContent = step1Data.get('address2') ;
    // document.getElementById('detailspostcity').textContent = step1Data.get('city');
    // document.getElementById('detailspostcode').textContent = step1Data.get('postcode');
    // document.getElementById('dob').textContent = day + '/' + month + '/' + year;
    // document.getElementById('ni_number').textContent = step7Data.get('ni_number');
    // document.getElementById('detailsconsentForm').scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'start'
    // });



    // this.scrollAbc();
  }

  submitEmployment() {
    // let lenders = this.selectedBanksTarget.value;
    let extraInfo0;
    BdLander = JSON.parse(localStorage.getItem("BdLander"));

    if (BdLander.extraInfo0){
      extraInfo0 = BdLander.extraInfo0;
      lenderNames = extraInfo0.split(",");
      lenderNames = lenderNames.slice(1);
      document.getElementById("lender_bank_name").innerHTML = lenderNames[0];
      document.getElementById('insuranceForm').style.display = 'none';
      document.getElementById('compensationForm').style.display = 'block';
    } else {
      this.lenderBtnArrowTarget.classList.add("hidden");
      this.lenderBtnSpinnerTarget.classList.remove("hidden");
      extraInfo0 = this.selectedBanksTarget.value;
      lenderNames = extraInfo0.split(",");
      lenderNames = lenderNames.slice(1);
      this.lenderBankNameTarget.innerHTML = lenderNames[0];
      BdLander.extraInfo0 = extraInfo0;
      localStorage.setItem("BdLander",JSON.stringify(BdLander));
      let lenderModal = this.lenderModalTarget;
      let compensationModal = this.compensationModalTarget;
      step7Data.append('template_id', templateId);
      step7Data.append('step', "7");

      Rails.ajax({
        url: `/onboarding_bd_update/${clientPostPackCode}`,
        type: "patch",
        data: step7Data,
        success: function(data) {
          if(BdLander){
            currentPage = 7;
            BdLander.currentPage = currentPage;
            localStorage.setItem("BdLander",JSON.stringify(BdLander));
          }      
          lenderModal.style.display = 'none';
          compensationModal.style.display = 'block';
          window.scrollTo({ top: 0, behavior: "smooth" });
          // document.getElementById('compensationForm').scrollIntoView({
          //   behavior: "smooth",
          //   block: 'center'
          // });
        },
        error: function(data) {
          console.log(data)
        }
      })

    }


    // let lenderBankName = this.lenderBankNameTarget;

    // if (this.otherBankFieldTarget.value.length > 2){
    //   lenders = lenders.replace('Other', this.otherBankFieldTarget.value)
    // }
    // let data = new FormData();
    // data.append('lenders', lenders);
    // data.append('step', 1);
    // data.append('template_id', templateId);

    // Rails.ajax({
    //   url: `/onboarding_bd_update/${clientPostPackCode}`,
    //   type: "patch",
    //   data: data,
    //   success: function(data) {
    //     lenderModal.style.display = 'none';
    //     compensationModal.style.display = 'block';
    //     let content = data[0]['lender'] != "" ? data[0]['lender'] : 'Abbey National'
    //     document.getElementById('lender_bank_name').textContent = content
    //     document.getElementById('compensationForm').scrollIntoView({
    //       behavior: "smooth",
    //       block: 'center'
    //     });
    //   },
    //   error: function(data) {}
    // })
  }

  submitCompensation() {
    BdLander = JSON.parse(localStorage.getItem("BdLander"));

    if (BdLander.extraInfo){
      document.getElementById('compensationForm').style.display = 'none';
      document.getElementById('thanksForm').style.display = 'block';
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {

      window.scrollTo({ top: 0, behavior: "smooth" });
      // document.getElementById('compensationForm').scrollIntoView({
      //   behavior: "smooth",
      //   block: 'center'
      // });
      let taxYears = this.selectedYearsTarget.value;
      let offerAmount = this.amountReceivedTargets[0].value || this.amountReceivedTargets[1].value;
      let amountDeductedvalue = this.amountDeductedTarget.value;
      extraInfo = extraInfo.concat(",", this.lenderBankNameTarget.innerHTML, ",", taxYears, ",", offerAmount, ",", amountDeductedvalue);
      let compensationModal = this.compensationModalTarget;
      let thanksModal = this.thanksModalTarget;
      if(lenderNames.length <= 1){
        lenderLoop = true;
      }
  
      if (offerAmount.split('.')[0].length <= 5 && lenderLoop){
        this.compensationBtnArrowTarget.classList.add("hidden");
        this.compensationBtnSpinnerTarget.classList.remove("hidden");
        step8Data.append('extra_info', extraInfo);
        step8Data.append('step', "8");
        step8Data.append('template_id', templateId);
        Rails.ajax({
          url: `/onboarding_bd_update/${clientPostPackCode}`,
          type: "patch",
          data: step8Data,
          success: function(data) {
            currentPage = 8;
            BdLander.currentPage = currentPage;
            localStorage.setItem("BdLander",JSON.stringify(BdLander));
            BdLander.extraInfo = extraInfo;
            delete BdLander.extraInfo0;
            localStorage.setItem("BdLander",JSON.stringify(BdLander));
  
            // if(data[0] != undefined){
            //   let content = data[0]['lender'] != "" ? data[0]['lender'] : 'Abbey National'
            //   const selectedEmployments = document.querySelectorAll('.selected-button');
  
            //   if(selectedEmployments.length > 0) {
            //     selectedEmployments.forEach(employment => {
            //       employment.classList.remove('selected-button');
            //     });
            //   }
            //   document.getElementById('lender_bank_name').textContent = content
            //   document.getElementById('price-pounds').value = ''
            //   document.getElementById('amount-deducted').value = ''
            //   submitCompensationBtnAdd.setAttribute('disabled', true);
            //   submitCompensationBtnAdd.classList.add('disabled-button');
            //   submitCompensationBtnAdd.classList.remove('enabled-button');
            // }else{
              compensationModal.style.display = 'none';
              thanksModal.style.display = 'block';
              window.scrollTo({ top: 0, behavior: "smooth" });
              // document.getElementById('thanksForm').scrollIntoView({
              //   behavior: "smooth",
              //   block: 'center'
              // });
            // }
          },
          error: function(data) {}
        })
      }
      else if (offerAmount.split('.')[0].length > 5) {
        extraInfo = extraInfo.split(",");
        for (let i = 0; i <= 3; i++) {
          extraInfo.pop();
        }
        extraInfo = extraInfo.join(",");
        this.amountReceivedErrorTarget.classList.remove('hide')
        this.amountReceivedErrorTarget.innerHTML = 'Please enter 5 digits or less for amount received to continue'
      } else {
        this.lenderBankNameTarget.innerHTML = lenderNames[i];
        if(i <= lenderNames.length) {
          this.selectYearTargets.forEach( (elem) => { elem.classList.remove("selected-button") } )
          this.amountReceivedTargets[0].value = "";
          this.amountReceivedTargets[1].value = "";
          this.amountDeductedTarget.value = "";
          console.log(extraInfo);
          i++;
          if(i === lenderNames.length){
            lenderLoop = true;
          }
        }
      }
    }
    }




  // submitDetailConsent(){

  //   let urlAddress = window.location.href.split('/');
  //   let clientPostPackCode = urlAddress[urlAddress.length -2];

  //   let detailsconsentModal = this.detailsconsentModalTarget;
  //   let lenderModal = this.lenderModalTarget;

  //   step5Data.append('template_id', templateId);
  //   step5Data.append('step', 5);

  //   Rails.ajax({
  //     url: `/onboarding_bd_update/${clientPostPackCode}`,
  //     type: "patch",
  //     data: step5Data,
  //     success: function(data) {
  //       console.log(data);
  //       detailsconsentModal.style.display ='none';
  //       lenderModal.style.display = 'block';
  //       document.getElementById('lenderForm').scrollIntoView({
  //         behavior: "smooth",
  //         block: 'start'
  //       });
  //     },
  //     error: function(data) {}
  //   })


  // }

  displaySearchAddress()
  {
    document.querySelector('.idpc-button').click();
    this.waitForElement('.idpc-select').then((elm) => {
      document.querySelector('.idpc-select').setAttribute('data-action', 'onboarding-bd#populateAddress');
      document.querySelector('.idpc-select').classList.add('mt-2');
      document.querySelector('.idpc-select').style.cssText = "--tw-ring-color: #F8F8F8; border-color: darkgray; padding-top: 15px; padding-bottom: 15px; border-radius: 4px; width: 85%";
      document.querySelector('.idpc-button').style.cssText = "background-color: #6998ab;";
    });

    if(!(document.querySelector('.idpc-select'))) {
      this.clearPostalAddress();
    }

    this.waitForElement('.idpc-button').then((element) => {
      let select_address = document.querySelector('.idpc-select-container');
      this.swapFields(element, select_address);
      element.style.cssText = "background-color: #A1BFC6;";
      document.querySelector('.idpc-button').style.cssText = "background-color: #6998ab;";
      let spanElement = document.createElement('span');
      spanElement.classList.add('lg:ml-10', 'ml-22', '-mr-1', 'h-5', 'w-5', 'mb-1', 'pt-1.5', 'text-right');
      let fontElement = document.createElement('i');
      fontElement.classList.add('fas', 'fa-arrow-right');
      spanElement.appendChild(fontElement);
      if (element.childElementCount > 0) {
        element.removeChild(spanElement);
      }
      this.findPostAddressTarget.appendChild(spanElement);
      element.appendChild(spanElement);
    });
    // document.querySelector('.idpc-select').setAttribute('data-onboarding-ppi-target', 'addressSelector');
  }

  scrollAbc() {
    window.scrollTo({top: 100, behavior: 'smooth'});
    // this.checkQuestion();
  }

  waitForElement(selector) {
    return new Promise(resolve => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(mutations => {
            if (document.querySelector(selector)) {
                resolve(document.querySelector(selector));
                observer.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
  }

  populateAddress(){
    this.addressLine1Target.innerHTML = this.line1Target.value;
    this.addressLine2Target.innerHTML = this.line2Target.value;
    this.addressLine3Target.innerHTML = this.line3Target.value;
    this.addressPostTownTarget.innerHTML = this.postTownTarget.value;
    this.addressPostCodeTarget.innerHTML = this.postCodeTarget.value;
    this.addressLine1Target.classList.add('pha-text-dark-blue');
    this.addressLine2Target.classList.add('pha-text-dark-blue');
    this.addressLine3Target.classList.add('pha-text-dark-blue');
    this.addressPostTownTarget.classList.add('pha-text-dark-blue');
    this.addressPostCodeTarget.classList.add('pha-text-dark-blue');
    this.addressWrapperTarget.classList.add('p-5');
    let title = this.titleTarget.value;
    let firstName = this.firstNameTarget.value;
    let lastName = this.lastNameTarget.value;
    let emailAddress = this.emailAddressTarget.value;
    let phoneNumber = this.phoneNumberTarget.value;
    let day = this.selectDayTarget.value;
    let month = this.selectMonthTarget.value;
    let year = this.selectDobYearTarget.value;
    let allDetailsVerified = true;

    if (firstName == '' || lastName == '' || day == '' || month == '' || year == '' || title == '') {
      allDetailsVerified = false;
    } 

    if (emailAddress == '' || !(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(emailAddress))) {
      allDetailsVerified = false;
    }

    if (phoneNumber == '' || !(/^(01|07)\d{9}$/.test(phoneNumber))) {
      allDetailsVerified = false;
    }

    // if(!(document.querySelector('.checkmark-input').checked)) {
    //   allDetailsVerified = false;
    // }

    if(this.addressLine1Target.innerHTML === ''){
      allDetailsVerified = false;
    }

    if (allDetailsVerified == false){
      this.submitClientDetailsBtnTarget.setAttribute('disabled', true);
      this.submitClientDetailsBtnTarget.classList.add('disabled-button');
      this.submitClientDetailsBtnTarget.classList.remove('enabled-button');
    }
    else {
      console.log('good to go');
      this.submitClientDetailsBtnTarget.removeAttribute('disabled');
      this.submitClientDetailsBtnTarget.classList.remove('disabled-button');
      this.submitClientDetailsBtnTarget.classList.add('enabled-button');
    }
  }

  clearPostalAddress(){
    this.addressLine1Target.innerHTML = ''
    this.addressLine2Target.innerHTML = ''
    this.addressLine3Target.innerHTML = ''
    this.addressPostTownTarget.innerHTML = ''
    this.addressPostCodeTarget.innerHTML = ''
  }

  handlePostalAddress(){
    if(document.querySelector('.idpc-select')){
      document.querySelector('.idpc-select').style.display = 'none'
      this.addressLine1Target.innerHTML = ''
      this.addressLine2Target.innerHTML = ''
      this.addressLine3Target.innerHTML = ''
      this.addressPostTownTarget.innerHTML = ''
      this.addressPostCodeTarget.innerHTML = ''
      this.submitClientDetailsBtnTarget.setAttribute('disabled', true);
      this.submitClientDetailsBtnTarget.classList.add('disabled-button');
      this.submitClientDetailsBtnTarget.classList.remove('enabled-button');
    }
  }

  showContent(e) {
    if(e.currentTarget.closest('dl').childNodes[3].classList.contains('hidden')) {
      e.currentTarget.closest('dl > dt > button').childNodes[1].style.color="#108CEC"
      e.currentTarget.closest('dl > dt > button').style.color="#108CEC"
      e.currentTarget.closest('dl > dt > button').childNodes[3].classList.add('hidden')
      e.currentTarget.closest('dl > dt > button').childNodes[5].classList.remove('hidden')
      e.currentTarget.closest('dl').childNodes[3].classList.remove('hidden')
    }
    else {
      e.currentTarget.closest('dl > dt > button').childNodes[1].style.color="#233E97"
      e.currentTarget.closest('dl > dt > button').style.color="#233E97"
      e.currentTarget.closest('dl > dt > button').childNodes[3].classList.remove('hidden')
      e.currentTarget.closest('dl > dt > button').childNodes[5].classList.add('hidden')
      e.currentTarget.closest('dl').childNodes[3].classList.add('hidden')
    }
  }

  multiBankSelector(e) {

    if(this.selectedBanksTarget.value.split(',').indexOf(e.currentTarget.innerHTML) === -1) {
      e.currentTarget.classList.add('selected-button');
      this.selectedBanksTarget.value = this.selectedBanksTarget.value + ',' + e.currentTarget.innerHTML
    }
    else {
      e.currentTarget.classList.remove('selected-button');
      let result = this.removeArrayElement(this.selectedBanksTarget.value.split(','), e.currentTarget.innerHTML)
      this.selectedBanksTarget.value = result.join(',')
    }

    if(this.selectedBanksTarget.value.length > 2) {
      this.employmentBtnTarget.removeAttribute('disabled');
      this.employmentBtnTarget.classList.remove('disabled-button');
      this.employmentBtnTarget.classList.add('enabled-button');
    }
    else {
      this.employmentBtnTarget.setAttribute('disabled', true);
      this.employmentBtnTarget.classList.add('disabled-button');
      this.employmentBtnTarget.classList.remove('enabled-button');
    }
    if (this.selectedBanksTarget.value.includes('Other')){
      if(window.screen <= 768){
      this.lenderOtherBankModalTarget.style.display = 'block';}
      else {
      this.lenderOtherBankMobileModalTarget.style.display = 'block';}
    }else {
      if(window.screen <= 768){
      this.lenderOtherBankModalTarget.style.display = 'none';}
      else {
      this.lenderOtherBankMobileModalTarget.style.display = 'none';}
    }
    console.log(this.selectedBanksTarget.value) // Selected Banks
  }

  multiYearSelector(e) {
    const selectedEmployments = document.querySelectorAll('.selected-button');
    if(selectedEmployments.length > 0) {
      selectedEmployments.forEach(employment => {
        employment.classList.remove('selected-button');
      });
    }
    
    if(Object.values(e.currentTarget.classList).indexOf('selected-button') === -1) {
      e.currentTarget.classList.add('selected-button');
      this.selectedYearsTarget.value = e.currentTarget.innerHTML;
    }

    if((this.amountReceivedTargets[0].value.length > 0 || this.amountReceivedTargets[1].value.length > 0) && this.selectedYearsTarget.value.length > 2) {
      this.submitCompensationBtnTarget.removeAttribute('disabled');
      this.submitCompensationBtnTarget.classList.add('enabled-button');
      this.submitCompensationBtnTarget.classList.remove('disabled-button');
    }
    else {
      this.submitCompensationBtnTarget.setAttribute('disabled', true);
      this.submitCompensationBtnTarget.classList.add('disabled-button');
      this.submitCompensationBtnTarget.classList.remove('enabled-button');
    }
  }

  totalAmountField(e){
    if(e.target.value.length > 0 && e.target.value > 1) {
      this.claimProcessStartBtnTarget.removeAttribute('disabled');
      this.claimProcessStartBtnTarget.classList.add('enabled-button');
      this.claimProcessStartBtnTarget.classList.remove('disabled-button');
    }
    else {
      this.claimProcessStartBtnTarget.setAttribute('disabled', true);
      this.claimProcessStartBtnTarget.classList.add('disabled-button');
      this.claimProcessStartBtnTarget.classList.remove('enabled-button');
    }
  }

  amountField(e) {
    let net = 0.00
    if (e.target.value.length > 0){
      let tax = (e.target.value) * (11 / 100)
      // let gross = tax * 5
      // net = gross - tax
      // this.amountDeductedTarget.value = net.toFixed(2)
      this.amountDeductedTarget.value = tax.toFixed(2)
    }else{
      this.amountDeductedTarget.value = ''
    }
    if(e.target.value.length > 0 && this.selectedYearsTarget.value.length > 2) {
      this.submitCompensationBtnTarget.removeAttribute('disabled');
      this.submitCompensationBtnTarget.classList.add('enabled-button');
      this.submitCompensationBtnTarget.classList.remove('disabled-button');
    }
    else {
      this.submitCompensationBtnTarget.setAttribute('disabled', true);
      this.submitCompensationBtnTarget.classList.add('disabled-button');
      this.submitCompensationBtnTarget.classList.remove('enabled-button');
    }
  }

  validateDetailConsent() {
    let allDetailsVerified = true;
    let checkConsentone = this.checkConsentOneTarget.checked
    let checkConsentwo = this.checkConsentTwoTarget.checked;
    let checkConsentthree = this.checkConsentThreeTarget.checked;
    let checkConsentfour = this.checkConsentFourTarget.checked;

    if (checkConsentone && checkConsentwo && checkConsentthree && checkConsentfour){
      console.log('good to go');
      this.signatureBtnTarget.removeAttribute('disabled');
      this.signatureBtnTarget.classList.remove('disabled-button');
      this.signatureBtnTarget.classList.add('enabled-button');
    }
    else {
      this.signatureBtnTarget.setAttribute('disabled', true);
      this.signatureBtnTarget.classList.add('disabled-button');
      this.signatureBtnTarget.classList.remove('enabled-button');
    }
  }


  validateClientDetails(e) {
    let allDetailsVerified = true;
    let firstName = this.firstNameTarget.value;
    let lastName = this.lastNameTarget.value;
    let emailAddress = this.emailAddressTarget.value;
    let phoneNumber = this.phoneNumberTarget.value;
    let day = this.selectDayTarget.value;
    let month = this.selectMonthTarget.value;
    let year = this.selectDobYearTarget.value;
    let title = this.titleTarget.value;
    switch (e.currentTarget.id) {
      case "title":
        this.titleTarget.classList.add("selected-option");
      break;
      case "day":
        this.selectDayTarget.classList.add("selected-option");
      break;
      case "month":
        this.selectMonthTarget.classList.add("selected-option");
      break;
      case "year":
        this.selectDobYearTarget.classList.add("selected-option");
      break;
    }

    firstName = firstName.trim();
    lastName = lastName.trim();

    if (firstName == '' || lastName == '' || day == '' || month == '' || year == '' || title == '') {
      allDetailsVerified = false;
    } 

    if (emailAddress == '' || !(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(emailAddress))) {
      allDetailsVerified = false;
    }

    if (phoneNumber == '' || !(/^(01|07)\d{9}$/.test(phoneNumber))) {
      allDetailsVerified = false;
    }

    // if(!(document.querySelector('.checkmark-input').checked)) {
    //   allDetailsVerified = false;
    // }
    
    if(this.addressLine1Target.innerHTML === ''){
      allDetailsVerified = false;
    }

    if (allDetailsVerified == false){
      this.submitClientDetailsBtnTarget.setAttribute('disabled', true);
      this.submitClientDetailsBtnTarget.classList.add('disabled-button');
      this.submitClientDetailsBtnTarget.classList.remove('enabled-button');
    }
    else {
      console.log('good to go');
      this.submitClientDetailsBtnTarget.removeAttribute('disabled');
      this.submitClientDetailsBtnTarget.classList.remove('disabled-button');
      this.submitClientDetailsBtnTarget.classList.add('enabled-button');
    }
  }

  validateEmailAddress(e) {
    let emailAddress = e.target.value;
    let firstName = this.firstNameTarget.value;
    let lastName = this.lastNameTarget.value;
    let phoneNumber = this.phoneNumberTarget.value;
    let day = this.selectDayTarget.value;
    let month = this.selectMonthTarget.value;
    let year = this.selectDobYearTarget.value;
    let allDetailsVerified = true;

    document.querySelector('.required-email-error').innerHTML = 'Hello';
    // document.querySelector('.required-email-error').style.cssText = 'opacity: 0';
      document.querySelector('.required-email-error').style.cssText = 'max-height: 0px;';
    setTimeout(() => {  
      if(emailAddress == '') {
      // document.querySelector('.required-email-error').style.cssText = 'opacity: 1';
      document.querySelector('.required-email-error').style.cssText = 'max-height: 100px;';
      document.querySelector('.required-email-error').style.cssText = 'color: #E10000;';
      document.querySelector('.required-email-error').innerHTML = 'Please, enter your email';
    }
    else if(!(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(emailAddress))) {
      document.querySelector('.required-email-error').style.cssText = 'max-height: 100px;';
      document.querySelector('.required-email-error').style.cssText = 'color: #E10000;';
      document.querySelector('.required-email-error').innerHTML = 'Email is not correct';
    }
    else {
      document.querySelector('.required-email-error').innerHTML = 'Hello';
      document.querySelector('.required-email-error').style.cssText = 'max-height: 0px;';
    }
     }, 1000);

    if (firstName == '' || lastName == '' || day == '' || month == '' || year == '') {
      allDetailsVerified = false;
    } 

    if (emailAddress == '' || !(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(emailAddress))) {
      allDetailsVerified = false;
    }

    if (phoneNumber == '' || !(/^(01|07)\d{9}$/.test(phoneNumber))) {
      allDetailsVerified = false;
    }

    // if(!(document.querySelector('.checkmark-input').checked)) {
    //   allDetailsVerified = false;
    // }

    if(this.addressLine1Target.innerHTML === ''){
      allDetailsVerified = false;
    }

    if (allDetailsVerified == false){
      this.submitClientDetailsBtnTarget.setAttribute('disabled', true);
      this.submitClientDetailsBtnTarget.classList.add('disabled-button');
      this.submitClientDetailsBtnTarget.classList.remove('enabled-button');
    }
    else {
      console.log('good to go');
      this.submitClientDetailsBtnTarget.removeAttribute('disabled');
      this.submitClientDetailsBtnTarget.classList.remove('disabled-button');
      this.submitClientDetailsBtnTarget.classList.add('enabled-button');
    }
  }

  validatePhoneNumber(e) {
    let phoneNumber = e.target.value;
    let firstName = this.firstNameTarget.value;
    let lastName = this.lastNameTarget.value;
    let emailAddress = this.emailAddressTarget.value;
    let day = this.selectDayTarget.value;
    let month = this.selectMonthTarget.value;
    let year = this.selectDobYearTarget.value;
    let allDetailsVerified = true;


    document.querySelector('.user-phonenumber-error').innerHTML = 'Hello';
    document.querySelector('.user-phonenumber-error').style.cssText = 'max-height: 0px';

    setTimeout(() => {  
      if(phoneNumber == '' || !(/^(01|07)\d{9}$/.test(phoneNumber))) {
      document.querySelector('.user-phonenumber-error').style.cssText = 'max-height: 100px';
      document.querySelector('.user-phonenumber-error').style.cssText = 'color: #E10000;';
      document.querySelector('.user-phonenumber-error').innerHTML = 'Please enter a valid phone number';
    }
    else {
      document.querySelector('.user-phonenumber-error').innerHTML = 'Hello';
      document.querySelector('.user-phonenumber-error').style.cssText = 'max-height: 0px';
    }
     }, 1000);

    if (firstName == '' || lastName == '' || day == '' || month == '' || year == '') {
      allDetailsVerified = false;
    } 

    if (emailAddress == '' || !(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(emailAddress))) {
      allDetailsVerified = false;
    }

    if (phoneNumber == '' || !(/^(01|07)\d{9}$/.test(phoneNumber))) {
      allDetailsVerified = false;
    }

    // if(!(document.querySelector('.checkmark-input').checked)) {
    //   allDetailsVerified = false;
    // }
    
    if(this.addressLine1Target.innerHTML === ''){
      allDetailsVerified = false;
    }

    if (allDetailsVerified == false){
      this.submitClientDetailsBtnTarget.setAttribute('disabled', true);
      this.submitClientDetailsBtnTarget.classList.add('disabled-button');
      this.submitClientDetailsBtnTarget.classList.remove('enabled-button');
    }
    else {
      console.log('good to go');
      this.submitClientDetailsBtnTarget.removeAttribute('disabled');
      this.submitClientDetailsBtnTarget.classList.remove('disabled-button');
      this.submitClientDetailsBtnTarget.classList.add('enabled-button');
    }
  }

  validateNiNumber(e){
    let regex = new RegExp("^(?!BG|GB|KN|NK|NT|TN|ZZ)[^DFIQUV][^DFIQUVo][0-9]{6}[ABCD]$")

    if (e.target.value.length == 2) {
      let class_lists = Object.values(e.target.classList);
      let class_list = class_lists.find(val => val.includes('inputs'));
      let val = parseInt(class_list.split('_').pop()) + 1;
      document.querySelector(`.inputs_${val}`).focus();
    }

    let ni_number = document.querySelector('#nin-char-2').value + document.querySelector('#nin-number-1').value +
                     document.querySelector('#nin-number-2').value + document.querySelector('#nin-number-3').value +
                     document.querySelector('#nin-char-1').value;

    if(ni_number.length === 9 && this.stripNi(ni_number).match(regex)){
     this.enableInsuranceBtnTarget.removeAttribute('disabled');
     this.enableInsuranceBtnTarget.classList.remove('disabled-button');
     this.enableInsuranceBtnTarget.classList.add('enabled-button'); 
    }
    else {
      this.enableInsuranceBtnTarget.setAttribute('disabled', true);
      this.enableInsuranceBtnTarget.classList.add('disabled-button');
      this.enableInsuranceBtnTarget.classList.remove('enabled-button');
    }
  }



  canvasDraw(){
    // this.signatureBtnTarget.classList.remove('disabled-button');
    // this.signatureBtnTarget.classList.add('enabled-button');
    // this.signatureBtnTarget.removeAttribute('disabled');
    this.signHerePlaceholderTarget.classList.add('hidden');
  }

  stripNi(ni_value){
    return ni_value.toUpperCase().replace(/ /g,'')
  }



  clear() {
    this.signature_pad.clear();
    this.signHerePlaceholderTarget.classList.remove('hidden');
  }

  // submitQuestionaire() {
  //   let questionsModal = this.questionsModalTarget;
  //   let thanksModal = this.thanksModalTarget;
  //   let married, required_uniform, asked_to_work_from_home, vehicle_required;
  //   let data = new FormData();
  //   let urlAddress = window.location.href.split('/');
  //   let templateId = window.location.href.split('/').pop();
  //   let clientPostPackCode = urlAddress[urlAddress.length - 2];

  //   if (this.checkMarriedTargets[0].checked){
  //     married = true;
  //   } else{
  //     married = false;
  //   }
  //   if (this.checkWashUniformTargets[0].checked){
  //     required_uniform = true;
  //   } else{
  //     required_uniform = false;
  //   }
  //   if(this.checkWfhTargets[0].checked){
  //     asked_to_work_from_home = true;
  //   } else{
  //     asked_to_work_from_home = false;
  //   }
  //   if(this.checkPetrolTargets[0].checked){
  //     vehicle_required = true;
  //   } else{
  //     vehicle_required = false;
  //   }

  //   data.append('template_id', templateId);
  //   data.append('step', 8);
  //   data.append('married', married);
  //   data.append('required_uniform', required_uniform);
  //   data.append('asked_to_work_from_home', asked_to_work_from_home);
  //   data.append('vehicle_required', vehicle_required);


  //   Rails.ajax({
  //     url: `/onboarding_bd_update/${clientPostPackCode}`,
  //     type: 'patch',
  //     data: data,
  //     success: function(data) {
  //       questionsModal.style.display = 'none';
  //       thanksModal.style.display = 'block';
  //       document.getElementById('thanksForm').scrollIntoView({
  //         behavior: 'smooth',
  //         block: 'center'
  //       });
  //     },
  //     error: function(data) {}
  //   })
  // }

  annualIncome(e) {
    // const selectedIncomes = document.querySelectorAll('.annual-income-button');
    // if(selectedIncomes.length > 0) {
    //   selectedIncomes.forEach(income => {
    //     income.classList.remove('annual-income-button');
    //   });
    // }

    // if(Object.values(e.currentTarget.classList).indexOf('annual-income-button') === -1) {
      e.currentTarget.classList.add('annual-income-button');
      this.currentSalaryBracketTarget.value = e.currentTarget.innerHTML;
      this.submitEmploymentDetails();
    // }


    // if(document.querySelectorAll('.employment-button').length > 0 && document.querySelectorAll('.annual-income-button').length > 0) {
    //   this.cardDetailsTarget.classList.remove('disabled-button');
    //   this.cardDetailsTarget.classList.add('enabled-button');
    //   this.cardDetailsTarget.removeAttribute('disabled');
    // }
    // else {
    //   this.cardDetailsTarget.classList.add('disabled-button');
    //   this.cardDetailsTarget.classList.remove('enabled-button');
    //   this.cardDetailsTarget.setAttribute('disabled', 'true');
    // }
  }

  selectedWashButton(e) {
    if(e.currentTarget.classList.contains('washer-yes')){
      this.selectedWashYesTarget.style.backgroundColor = '#47c9a7';
      this.selectedWashYesTarget.style.color = '#fff';
      this.selectedWashNoTarget.style.backgroundColor = '#fff';
      this.selectedWashNoTarget.style.color = '#000';
    }
    else
    {
      this.selectedWashYesTarget.style.backgroundColor = '#fff';
      this.selectedWashYesTarget.style.color = '#000';
      this.selectedWashNoTarget.style.backgroundColor = '#47c9a7';
      this.selectedWashNoTarget.style.color = '#fff';
    }
    if((this.checkMarriedTargets[0].checked || this.checkMarriedTargets[1].checked) && (this.checkWashUniformTargets[0].checked || this.checkWashUniformTargets[1].checked) &&
        (this.checkWfhTargets[0].checked || this.checkWfhTargets[1].checked) && (this.checkPetrolTargets[0].checked || this.checkPetrolTargets[1].checked || this.checkPetrolTargets[2].checked))
    {
      this.submitQualifiersBtnTarget.removeAttribute('disabled');
      this.submitQualifiersBtnTarget.classList.remove('disabled-button');
      this.submitQualifiersBtnTarget.classList.add('enabled-button');
    }
    else {
      this.submitQualifiersBtnTarget.setAttribute('disabled', true);
      this.submitQualifiersBtnTarget.classList.add('disabled-button');
      this.submitQualifiersBtnTarget.classList.remove('enabled-button');
    }
  }

  selectedHome(e) {
    if(e.currentTarget.classList.contains('home-yes')){
      this.selectedHomeYesTarget.style.backgroundColor = '#47c9a7';
      this.selectedHomeYesTarget.style.color = '#fff';
      this.selectedHomeNoTarget.style.backgroundColor = '#fff';
      this.selectedHomeNoTarget.style.color = '#000';
    }
    else
    {
      this.selectedHomeYesTarget.style.backgroundColor = '#fff';
      this.selectedHomeYesTarget.style.color = '#000';
      this.selectedHomeNoTarget.style.backgroundColor = '#47c9a7';
      this.selectedHomeNoTarget.style.color = '#fff';
    }
    if((this.checkMarriedTargets[0].checked || this.checkMarriedTargets[1].checked) && (this.checkWashUniformTargets[0].checked || this.checkWashUniformTargets[1].checked) &&
        (this.checkWfhTargets[0].checked || this.checkWfhTargets[1].checked) && (this.checkPetrolTargets[0].checked || this.checkPetrolTargets[1].checked || this.checkPetrolTargets[2].checked))
    {
      this.submitQualifiersBtnTarget.removeAttribute('disabled');
      this.submitQualifiersBtnTarget.classList.remove('disabled-button');
      this.submitQualifiersBtnTarget.classList.add('enabled-button');
    }
    else {
      this.submitQualifiersBtnTarget.setAttribute('disabled', true);
      this.submitQualifiersBtnTarget.classList.add('disabled-button');
      this.submitQualifiersBtnTarget.classList.remove('enabled-button');
    }
  }

  selectedPetrol(e) {
    if(e.currentTarget.classList.contains('petrol-yes')){
      this.selectedPetrolYesTarget.style.backgroundColor = '#47c9a7';
      this.selectedPetrolYesTarget.style.color = '#fff';
      this.selectedPetrolNoTarget.style.backgroundColor = '#fff';
      this.selectedPetrolNoTarget.style.color = '#000';
      this.selectedPetrolNaTarget.style.backgroundColor = '#fff';
      this.selectedPetrolNaTarget.style.color = '#000';
    }
    else if(e.currentTarget.classList.contains('petrol-no'))
    {
      this.selectedPetrolYesTarget.style.backgroundColor = '#fff';
      this.selectedPetrolYesTarget.style.color = '#000';
      this.selectedPetrolNoTarget.style.backgroundColor = '#47c9a7';
      this.selectedPetrolNoTarget.style.color = '#fff';
      this.selectedPetrolNaTarget.style.backgroundColor = '#fff';
      this.selectedPetrolNaTarget.style.color = '#000';
    }
    else {
      this.selectedPetrolYesTarget.style.backgroundColor = '#fff';
      this.selectedPetrolYesTarget.style.color = '#000';
      this.selectedPetrolNoTarget.style.backgroundColor = '#fff';
      this.selectedPetrolNoTarget.style.color = '#000';
      this.selectedPetrolNaTarget.style.backgroundColor = '#47c9a7';
      this.selectedPetrolNaTarget.style.color = '#fff';
    }
    if((this.checkMarriedTargets[0].checked || this.checkMarriedTargets[1].checked) && (this.checkWashUniformTargets[0].checked || this.checkWashUniformTargets[1].checked) &&
        (this.checkWfhTargets[0].checked || this.checkWfhTargets[1].checked) && (this.checkPetrolTargets[0].checked || this.checkPetrolTargets[1].checked || this.checkPetrolTargets[2].checked))
    {
      this.submitQualifiersBtnTarget.removeAttribute('disabled');
      this.submitQualifiersBtnTarget.classList.remove('disabled-button');
      this.submitQualifiersBtnTarget.classList.add('enabled-button');
    }
    else {
      this.submitQualifiersBtnTarget.setAttribute('disabled', true);
      this.submitQualifiersBtnTarget.classList.add('disabled-button');
      this.submitQualifiersBtnTarget.classList.remove('enabled-button');
    }
  }

  selectedMarried(e) {
    if(e.currentTarget.classList.contains('married-yes')){
      this.selectedMarriedYesTarget.style.backgroundColor = '#47c9a7';
      this.selectedMarriedYesTarget.style.color = '#fff';
      this.selectedMarriedNoTarget.style.backgroundColor = '#fff';
      this.selectedMarriedNoTarget.style.color = '#000';
    }
    else
    {
      this.selectedMarriedYesTarget.style.backgroundColor = '#fff';
      this.selectedMarriedYesTarget.style.color = '#000';
      this.selectedMarriedNoTarget.style.backgroundColor = '#47c9a7';
      this.selectedMarriedNoTarget.style.color = '#fff';
    }
    if((this.checkMarriedTargets[0].checked || this.checkMarriedTargets[1].checked) && (this.checkWashUniformTargets[0].checked || this.checkWashUniformTargets[1].checked) &&
        (this.checkWfhTargets[0].checked || this.checkWfhTargets[1].checked) && (this.checkPetrolTargets[0].checked || this.checkPetrolTargets[1].checked || this.checkPetrolTargets[2].checked))
    {
      this.submitQualifiersBtnTarget.removeAttribute('disabled');
      this.submitQualifiersBtnTarget.classList.remove('disabled-button');
      this.submitQualifiersBtnTarget.classList.add('enabled-button');
    }
    else {
      this.submitQualifiersBtnTarget.setAttribute('disabled', true);
      this.submitQualifiersBtnTarget.classList.add('disabled-button');
      this.submitQualifiersBtnTarget.classList.remove('enabled-button');
    }
  }


  employmentStatus(e) {
    const selectedEmployments = document.querySelectorAll(".employment-button");
    const annualIncomeToggle = this.annualIncomeToggleTarget;
    const employmentStatusToggle = this.employmentStatusToggleTarget;

    if (selectedEmployments.length > 0) {
      selectedEmployments.forEach((employment) => {
        employment.classList.remove("employment-button");
      });
    }
    if (
      Object.values(e.currentTarget.classList).indexOf("employment-button") ===
      -1
    ) {
      e.currentTarget.classList.add("employment-button");
      this.currentEmploymentTarget.value = e.currentTarget.innerHTML;
      // annualIncomeToggle.classList.remove("hidden");
      employmentStatusToggle.style.transition = "max-height .25s ease-in-out";
      employmentStatusToggle.style.maxHeight = "0";
      annualIncomeToggle.style.transition = "max-height .5s ease-in-out";
      annualIncomeToggle.style.maxHeight = "500px";
      // this.cardDetailsTarget.classList.remove("hidden");
    }

    // if (
    //   document.querySelectorAll(".employment-button").length > 0 &&
    //   document.querySelectorAll(".annual-income-button").length > 0
    // ) {
    //   this.cardDetailsTarget.classList.remove("disabled-button");
    //   this.cardDetailsTarget.classList.add("enabled-button");
    //   this.cardDetailsTarget.removeAttribute("disabled");
    // } else {
    //   this.cardDetailsTarget.classList.add("disabled-button");
    //   this.cardDetailsTarget.classList.remove("enabled-button");
    //   this.cardDetailsTarget.setAttribute("disabled", "true");
    // }
  }

  removeArrayElement(arr, value) {
    return arr.filter(function(ele){
        return ele != value;
    });
  }

  swapFields(element1, element2) {
    element2.parentNode.insertBefore(element2, element1);
  }
}
