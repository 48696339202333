import { Controller } from 'stimulus'
import Rails from "@rails/ujs";
export default class extends Controller {
    static targets = ['emp_row', 'add_item','template', 'dyn_end_date_display','dyn_still_work']
connect(){
       let currentStorage = JSON.parse( localStorage.getItem(window.location))
    if (!currentStorage){

    }
    else{
        // targets used dyn_job_title_select_display, dyn_job_title_text_display, dyn_end_date_display, dyn_still_work
        // console.log(currentStorage)
        // console.log(currentStorage['leed[leed_employments_attributes][0][still_working_here]'])
        // console.log(currentStorage['leed[leed_employments_attributes][0][position]'])
        // console.log(currentStorage['leed[leed_employments_attributes][0][job_title]'])
        // console.log(currentStorage['leed[leed_employments_attributes][0][position_text]'])
if (currentStorage['leed[leed_employments_attributes][0][industry]'] && currentStorage['leed[leed_employments_attributes][0][job_title]']){
    this.dynIndustriesViewSelector(parseInt(currentStorage['leed[leed_employments_attributes][0][industry]']),0)
}
if (currentStorage['leed[leed_employments_attributes][0][still_working_here]']){
    if (currentStorage['leed[leed_employments_attributes][0][still_working_here]'] === '0'){
        this.dyn_end_date_displayTarget.classList.remove('hidden')
        document.getElementById(`dyn_end_date_day_0`).setAttribute("required", "required");
        document.getElementById(`dyn_end_month_0`).setAttribute("required", "required");
        document.getElementById(`dyn_end_date_year_0`).setAttribute("required", "required");
        this.dyn_still_workTarget.checked = false
    }
}
if (currentStorage['leed[leed_employments_attributes][0][end_date]'] && currentStorage['leed[leed_employments_attributes][0][end_date]'] !== ""){
    this.add_itemTarget.classList.remove('hidden')
}
    }

    const allIndexes = document.querySelectorAll("[name^=indexes]");
    var total_rows = parseInt(allIndexes.length) - 1;
    console.log(total_rows)
    if (total_rows > 0){



    for (let i = 0; i <= total_rows; i++) {
        let final_start =   document.getElementById(`final_dyn_start_date_${i}`);
        let start_date_day = document.getElementById(`dyn_day_start_${i}`);
        let start_date_month = document.getElementById(`dyn_month_start_${i}`);
        let start_date_year = document.getElementById(`dyn_year_start_${i}`);

        let still_work = document.getElementById(`dyn_still_work_${i}`)
        let still_work_final = document.getElementById(`final_dyn_still_work_${i}`)
        let end_work_display = document.getElementById(`dyn_end_date_display_${i}`)

        let year = final_start.value.split('-')[0];
        let day = final_start.value.split('-')[1];
        let month = final_start.value.split('-')[2];

        start_date_day.value = day
        start_date_month.value = month
        start_date_year.value = year
        console.log(still_work_final.value )
//check if still work is true or false
        if (still_work_final.value === '0' || still_work_final.value === 'false' ){
            still_work.checked = false
            end_work_display.classList.remove('hidden')
            let final_end = document.getElementById(`dyn_final_end_date_${i}`);
            let end_date_day = document.getElementById(`dyn_end_date_day_${i}`)
            let end_date_month = document.getElementById(`dyn_end_month_${i}`)
            let end_date_year = document.getElementById(`dyn_end_date_year_${i}`)
            if(final_end)
            {
                let end_year = final_end.value.split('-')[0];
                let end_day = final_end.value.split('-')[1];
                let end_month = final_end.value.split('-')[2];
                end_date_day.value = end_day
                end_date_month.value = end_month
                end_date_year.value = end_year
                end_date_day.setAttribute("required", "required");
                end_date_month.setAttribute("required", "required");
                end_date_year.setAttribute("required", "required");
            }
        }

    }

}
}
// functionality start
    salaryBracketsChange() {
// targets used: salary_bracket
        let index = event.currentTarget.getAttribute("data-index");
        let salary_bracket = document.getElementById(`dyn_salary_bracket_${index}`)

        if (salary_bracket.value === '' ) {

            salary_bracket.classList.add("border_change");

        } else {
            salary_bracket.classList.remove("border_change");
        }

    }

    dynIndustryChange() {
// targets used: dyn_industry, dyn_job_title
        let index = event.currentTarget.getAttribute("data-index");
        let dynIndustry = document.getElementById(`dyn_industry_${index}`)
        let selectBox = document.getElementById(`dyn_job_title_${index}`)


        selectBox.innerHTML = '';
        if (dynIndustry.value > 1) {


            Rails.ajax({
                url: `/get_positions/${dynIndustry.value}`,
                type: 'GET',
                dataType: "script",
                credentials: "include",
                error: function (jqXHR, textStatus, errorThrown) {
                    console.log("AJAX Error:", textStatus)
                    alert('There was an error.')
                },
                success: data => {
                    const blank = document.createElement('option');
                    blank.value = '';
                    blank.innerHTML = 'Select an title';
                    selectBox.appendChild(blank);
                    let positions = data['positions_by_industry'];
                    for (let i = 0; i < positions.length; i++) {
                        const opt = document.createElement('option');
                        opt.value = positions[i]["name"];
                        opt.innerHTML = positions[i]["name"];
                        selectBox.appendChild(opt);


                    }
                }

            })

            this.dynIndustriesViewSelector(dynIndustry.value,index)


        }

    }

    dynPositionsChange() {

       //targets used : dyn_job_title ,submit_dyn_job_title
        let index = event.currentTarget.getAttribute("data-index");
        let dyn_job_title_select = document.getElementById(`dyn_job_title_${index}`)
        let final_record = document.getElementById(`submit_dyn_job_title_${index}`)


        if (dyn_job_title_select.value.length >= 3) {
            const title = dyn_job_title_select.value;
            final_record.value = title
        }

    }

    dynCheckPositionText() {

        let index = event.currentTarget.getAttribute("data-index");
        let dyn_job_title_text = document.getElementById(`dyn_job_text_title_${index}`)
        let dyn_job_title_text_error = document.getElementById(`dyn_job_title_text_error_${index}`)
        let final_record = document.getElementById(`submit_dyn_job_title_${index}`)

        if (dyn_job_title_text.value.length > 1) {
            dyn_job_title_text_error.classList.add('hidden')
            dyn_job_title_text.classList.remove('border_change')
        } else {
            dyn_job_title_text_error.classList.remove('hidden')
            dyn_job_title_text.classList.add('border_change')
        }
        let valid_position = this.validateText(dyn_job_title_text.value)

        dyn_job_title_text.value = valid_position
        final_record.value = valid_position

    }


    dynCheckEmpName() {

        let index = event.currentTarget.getAttribute("data-index");
        let dyn_emp_value = document.getElementById(`dyn_emp_name_${index}`).value
        if (dyn_emp_value.length > 1) {
            document.getElementById(`dyn_employer_name_error_${index}`).classList.add('hidden')
            document.getElementById(`dyn_emp_name_${index}`).classList.remove("border_change");

        } else {
            document.getElementById(`dyn_employer_name_error_${index}`).classList.remove('hidden')
            document.getElementById(`dyn_emp_name_${index}`).classList.add("border_change");

        }
    }

    dynSetStartDate(){
        let index = event.currentTarget.getAttribute("data-index");
        let start_date_day = document.getElementById(`dyn_day_start_${index}`).value
        let start_date_month = document.getElementById(`dyn_month_start_${index}`).value
        let start_date_year = document.getElementById(`dyn_year_start_${index}`).value
        let final_dyn_start_date = document.getElementById(`final_dyn_start_date_${index}`)


        if (start_date_month === '04') {
            document.getElementById(`date29_${index}`).classList.remove("hidden");
            document.getElementById(`date30_${index}`).classList.remove("hidden");
            document.getElementById(`date31_${index}`).classList.add("hidden");
        } else if (start_date_month === '06') {
            document.getElementById(`date29_${index}`).classList.remove("hidden");
            document.getElementById(`date30_${index}`).classList.remove("hidden");
            document.getElementById(`date31_${index}`).classList.add("hidden");
        } else if (start_date_month === '09') {
            document.getElementById(`date29_${index}`).classList.remove("hidden");
            document.getElementById(`date30_${index}`).classList.remove("hidden");
            document.getElementById(`date31_${index}`).classList.add("hidden");
        } else if (start_date_month === '11') {
            document.getElementById(`date29_${index}`).classList.remove("hidden");
            document.getElementById(`date30_${index}`).classList.remove("hidden");
            document.getElementById(`date31_${index}`).classList.add("hidden");
        } else {
            document.getElementById(`date29_${index}`).classList.remove("hidden");
            document.getElementById(`date30_${index}`).classList.remove("hidden");
            document.getElementById(`date31_${index}`).classList.remove("hidden");
        }

        if (start_date_year) {
            let leapYear = start_date_year % 4 === 0

            if (!leapYear && start_date_month === '02') {

                document.getElementById(`date29_${index}`).classList.add("hidden");
                document.getElementById(`date30_${index}`).classList.add("hidden");
                document.getElementById(`date31_${index}`).classList.add("hidden");

            } else if (leapYear && start_date_month === '02') {

                document.getElementById(`date29_${index}`).classList.remove("hidden");
                document.getElementById(`date30_${index}`).classList.add("hidden");
                document.getElementById(`date31_${index}`).classList.add("hidden");


            }
        }

        if (start_date_day && start_date_month && start_date_year) {
            const myDateStart = new Date(`${start_date_day}-${start_date_month}-${start_date_year}`);
            final_dyn_start_date.value = myDateStart
            console.log(final_dyn_start_date.value)
            if ( final_dyn_start_date.value === 'Invalid Date')
            {
                document.getElementById(`dyn_day_start_${index}`).selectedIndex = 0;
                document.getElementById(`dyn_month_start_${index}`).selectedIndex = 0;
                document.getElementById(`dyn_year_start_${index}`).selectedIndex = 0;
                final_dyn_start_date.value = null
            }

        }

    }

    dynSetEndDate(){
        let index = event.currentTarget.getAttribute("data-index");
        let end_date_day = document.getElementById(`dyn_end_date_day_${index}`).value
        let end_date_month = document.getElementById(`dyn_end_month_${index}`).value
        let end_date_year = document.getElementById(`dyn_end_date_year_${index}`).value
        let final_dyn_end_date = document.getElementById(`dyn_final_end_date_${index}`)

        if (end_date_month === '04') {
            document.getElementById(`end_date29_${index}`).classList.remove("hidden");
            document.getElementById(`end_date30_${index}`).classList.remove("hidden");
            document.getElementById(`end_date31_${index}`).classList.add("hidden");
        } else if (end_date_month === '06') {
            document.getElementById(`end_date29_${index}`).classList.remove("hidden");
            document.getElementById(`end_date30_${index}`).classList.remove("hidden");
            document.getElementById(`end_date31_${index}`).classList.add("hidden");
        } else if (end_date_month === '09') {
            document.getElementById(`end_date29_${index}`).classList.remove("hidden");
            document.getElementById(`end_date30_${index}`).classList.remove("hidden");
            document.getElementById(`end_date31_${index}`).classList.add("hidden");
        } else if (end_date_month === '11') {
            document.getElementById(`end_date29_${index}`).classList.remove("hidden");
            document.getElementById(`end_date30_${index}`).classList.remove("hidden");
            document.getElementById(`end_date31_${index}`).classList.add("hidden");
        } else {
            document.getElementById(`end_date29_${index}`).classList.remove("hidden");
            document.getElementById(`end_date30_${index}`).classList.remove("hidden");
            document.getElementById(`end_date31_${index}`).classList.remove("hidden");
        }

        if (end_date_year) {
            let leapYear = end_date_year % 4 === 0

            if (!leapYear && end_date_month === '02') {

                document.getElementById(`end_date29_${index}`).classList.add("hidden");
                document.getElementById(`end_date30_${index}`).classList.add("hidden");
                document.getElementById(`end_date31_${index}`).classList.add("hidden");

            } else if (leapYear && end_date_month === '02') {

                document.getElementById(`end_date29_${index}`).classList.remove("hidden");
                document.getElementById(`end_date30_${index}`).classList.add("hidden");
                document.getElementById(`end_date31_${index}`).classList.add("hidden");


            }
        }

        if (end_date_day && end_date_month && end_date_year) {
            final_dyn_end_date.value = ''
            const myDateEnd = new Date(`${end_date_day}-${end_date_month}-${end_date_year}`);

            console.log(end_date_day)
            console.log(myDateEnd)
            final_dyn_end_date.value = myDateEnd
            if ( final_dyn_end_date.value === 'Invalid Date')
            {
                document.getElementById(`dyn_end_date_day_${index}`).selectedIndex = 0;
                document.getElementById(`dyn_end_month_${index}`).selectedIndex = 0;
                document.getElementById(`dyn_end_date_year_${index}`).selectedIndex = 0;

            }else{
                this.add_itemTarget.classList.remove('hidden')

            }


        }
    }

    dynToggle() {
        let index = event.currentTarget.getAttribute("data-index");
        let still_work = document.getElementById(`dyn_still_work_${index}`)
        let end_work_display = document.getElementById(`dyn_end_date_display_${index}`)
        let final_work_display = document.getElementById(`final_dyn_still_work_${index}`)
        let final_dyn_end_date = document.getElementById(`dyn_final_end_date_${index}`)

        if (still_work.checked === true) {
            end_work_display.classList.add('hidden')
            final_work_display.value = '1'
            document.getElementById(`dyn_end_date_day_${index}`).selectedIndex = 0;
            document.getElementById(`dyn_end_month_${index}`).selectedIndex = 0;
            document.getElementById(`dyn_end_date_year_${index}`).selectedIndex = 0;
            document.getElementById(`dyn_end_date_day_${index}`).removeAttribute("required", "required");
            document.getElementById(`dyn_end_month_${index}`).removeAttribute("required", "required");
            document.getElementById(`dyn_end_date_year_${index}`).removeAttribute("required", "required");

            final_dyn_end_date.value = ''
            this.add_itemTarget.classList.add('hidden')
        } else {
            end_work_display.classList.remove('hidden')
            final_work_display.value = '0'
            document.getElementById(`dyn_end_date_day_${index}`).setAttribute("required", "required");
            document.getElementById(`dyn_end_month_${index}`).setAttribute("required", "required");
            document.getElementById(`dyn_end_date_year_${index}`).setAttribute("required", "required");

        }


    }
// functionality end



    // helper functions start
    dynIndustriesViewSelector(id,index) {
        // targets used:  dyn_job_title_select_display, dyn_job_title_text_display
        const array = [39,111]
        let selectClassCheck = document.getElementById(`dyn_job_title_select_display_${index}`)
        let textClassCheck = document.getElementById(`dyn_job_title_text_display_${index}`)

        if (array.includes(parseInt(id))) {

            selectClassCheck.classList.add("hidden");
            textClassCheck.classList.remove("hidden");
            document.getElementById(`dyn_job_title_${index}`).removeAttribute("required", "required");
            document.getElementById(`dyn_job_text_title_${index}`).setAttribute("required", "required");
            document.getElementById(`dyn_job_title_${index}`).innerHTML = ''
        } else {

            textClassCheck.classList.add("hidden");
            selectClassCheck.classList.remove("hidden");
            document.getElementById(`dyn_job_text_title_${index}`).removeAttribute("required", "required");
            document.getElementById(`dyn_job_title_${index}`).setAttribute("required", "required");
           if(document.getElementById(`dyn_job_title_text_${index}`)){
               document.getElementById(`dyn_job_title_text_${index}`).value = ''
           }
        }
    }

    validateText(text) {
        const noSpecialCharacters = text.replace(/[^a-zA-Z0-9 ]/g, '');
        let c_text = noSpecialCharacters.charAt(0).toUpperCase() + noSpecialCharacters.slice(1);
        return c_text
    }

    // helper functions end            final_dyn_end_date.value = null



    add_employment(event){
        event.preventDefault()
        const allIndexes = document.querySelectorAll("[name^=indexes]");
        var total_rows = parseInt(allIndexes.length);
        console.log(total_rows)
        if(total_rows <=2){
        let content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().getTime())
        this.add_itemTarget.insertAdjacentHTML('beforebegin', content)

            this.add_itemTarget.classList.add('hidden')
        }

    }

    remove_employment(event){
        event.preventDefault()
        let item = event.target.closest(".nested-fields")
        if(item.dataset.newRecord === 'true'){
            item.remove()
        }
        else{
            item.querySelector("input[name*='_destroy']").value = 1
            item.style.display = 'none'
        }

    }
}

