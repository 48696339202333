import SignaturePad from "signature_pad";
import { Controller } from "stimulus"
import ToggleController from 'stimulus-toggle'

export default class extends Controller {
    static targets = ["terms", "data_permission", "authorisation","next", "clear", "error_container", "icon", "error_title", "error_text_container", "error_text", "signature", "ni_number", "current_income", "income2017", "income2018", "income2019", "income2020", "income2021", "incomeBracket2018", "incomeBracket2019", "incomeBracket2020", "incomeBracket2021", "sa_completed", "sa_not_completed", "amountReceived", "offerMonth", "offerYear", "lender", "lenderTable", "employmentStartDate", "employmentEndDate", "worked2020", "worked2021", "expensesPaid", "alreadyClaimed", "jobTitle", "employerName", "industry", "selfAssessment", "startDay", "startMonth", "startYear", "stillWorkingHere", "endDay", "endMonth", "endYear"];

     connect(){
        // console.log(this.yesLenderTargets[1].name)
         const canvas = document.querySelector("canvas");
         if (canvas){
             console.log('sig pad')

             //this.resizeCanvas(canvas);
             this.signature_pad = new SignaturePad(canvas, {drawOnly:true});
         }


     }

    allChecked(){
        let allChecked = true
        document.querySelectorAll('.checkmark-input').forEach(function (item, index) {
            if (!item.checked)
                allChecked = false;
        });
        if (!allChecked) {

            return false;
        } else {
            this.error_containerTarget.classList.remove('show');
            this.error_containerTarget.classList.add('hide');
        }
    }
    terms() {
        let allChecked = true
        document.querySelectorAll('.checkmark-input').forEach(function (item, index) {
            if (!item.checked)
                allChecked = false;
        });
        if (!allChecked) {
            this.showNotif('Please tick the boxes above to proceed', 'error');
            return false;
        } else {
            this.error_containerTarget.classList.remove('show');
            this.error_containerTarget.classList.add('hide');
            this.nextTarget.closest('form').submit()
        }
    }

    signature(event){
         let dots = this.signature_pad.toData()



            if (this.signature_pad.isEmpty()){

                event.preventDefault();
                this.showNotif('Please sign before proceeding.', 'error')
            }
            else if (dots && dots[0].points.length <= 10){
              event.preventDefault();
              this.showNotif('Please add a full signature. Clear and try again.', 'error')
                console.log(dots.length)
           }
         else {
                event.preventDefault();
                this.error_containerTarget.classList.remove('show');
                this.error_containerTarget.classList.add('hide');
                this.signatureTarget.value = this.signature_pad.toDataURL()

                this.signatureTarget.closest('form').submit()
            }


    }

    checkNiOnType(){

        if (this.ni_numberTarget.value.length === 9) {
            this.ni_numberTarget.value = this.ni_numberTarget.value.toUpperCase().replace(/ /g,'')
            if(isNino(this.ni_numberTarget.value)){
                this.error_containerTarget.classList.remove('show');
                this.error_containerTarget.classList.add('hide');

            } else{
                console.log('mismatch keyup')
                // there is a mismatch, hence show the error message
                this.showNotif('The national insurance number entered is in an incorrect format, please check and try again.', 'error')
                this.ni_numberTarget.value.toUpperCase().replace(/ /g,'')
            }

        }

    }


    validateNiNumber(e){
        e.preventDefault()
        let ni_field = this.ni_numberTarget
        this.stripNi(ni_field.value);
        let regex = new RegExp("^(?!BG|GB|KN|NK|NT|TN|ZZ)[^DFIQUV][^DFIQUVo][0-9]{6}[ABCD]$")

        if (ni_field.value.length == 0){
            console.log('blank')
            this.showNotif('The National Insurance number field is blank, please enter it before continuing.', 'error')
            e.preventDefault()
        }
        else if (ni_field.value.length === 9  && this.stripNi(ni_field.value).match(regex)) {
            this.error_containerTarget.classList.remove('show');
            this.error_containerTarget.classList.add('hide');
            console.log('good to go')
            // else, do not display message

            ni_field.value.toUpperCase().replace(/ /g,'')
            this.ni_numberTarget.closest('form').submit()
        }
        else{
            this.ni_numberTarget.value.toUpperCase().replace(/ /g,'')
            console.log('mismatch')
            // there is a mismatch, hence show the error message
            this.showNotif('The national insurance number entered is in an incorrect format, please check and try again.', 'error')
            this.ni_numberTarget.value = this.ni_numberTarget.value.toUpperCase().replace(/ /g,'')
            e.preventDefault()

        }

    }

    checkIncomeOnType(){
        let income1 = this.income2017Target.value.length
        let income2 = this.income2018Target.value.length
        let income3 = this.income2019Target.value.length
        let income4 = this.income2020Target.value.length

        if ( ( income1 == 0 || income1 > 6) || ( income2 == 0 || income2 > 6 ) || ( income3 == 0 || income3 > 6 ) || (income4 == 0 || income4 > 6 ) ){

        } else {
            this.error_containerTarget.classList.remove('show');
            this.error_containerTarget.classList.add('hide');
        }
    }

    currentIncome(e){
         console.log('current')
         e.preventDefault()
        let income1 = this.income2017Target.value.length
        let income2 = this.income2018Target.value.length
        let income3 = this.income2019Target.value.length
        let income4 = this.income2020Target.value.length

       if (income1 == 0 || income2 == 0 || income3 == 0 || income4 == 0  ){
           this.showNotif('Please enter your income for all tax years to continue.', 'error')
        }
        else if (income1 > 6 || income2 > 6 || income3 > 6 || income4 > 6){
            this.showNotif('Please enter income for all tax years less than 6 digits to continue.', 'error')
        }
        else {
           this.income2017Target.closest('form').submit()
       }
    }

    currentIncomeBracket(event){
        console.log('income bracket')
        event.preventDefault()
        let income1 = this.incomeBracket2018Target.value.length
        let income2 = this.incomeBracket2019Target.value.length
        let income3 = this.incomeBracket2020Target.value.length
        let income4 = this.incomeBracket2021Target.value.length

        if (income1 == 0 || income2 == 0 || income3 == 0 || income4 == 0  ){
           this.showNotif('Please enter your income for all tax years to continue.', 'error')
        }
        else {
            this.incomeBracket2018Target.closest('form').submit()
        }
    }


    resizeCanvas(canvas) {
        var ratio =  Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);
        console.log('resize canvas')
    }

    stripNi(ni_value){

        return ni_value.toUpperCase().replace(/ /g,'')
    }

    showNotif(msg, type) {
         console.log('show notif')
        this.error_textTarget.innerHTML  = msg
        if (type === 'success'){
            this.error_containerTarget.classList.remove('bg-red-50')
            this.iconTarget.classList.remove('text-red-400')
            this.error_titleTarget.classList.remove('text-red-800')
            this.error_text_containerTarget.classList.remove('text-red-700')

            this.error_containerTarget.classList.add('bg-green-50')
            this.iconTarget.classList.add('text-green-400')
            this.error_titleTarget.classList.add('text-green-800')
            this.error_text_containerTarget.classList.add('text-green-700')


        } else {
            this.error_containerTarget.classList.add('bg-red-50')
            this.iconTarget.classList.add('text-red-400')
            this.error_titleTarget.classList.add('text-red-800')
            this.error_text_containerTarget.classList.add('text-red-700')

            this.error_containerTarget.classList.remove('bg-green-50')
            this.iconTarget.classList.remove('text-green-400')
            this.error_titleTarget.classList.remove('text-green-800')
            this.error_text_containerTarget.classList.remove('text-green-700')
        }
        this.error_containerTarget.classList.remove('hide');
        this.error_containerTarget.classList.add('show');

    }

    clear() {

        this.signature_pad.clear();

    }

    saCompleted(){
         if (this.sa_completedTarget.clicked == true || 'true'){
             let form = this.sa_completedTarget.closest('form')
             let input = document.createElement('input')
             input.setAttribute('hidden', 'true')
             input.setAttribute('name', 'sa_completed')
             input.setAttribute('value', 'true')
             form.appendChild(input)
         } else if (this.sa_not_completedTarget.clicked == true || 'true'){
             let form = this.sa_completedTarget.closest('form')
             let input = document.createElement('input')
             input.setAttribute('hidden', 'true')
             input.setAttribute('name', 'sa_completed')
             input.setAttribute('value', 'false')
             form.appendChild(input)
         }
    }

    validateOfferAmount(event) {
        console.log('validating lender offer amount')
        let isValidate = false;

        for ( let element of this.amountReceivedTargets) {
            if (element.value.split('.')[0].length <= 5) {
                isValidate = true
            }
            else {
                isValidate = false
                break;
            }
        }

        if (isValidate || this.amountReceivedTargets.length == 0) {
            this.validateLenderInformation()
        }
        else {
            this.showNotif('Please enter 5 digits or less for amount received to continue', 'error')
        }
    }

    validateLenderInformation() {
        console.log('validating lender information')

        let isValidate = false;
        let amountReceived;
        let offerMonth;
        let offerYear;
        let lenderName;

        for ( let element of this.amountReceivedTargets) {
            amountReceived = element.value
            offerMonth = this.offerMonthTargets.find(tar => tar.name.split('_').pop() == element.name.split('_').pop()).value
            offerYear = this.offerYearTargets.find(tar => tar.name.split('_').pop() == element.name.split('_').pop()).value
            lenderName = this.lenderTargets.find(tar => tar.name.split('_').pop() == element.name.split('_').pop()).value

            if (amountReceived != '' && offerMonth != '' && offerYear != '' && lenderName != ''){
                isValidate = true;
                break;
            }
            else {
                isValidate = false;
            }
        }

        if (isValidate == true) {
            this.amountReceivedTarget.closest('form').submit()
        }
        else {
            this.showNotif('Please fill the data for at-least one lender', 'error')
        }
    }

    validateWorkFromHome(event) {
        let isWorked2020;
        let isWorked2021;
        if (this.worked2020Targets[0].checked || this.worked2020Targets[1].checked){
            isWorked2020 = true;
        }
        else {
            isWorked2020 = false;
        }

        if (this.worked2021Targets[0].checked || this.worked2021Targets[1].checked)
            isWorked2021 = true
        else {
            isWorked2021 = false
        }
        if (isWorked2020 == true && isWorked2021 == true){
            this.worked2020Target.closest('form').submit()
        }
        else {
            this.showNotif('Please complete the form', 'error')
        }

    }

    validateClaimEligibility(event) {
        let isExpensesPaid, isAlreadyClaimed;
        if (this.expensesPaidTargets[0].checked || this.expensesPaidTargets[1].checked){
            isExpensesPaid = true;
        }
        else {
            isExpensesPaid = false;
        }

        if (this.alreadyClaimedTargets[0].checked || this.alreadyClaimedTargets[1].checked) {
            isAlreadyClaimed = true;
        }
        else {
            isAlreadyClaimed = false;
        }

        if (isExpensesPaid == true && isAlreadyClaimed == true){
            this.expensesPaidTarget.closest('form').submit()
        }
        else {
            this.showNotif('Please complete the form', 'error')
        }
    }

    checkSelfAssessment(event) {
        let isSelfAssessment = event.target.value;
        if (isSelfAssessment == 'yes') {
            this.employmentStartDateTarget.classList.add('hidden-fields')
            this.employmentEndDateTarget.classList.add('hidden-fields')
        }
        else if( isSelfAssessment == 'no') {
            this.employmentStartDateTarget.classList.remove('hidden-fields')
        }
    }

    checkStillWorking(event) {
        let isStillWorking = event.target.value;
        if (isStillWorking == 'yes') {
            this.employmentEndDateTarget.classList.add('hidden-fields')
        }
        else if(isStillWorking == 'no') {
            this.employmentEndDateTarget.classList.remove('hidden-fields')
        }
    }

    validateEmploymentDetails(event) {
        let isSelfAssessment = false;
        let commonFields = false;
        let dynamicFields = false;
        let employerName, jobTitle, industry, startDay, startMonth, startYear, endDay, endMonth, endYear;
        employerName = this.employerNameTarget.value;
        jobTitle = this.jobTitleTarget.value;
        industry = this.industryTarget.value;
        startDay = this.startDayTarget.value;
        startMonth = this.startMonthTarget.value;
        startYear = this.startYearTarget.value;
        endDay = this.endDayTarget.value;
        endMonth = this.endMonthTarget.value;
        endYear = this.endYearTarget.value;

        if (employerName == '' || jobTitle == '' || industry == ''){
            commonFields = false;
        }
        else {
            commonFields = true;
        }

        if (this.selfAssessmentTargets[0].checked || this.selfAssessmentTargets[1].checked) {
            isSelfAssessment = true;
        }
        else {
            isSelfAssessment = false;
        }

        if (isSelfAssessment == true) {
            if (this.selfAssessmentTargets[1].checked) {
                if (startDay !== '' && startMonth !== '' && startYear !== '' && (this.stillWorkingHereTargets[0].checked || this.stillWorkingHereTargets[1].checked)) {
                    dynamicFields = true;
                }
                else {
                    dynamicFields = false;
                }
            }
            else {
                dynamicFields = true;
            }
        }

        if (this.selfAssessmentTargets[1].checked && dynamicFields && this.stillWorkingHereTargets[1].checked) {
            if (endDay == '' || endMonth == '' || endYear == ''){
                dynamicFields = false;
            }
        }


        if (commonFields == false || isSelfAssessment == false || dynamicFields == false) {
            this.showNotif('Please fill the complete form', 'error');
        }
        else {
            this.employerNameTarget.closest('form').submit();
        }
    }

    hideLenderTable(event) {
        let tableTarget = this.lenderTableTargets.find( tar => tar.id.split('_').pop() == event.target.id.split('_').pop())
        let inputs = tableTarget.getElementsByTagName("input")
        inputs[1].value  = ''
        tableTarget.style.display = 'none';
    }

}
