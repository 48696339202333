import { Controller } from "stimulus"
export default class extends Controller {
    static targets = [ "source", "target" ];

    handleSelectChange() {
        this.populateSelect(this.sourceTarget.value);
    }

    removeOptions(selectElement) {
        var i, L = selectElement.options.length - 1;
        for(i = L; i >= 0; i--) {
            selectElement.remove(i);
        }
    }

    resetOnly(){
        //var list =
        document.querySelector('#list_id').remove()
        //list.classList.toggle('hidden')
        //list.classList.toggle('input')
        //list.disabled = true
        //var introducer =
         document.querySelector('#introducer_id').remove()
        //introducer.classList.toggle('hidden')
        //introducer.classList.toggle('input')
        //introducer.disabled = true

    }

    populateSelect(sourceId, targetId = null) {

        var list_select = document.querySelector('#list_id')
        this.removeOptions(list_select);

        Rails.ajax({
            type: 'GET',
            dataType: 'script',
            url: `/${this.data.get('sourceRoutePart')}/${sourceId}/${this.data.get('targetRoutePart')}`,
            error: function(jqXHR, textStatus, errorThrown)  {
                console.log("AJAX Error:", textStatus)
            },
            success: (data) =>  {
                console.log("Dynamic operator select OK!", data)
                let lists = data;
                console.log("lists: ", lists)
                for (var i = 0; i < lists.length; i++) {
                    var opt = document.createElement('option');
                    opt.value = lists[i]["id"];
                    opt.innerHTML = lists[i]["name"];
                    list_select.appendChild(opt);


                }

            }
        })

    }

    validate(e){
        e.preventDefault();
        var ddl = document.getElementById("list_id");
        var selectedValue = ddl.options[ddl.selectedIndex].text;
        if (selectedValue == "Select One")
        {
            alert("Please select an Introducer, followed by a List.");
        } else {
           document.getElementById('client-list-form').submit()
        }
    }


}