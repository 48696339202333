import {Controller} from 'stimulus'

import Rails from "@rails/ujs"
import SignaturePad from "signature_pad";
import isNino from "is-national-insurance-number";

export default class extends Controller {
    static targets = ['form', 'first_name','dyn_add_button', 'name_cross', 'email', 'email_cross', 'dob_final', 'dob_cross', 'lastname_cross', 'last_name', 'previous_uniform_no', 'previous_uniform_yes', 'previous_uniform', 'claim_authorisation', 'data_permission', 'terms', 'signature_display', 'signature', 'error_container', 'icon', 'error_title', 'error_text_container', 'error_text', 'address1_cross', 'address1', 'address2_cross', 'address2', 'city', 'city_cross', 'county', 'county_cross', 'postcode', 'postcode_cross', 'job_change_no', 'job_change_yes', 'next_display',  'ni_number', 'ni_number_cross',  'dob_date_day', 'dob_date29', 'dob_date30', 'dob_date31', 'dob_date_month', 'dob_date_year', 'dob_full_date', 'current_temp','job_change_display']





    connect() {
        this.setLocalStorage()

let current_tmp = document.getElementById('dyn_current_id')

        if(current_tmp){

        localStorage.setItem('temp_id', current_tmp.value);



            let  existing_temp_id =   current_tmp.value
            if(existing_temp_id === '0'){
            this.existingTempDateSet(0)
            }
            else if (existing_temp_id === '1'){
                this.existingTempDateSet(0)
                this.existingTempDateSet(1)
            }
            else if (existing_temp_id === '2'){
                this.existingTempDateSet(0)
                this.existingTempDateSet(1)
                this.existingTempDateSet(2)
            }
            else if (existing_temp_id === '3'){
                this.existingTempDateSet(0)
                this.existingTempDateSet(1)
                this.existingTempDateSet(2)
                this.existingTempDateSet(3)
            }
            }

        this.localStorageKey = window.location

        // Retrieve data from localStorage when the Controller loads.
        this.setFormData();

        const canvas = document.querySelector("canvas");
        if (canvas) {


            //this.resizeCanvas(canvas);
            this.signature_pad = new SignaturePad(canvas, {drawOnly: true});
        }






    }

    existingTempDateSet(i)
    {

        let final_dyn_start_date = document.getElementById(`final_dyn_start_date_${i}`).value
        let final_dyn_end_date = document.getElementById(`dyn_final_end_date_${i}`).value


        const d = new Date(final_dyn_start_date);

        let date = this.formatWithLeadingZero(d.getDate());
        let month =  this.formatWithLeadingZero( d.getMonth() + 1) ; // Since getUTCMonth() returns month from 0-11 not 1-12
        let year = d.getFullYear();



        console.log(date,month,year)
        document.getElementById(`dyn_day_start_${i}`).value = date;
        document.getElementById(`dyn_month_start_${i}`).value = month;
        document.getElementById(`dyn_year_start_${i}`).value = year;

        if(final_dyn_end_date){
            const d2 = new Date(final_dyn_end_date);
            let end_date = this.formatWithLeadingZero(d2.getDate());
            let end_month =  this.formatWithLeadingZero( d2.getMonth() + 1) ; // Since getUTCMonth() returns month from 0-11 not 1-12
            let end_year = d2.getFullYear();
            document.getElementById(`dyn_end_date_day_${i}`).value = end_date;
            document.getElementById(`dyn_end_month_${i}`).value = end_month;
            document.getElementById(`dyn_end_date_year_${i}`).value = end_year;
            document.getElementById(`dyn_still_work_${i}`).checked = false;
        }
        else{
            document.getElementById(`dyn_still_work_${i}`).checked = true;
        }



    }

    formatWithLeadingZero(value){
        if (value < 10) value = '0' + value;

        return value.toString()
    }

    getFormData() {
        // Construct a set of of key/value pairs representing form fields and their values.
        // https://developer.mozilla.org/en-US/docs/Web/API/FormData
        const form = new FormData(this.formTarget);
        let data = []
        // Loop through each key/value pair.
        // https://developer.mozilla.org/en-US/docs/Web/API/FormData/entries#example
        for (var pair of form.entries()) {
            // We don't want to save the authenticity_token to localStorage since that is generated by Rails.
            // https://guides.rubyonrails.org/security.html#cross-site-request-forgery-csrf
            if (pair[0] != "authenticity_token") {
                data.push([pair[0], pair[1]])
            }
        }
        // Return the key/value pairs as an Object. Each key is a field name, and each value is the field value.
        // https://developer.mozilla.org/en-us/docs/Web/JavaScript/Reference/Global_Objects/Object/fromEntries
        return Object.fromEntries(data)
    }

    saveToLocalStorage() {
        const data = this.getFormData();
        // Save the form data into localStorage. We need to convert the data Object into a String.
        localStorage.setItem(this.localStorageKey, JSON.stringify(data));
    }

    clearLocalStorage() {
        // See if there is data stored for this particular form.
        if (localStorage.getItem(this.localStorageKey) != null) {
            // Clear data from localStorage when the form is submitted.
            localStorage.removeItem(this.localStorageKey);
        }
    }

    setFormData() {
        // See if there is data stored for this particular form.
        if (localStorage.getItem(this.localStorageKey) != null) {
            // We need to convert the String of data back into an Object.
            const data = JSON.parse(localStorage.getItem(this.localStorageKey));
            // This allows us to have access to this.formTarget in the loop below.
            const form = this.formTarget;
            // Loop through each key/value pair and set the value on the corresponding form field.
            Object.entries(data).forEach((entry) => {
                let name = entry[0];
                let value = entry[1];
                let input = form.querySelector(`[name='${name}']`);
                input && (input.value = value);
            })
        }
    }

    setLocalStorage() {
        let temp_id = localStorage.getItem('temp_id')
        let current_temp = document.getElementById(`current_temp_${parseInt(temp_id)}`);

        if (!temp_id) {
            localStorage.setItem('temp_id', '0')
        } else {
if(current_temp){
            if ((current_temp === null && parseInt(temp_id) >= 0)) {
                localStorage.setItem('temp_id', '0')

            } else {
                localStorage.setItem('temp_id', parseInt(current_temp.value))
            }
        }
else {
    localStorage.setItem('temp_id', '0')
}
        }
    }

    resizeCanvas(canvas) {
        var ratio = Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);

    }

    salaryBracketsChange() {

        if (this.salary_bracketTarget.value === '') {

            this.salary_bracketTarget.classList.add("border_change");

        } else {
            this.salary_bracketTarget.classList.remove("border_change");
        }

    }

    validatePreviousUniformNo() {
        this.job_change_displayTarget.classList.add('hidden')
    }

    validatePreviousUniformYes() {
        this.job_change_displayTarget.classList.remove('hidden')
    }

    validateJobChangeNo() {
        this.next_displayTarget.classList.remove('hidden')
        this.error_containerTarget.classList.add('hidden')

    }

    validateJobChangeYes() {
        this.error_containerTarget.classList.remove('hidden')
        this.next_displayTarget.classList.add('hidden')
        this.showNotif('This is because you have indicated that you have previously made a claim for Uniform Allowance', 'error')
    }

    toggle() {
        if (this.still_working_hereTarget.checked === true) {
            this.end_date_displayTarget.classList.add('hidden')
            this.dyn_add_buttonTarget.classList.add('hidden')
        } else {
            this.end_date_displayTarget.classList.remove('hidden')
            this.dyn_add_buttonTarget.classList.remove('hidden')
        }
    }

    dynToggle() {


        let currentStep = parseInt(localStorage.getItem('current_index'))
        let still_work = document.getElementById(`dyn_still_work_${currentStep}`)
        let end_work_display = document.getElementById(`dyn_end_date_display_${currentStep}`)
        let final_work_display = document.getElementById(`final_dyn_still_work_${currentStep}`)


        if (still_work.checked === true) {
            end_work_display.classList.add('hidden')
            final_work_display.value = '1'
        } else {
            end_work_display.classList.remove('hidden')
            final_work_display.value = '0'
        }
    }

    industryChange() {

        const selectBox = this.job_titleTarget;
        selectBox.innerHTML = '';
        if (this.industryTarget.value > 1) {


            Rails.ajax({
                url: `/get_positions/${this.industryTarget.value}`,
                type: 'GET',
                dataType: "script",
                credentials: "include",
                error: function (jqXHR, textStatus, errorThrown) {
                    console.log("AJAX Error:", textStatus)
                    alert('There was an error.')
                },
                success: data => {
                    const blank = document.createElement('option');
                    blank.value = null;
                    blank.innerHTML = 'Select an title';
                    selectBox.appendChild(blank);
                    let positions = data['positions_by_industry'];
                    for (let i = 0; i < positions.length; i++) {
                        const opt = document.createElement('option');
                        opt.value = positions[i]["name"];
                        opt.innerHTML = positions[i]["name"];
                        selectBox.appendChild(opt);


                    }
                }
            })

            this.industriesViewSelector(this.industryTarget.value)


        }

    }


    dynIndustryChange() {

         let currentStep = parseInt(localStorage.getItem('current_index'))
        let dynIndustry = document.getElementById(`dyn_industry_${currentStep}`)


        let selectBox = document.getElementById(`dyn_job_title_${currentStep}`)


        selectBox.innerHTML = '';
        if (dynIndustry.value > 1) {


            Rails.ajax({
                url: `/get_positions/${dynIndustry.value}`,
                type: 'GET',
                dataType: "script",
                credentials: "include",
                error: function (jqXHR, textStatus, errorThrown) {
                    console.log("AJAX Error:", textStatus)
                    alert('There was an error.')
                },


                success: data => {
                    const blank = document.createElement('option');
                    blank.value = null;
                    blank.innerHTML = 'Select an title';
                    selectBox.appendChild(blank);
                    let positions = data['positions_by_industry'];
                    for (let i = 0; i < positions.length; i++) {
                        const opt = document.createElement('option');
                        opt.value = positions[i]["name"];
                        opt.innerHTML = positions[i]["name"];
                        selectBox.appendChild(opt);


                    }
                }

            })

            this.dynIndustriesViewSelector(dynIndustry.value,currentStep)


        }

    }

    checkEmpName() {
        if (this.employer_nameTarget.value.length > 1) {

            this.employer_name_crossTarget.classList.add('hidden')
        } else {
            this.employer_name_crossTarget.classList.remove('hidden')
        }


    }

    dynCheckEmpName() {
        let currentStep = parseInt(localStorage.getItem('current_index'))
        let dyn_emp_value = document.getElementById(`dyn_emp_name_${currentStep}`).value
        if (dyn_emp_value.length > 1) {
            document.getElementById(`dyn_employer_name_cross_${currentStep}`).classList.add('hidden')

        } else {
            document.getElementById(`dyn_employer_name_cross_${currentStep}`).classList.remove('hidden')

        }


    }

    positionsChange() {
        if (this.job_titleTarget.value.length >= 3) {
            const title = this.job_titleTarget.value;
            this.submit_job_titleTarget.value = title


        }
    }

    dynPositionsChange() {

        let currentStep = parseInt(localStorage.getItem('current_index'))

        let dyn_job_title_select = document.getElementById(`dyn_job_title_${currentStep}`)
        let temp_record = document.getElementById(`submit_dyn_job_title_${currentStep}`)


        if (dyn_job_title_select.value.length >= 3) {
            const title = dyn_job_title_select.value;
            temp_record.value = title
        }

    }


    checkPositionText() {

        if (this.job_title_textTarget.value.length >= 1) {
            this.job_title_text_crossTarget.classList.add('hidden')
        } else {
            this.job_title_text_crossTarget.classList.remove('hidden')
        }
        let valid_position = this.validateText(this.job_title_textTarget.value)
        this.job_title_textTarget.value = valid_position
        this.submit_job_titleTarget.value = valid_position

    }

    dynCheckPositionText() {


        let currentStep = parseInt(localStorage.getItem('current_index'))
        let dyn_job_title_text = document.getElementById(`dyn_job_text_title_${currentStep}`)
        let error = document.getElementById(`dyn_job_title_text_cross_${currentStep}`)
        let final_record = document.getElementById(`submit_dyn_job_title_${currentStep}`)


        if (dyn_job_title_text.value.length >= 1) {
            error.classList.add('hidden')
        } else {
            error.classList.remove('hidden')
        }
        let valid_position = this.validateText(dyn_job_title_text.value)
        dyn_job_title_text.value = valid_position
        final_record.value = valid_position

    }


    checkFirstName() {

        if (this.first_nameTarget.value.length > 0 && this.first_nameTarget.value.length < 3) {
            this.name_crossTarget.classList.remove("hidden");
            this.first_nameTarget.classList.add("border_change");
            this.first_nameTarget.focus()
        } else {
            this.name_crossTarget.classList.add("hidden");
            this.first_nameTarget.classList.remove("border_change");
        }


        let fName = this.formatName(this.first_nameTarget.value);
        this.first_nameTarget.value = fName
    }


    checkLastName() {

        if (this.last_nameTarget.value.length > 0 && this.last_nameTarget.value.length < 3) {
            this.lastname_crossTarget.classList.remove("hidden");
            this.last_nameTarget.classList.add("border_change");
            this.last_nameTarget.focus()
        } else {
            this.lastname_crossTarget.classList.add("hidden");
            this.last_nameTarget.classList.remove("border_change");
        }


        let lName = this.formatName(this.last_nameTarget.value);
        this.last_nameTarget.value = lName
    }

    checkEmail() {

        if (this.emailTarget.value.length >= 4 && this.validateEmail(this.emailTarget.value) === false) {
            this.email_crossTarget.classList.remove("hidden");
            this.emailTarget.classList.add("border_change");
            this.emailTarget.focus()
        } else {
            this.email_crossTarget.classList.add("hidden");
            this.emailTarget.classList.remove("border_change");
        }


    }

    checkFinalDob() {

        if (this.dob_finalTarget.value.length >= 4 && this.validateDate(this.dob_finalTarget.value) === false) {
            this.dob_crossTarget.classList.remove("hidden");
            this.dob_finalTarget.classList.add("border_change");
            this.dob_finalTarget.focus()
        } else {
            this.dob_crossTarget.classList.add("hidden");
            this.dob_finalTarget.classList.remove("border_change");
        }


    }


    checkAddress1() {

        if (this.address1Target.value.length > 3 && this.address1Target.value.length <= 5) {

            this.address1_crossTarget.classList.remove("hidden");
            this.address1Target.classList.add("border_change");
            this.address1Target.focus()
        } else {
            this.address1_crossTarget.classList.add("hidden");
            this.address1Target.classList.remove("border_change");
        }
        let valid_address1 = this.validateText(this.address1Target.value)
        this.address1Target.value = valid_address1

    }

    checkAddress2() {

        if (this.address2Target.value.length > 3 && this.address2Target.value.length <= 5) {

            this.address2_crossTarget.classList.remove("hidden");
            this.address2Target.classList.add("border_change");
            this.address2Target.focus()
        } else {
            this.address2_crossTarget.classList.add("hidden");
            this.address2Target.classList.remove("border_change");
        }
        let valid_address2 = this.validateText(this.address2Target.value)
        this.address2Target.value = valid_address2

    }

    checkCity() {

        if (this.cityTarget.value.length > 2 && this.cityTarget.value.length <= 3) {

            this.city_crossTarget.classList.remove("hidden");
            this.cityTarget.classList.add("border_change");
            this.cityTarget.focus()
        } else {
            this.city_crossTarget.classList.add("hidden");
            this.cityTarget.classList.remove("border_change");
        }
        let valid_city = this.formatName(this.cityTarget.value)
        this.cityTarget.value = valid_city

    }

    checkCounty() {

        if (this.countyTarget.value.length > 2 && this.countyTarget.value.length <= 3) {

            this.county_crossTarget.classList.remove("hidden");
            this.countyTarget.classList.add("border_change");
            this.countyTarget.focus()
        } else {
            this.county_crossTarget.classList.add("hidden");
            this.countyTarget.classList.remove("border_change");
        }
        let valid_county = this.formatName(this.countyTarget.value)
        this.countyTarget.value = valid_county

    }

    checkPostcode() {

        if (this.postcodeTarget.value.length > 3 && this.postcodeTarget.value.length < 5) {

            this.postcode_crossTarget.classList.remove("hidden");
            this.postcodeTarget.classList.add("border_change");
            this.postcodeTarget.focus()
        } else {
            this.postcode_crossTarget.classList.add("hidden");
            this.postcodeTarget.classList.remove("border_change");
        }
        let valid_postcode = this.isValidPostcode(this.postcodeTarget.value)
        if (valid_postcode === true) {
            let valid_postcode = this.replaceFormatPostCodeFormat(this.postcodeTarget.value)
            this.postcodeTarget.value = valid_postcode

        } else {
            this.postcode_crossTarget.classList.remove("hidden");
            this.postcodeTarget.classList.add("border_change");
            this.postcodeTarget.focus()
        }

        //this.postcodeTarget.value = valid_postcode

    }


    checkNiNumber() {

        if (this.ni_numberTarget.value.length === 9) {

            this.ni_numberTarget.value = this.ni_numberTarget.value.toUpperCase().replace(/ /g, '')
            if (isNino(this.ni_numberTarget.value)) {
                this.ni_number_crossTarget.classList.add('hidden');

            } else {

                // there is a mismatch, hence show the error message
                this.ni_number_crossTarget.classList.remove('hidden');
                this.ni_numberTarget.value = '';
            }

        }

    }

    submitNextBtn() {
        let position_and_job_title = this.check_position_and_job_title()
        let main_start_date = this.validate_main_start_date_submit()
        let main_end_date = this.validate_main_end_date_submit()
        let NxtBtnChecker = this.checkNextBtn(position_and_job_title, main_start_date, main_end_date)
        this.clearLocalStorage()
        if (NxtBtnChecker === true && this.checkDynBtnNoDate(parseInt(localStorage.getItem('temp_id'))) === true) {

            document.querySelector('form').submit();


        }
        else {
            if ( this.error_containerTarget.classList.contains('hidden') === true){
                this.error_containerTarget.classList.remove('hidden')
                this.showNotif('Please fill all fields', 'error')
            }
        }
    }

    existingSubmitNextBtn() {
        this.error_containerTarget.classList.add('hidden')
        this.clearLocalStorage()

        if (this.checkDynBtnWithZero(parseInt(localStorage.getItem('temp_id'))) === true) {
            document.querySelector('form').submit();
        }
        else {
            if ( this.error_containerTarget.classList.contains('hidden') === true){
                this.error_containerTarget.classList.remove('hidden')
                this.showNotif('Please fill all fields', 'error')
            }
        }
    }


    checkNextBtn(position_and_job_title, main_start_date, main_end_date) {
        event.preventDefault();


        if (!this.salary_bracketTarget.value) {
            this.error_containerTarget.classList.remove('hidden')
            this.showNotif('Please ensure salary bracket selected', 'error')

        } else if (!this.industryTarget.value) {
            this.error_containerTarget.classList.remove('hidden')
            this.showNotif('Please ensure Industry  selected', 'error')

        } else if (position_and_job_title === false) {
            this.error_containerTarget.classList.remove('hidden')
            this.showNotif('Please ensure u got a job title', 'error')

        } else if (!this.employer_nameTarget.value) {
            this.error_containerTarget.classList.remove('hidden')
            this.showNotif('Please ensure employer name entered correctly', 'error')

        } else if (main_start_date === false) {
            this.error_containerTarget.classList.remove('hidden')
            this.showNotif('Please ensure you selected all 3 option for start date', 'error')

        } else if (main_end_date === false) {
            this.error_containerTarget.classList.remove('hidden')
            this.showNotif('Please ensure you selected all 3 option for the end date', 'error')

        } else {
            this.error_containerTarget.classList.add('hidden')
            return true
        }


    }

    checkDynBtnNoDate(currentStep) {


        if (currentStep >0){

        if (currentStep === 1) {
            let dynSWH = document.getElementById(`final_dyn_still_work_1`)
            dynSWH.value = 1
            let checkOne = this.checkAllDynYear(1)

            if (checkOne === true  ) {
                return true
        }
        }
        else if (currentStep === 2) {
            let dynSWH = document.getElementById(`final_dyn_still_work_1`)
            dynSWH.value = 0
            let dynSWH2 = document.getElementById(`final_dyn_still_work_2`)
            dynSWH2.value = 1

            let checkOne = this.checkAllDynYear(1)
            let checkTwo = this.checkAllDynYear(2)

            if (checkOne === true && checkTwo === true) {
                return true

            } else {
                return false
            }


        }
          else  if (currentStep === 3) {
                let dynSWH = document.getElementById(`final_dyn_still_work_1`)
                dynSWH.value = 0
                let dynSWH2 = document.getElementById(`final_dyn_still_work_2`)
                dynSWH2.value = 0
                let dynSWH3 = document.getElementById(`final_dyn_still_work_3`)
                dynSWH3.value = 1

                this.dyn_add_buttonTarget.classList.add('hidden')
                let checkOne = this.checkAllDynYear(1)
                let checkTwo = this.checkAllDynYear(2)
                let checkthree = this.checkAllDynYear(3)

                if (checkOne === true && checkTwo === true && checkthree === true) {
                    return true
                } else {
                    return false
                }

            } else {
            return false
        }
        }


        else {return true}
    }



    checkDynBtnWithZero(currentStep) {


        if (currentStep === 0){
            let dynSWH = document.getElementById(`final_dyn_still_work_0`)
            dynSWH.value = 1
            let checkZero = this.checkAllDynYear(0)

            if (checkZero === true  ) {
                return true
            }
        }
      else if (currentStep === 1) {
            let dynSWH0 = document.getElementById(`final_dyn_still_work_0`)
            dynSWH0.value = 0
            let dynSWH = document.getElementById(`final_dyn_still_work_1`)
            dynSWH.value = 1

            let checkZero = this.checkAllDynYear(0)
            let checkOne = this.checkAllDynYear(1)

            if (checkOne === true  && checkZero === true) {
                return true
        }
        }
       else if (currentStep === 2) {

            let dynSWH0 = document.getElementById(`final_dyn_still_work_0`)
            dynSWH0.value = 0
            let dynSWH = document.getElementById(`final_dyn_still_work_1`)
            dynSWH.value = 0
            let dynSWH2 = document.getElementById(`final_dyn_still_work_2`)
            dynSWH2.value = 1

            let checkZero = this.checkAllDynYear(0)
            let checkOne = this.checkAllDynYear(1)
            let checkTwo = this.checkAllDynYear(2)

            if (checkZero === true && checkOne === true && checkTwo === true) {
                return true

            } else {
                console.log('send false ')
                return false
            }
        }
         else if (currentStep === 3) {
            let dynSWH0 = document.getElementById(`final_dyn_still_work_0`)
            dynSWH0.value = 0
                let dynSWH = document.getElementById(`final_dyn_still_work_1`)
                dynSWH.value = 0
                let dynSWH2 = document.getElementById(`final_dyn_still_work_2`)
                dynSWH2.value = 0
                let dynSWH3 = document.getElementById(`final_dyn_still_work_3`)
                dynSWH3.value = 1

                this.dyn_add_buttonTarget.classList.add('hidden')
            let checkZero = this.checkAllDynYear(0)
                let checkOne = this.checkAllDynYear(1)
                let checkTwo = this.checkAllDynYear(2)
                let checkthree = this.checkAllDynYear(3)

                if (checkZero === true && checkOne === true && checkTwo === true && checkthree === true) {

                    return true



            }
        }


        else {return false}
    }


checkDynBtn(currentStep) {
        event.preventDefault();

        if (currentStep >0){

        if (currentStep === 1) {
            let dynSWH = document.getElementById(`final_dyn_still_work_1`)
            let checkOne = this.checkAllDynYear(1)
            if (checkOne === true  ) {


                dynSWH.value = 1

                if(  this. required_dyn_end_date_submit(1) === true){
                return true

            } else {
                return false
            }


        }
        }
       else if (currentStep === 2) {

            let checkOne = this.checkAllDynYear(1)
            let checkTwo = this.checkAllDynYear(2)

            if (checkOne === true && checkTwo === true) {
                let dynSWH1 = document.getElementById(`final_dyn_still_work_1`)
                dynSWH1.value = 0
              if(this.required_dyn_end_date_submit(2) === true)   {
                let dynSWH2 = document.getElementById(`final_dyn_still_work_2`)
                dynSWH2.value = 1
                return true

            } else {
                return false
            }


        }
        }
       else if (currentStep === 3) {
            this.dyn_add_buttonTarget.classList.add('hidden')
            let checkOne = this.checkAllDynYear(1)
            let checkTwo = this.checkAllDynYear(2)
            let checkthree = this.checkAllDynYear(3)

            if (checkOne === true && checkTwo === true && checkthree === true) {
                let dynSWH1 = document.getElementById(`final_dyn_still_work_1`)
                dynSWH1.value = 0
             if( this.required_dyn_end_date_submit(1) === true)  {
                 let dynSWH2 = document.getElementById(`final_dyn_still_work_2`)
                 dynSWH2.value = 0
                 if(this.required_dyn_end_date_submit(2) === true){
                     let dynSWH3 = document.getElementById(`final_dyn_still_work_3`)
                     dynSWH3.value = 1
                     return true
                 }
             }



            } else {
                return false
            }

        } else {
            return false
        }
        }
        else {return true}
    }

    required_dyn_end_date_submit(currentStep) {


        let classChecker = document.getElementById(`dyn_end_date_display_${currentStep}`)


        if (classChecker.classList.contains('hidden') === true) {
            classChecker.classList.remove('hidden')
            this.dyn_add_buttonTarget.classList.add('hidden')
            return false
        }
        else if(classChecker.classList.contains('hidden') === false) {
            return true

        }  else {
            return false


        }
    }

    checkAllDynYear(i) {
        let position_and_job_title = this.check_dyn_position_and_job_title(i)
        let dyn_end_date = this.validate_dyn_end_date_submit(i)
        let dyn_start_date = this.validate_dyn_start_date_submit(i)


        let dynIndustry = document.getElementById(`dyn_industry_${i}`)

        let dyn_name = document.getElementById(`dyn_emp_name_${i}`).value
        let selectedIndustryValue = dynIndustry.options[dynIndustry.selectedIndex].value;

        if (selectedIndustryValue === "Select Industry" || selectedIndustryValue === '') {
            this.error_containerTarget.classList.remove('hidden')
            this.showNotif('Please ensure Industry  selected', 'error')
            return false
        } else if (position_and_job_title === false) {
            this.error_containerTarget.classList.remove('hidden')
            this.showNotif('Please check job title', 'error')
            return false
        } else if (dyn_name.length <= 1) {
            this.error_containerTarget.classList.remove('hidden')
            this.showNotif('Please enter a valid name', 'error')
            return false
        } else if (dyn_start_date === false) {
            this.error_containerTarget.classList.remove('hidden')
            this.showNotif('Please select all 3 options for the start date', 'error')
            return false
        } else if (dyn_end_date === false) {
            this.error_containerTarget.classList.remove('hidden')
            this.showNotif('Please select all 3 options for end date', 'error')
            return false
        } else {
            this.error_containerTarget.classList.add('hidden')
            return true
        }

    }


    validate_main_start_date_submit() {

        if (!this.main_start_date_dayTarget.value || this.main_start_date_dayTarget.value === 'Select...') {
            return false
        } else if (!this.main_start_date_monthTarget.value || this.main_start_date_monthTarget.value === 'Select...') {
            return false;
        } else if (!this.main_start_date_yearTarget.value || this.main_start_date_yearTarget.value === 'Select...') {
            return false
        } else {
            return true
        }
    }

    validate_dyn_start_date_submit(currentStep) {

        let start_date_day = document.getElementById(`dyn_day_start_${currentStep}`).value
        let start_date_month = document.getElementById(`dyn_month_start_${currentStep}`).value
        let start_date_year = document.getElementById(`dyn_year_start_${currentStep}`).value


        if (!start_date_day || start_date_day === 'Select...') {
            return false
        } else if (!start_date_month || start_date_month === 'Select...') {
            return false;
        } else if (!start_date_year || start_date_year === 'Select...') {
            return false
        } else {
            return true
        }
    }



    validate_dyn_end_date_submit(currentStep) {

        let end_date_day = document.getElementById(`dyn_end_date_day_${currentStep}`)
        let end_date_month = document.getElementById(`dyn_end_month_${currentStep}`)
        let end_date_year = document.getElementById(`dyn_end_date_year_${currentStep}`)
        let classChecker = document.getElementById(`dyn_end_date_display_${currentStep}`)


        if (classChecker.classList.contains('hidden') === false) {
            if (!end_date_day.value || end_date_day.value === 'Select...') {
                return false
            } else if (!end_date_month.value || end_date_month.value === 'Select...') {
                return false;
            } else if (!end_date_year.value || end_date_year.value === 'Select...') {
                return false
            } else {
                return true
            }
        } else {

            return true
        }
    }

    validate_main_end_date_submit() {
        let classChecker = this.end_date_displayTarget.classList.contains('hidden')

        if (classChecker === false) {
            if (!this.main_end_date_dayTarget.value || this.main_end_date_dayTarget.value === 'Select...') {
                return false
            } else if (!this.main_end_date_monthTarget.value || this.main_end_date_monthTarget.value === 'Select...') {
                return false;
            } else if (!this.main_end_date_yearTarget.value || this.main_end_date_yearTarget.value === 'Select...') {
                return false
            } else {
                return true
            }
        } else {
            return true
        }
    }

    check_position_and_job_title() {
        let textClassCheck = this.job_title_text_displayTarget.classList.contains('hidden')
        let selectClassCheck = this.job_title_select_displayTarget.classList.contains('hidden')

        if (!this.industryTarget.value || this.industryTarget.innerHTML === 'Select an industry') {


            return false
        } else {
            if (textClassCheck === true && selectClassCheck === false) {

                if (this.submit_job_titleTarget.value === '' || this.submit_job_titleTarget.value === null|| this.job_titleTarget.value.length === 0) {

                    return false
                }
            else{
                    return true
                }
            } else if (textClassCheck === false && selectClassCheck === true) {
                if (!this.job_title_textTarget.value) {

                    return false
                }
            } else if (textClassCheck === true && selectClassCheck === true) {


                return false

            } else {

                return true
            }
        }


    }

    check_dyn_position_and_job_title(currentStep) {


        let textClassCheck = document.getElementById(`dyn_job_title_text_display_${currentStep}`).classList.contains('hidden')
        let selectClassCheck = document.getElementById(`dyn_job_title_select_display_${currentStep}`).classList.contains('hidden')

        if (textClassCheck === true && selectClassCheck === false) {
            let dynPosition = document.getElementById(`dyn_job_title_${currentStep}`)
            let selectedPositionValue = dynPosition.options[dynPosition.selectedIndex].value;

            if (selectedPositionValue === '' || selectedPositionValue === 'null' || selectedPositionValue === 'Select an title') {

                return false
            } else {
                return true
            }
        } else if (textClassCheck === false && selectClassCheck === true) {
            if (!document.getElementById(`dyn_job_text_title_${currentStep}`).value) {

                return false
            } else {
                return true
            }
        } else if (textClassCheck === true && selectClassCheck === true) {


            return false

        } else {

            return true
        }


    }


    finalTitleSelect() {
        if (this.job_title_select_displayTarget.classList.contains('hidden') && this.job_title_text_crossTarget.classList.contains('hidden')) {
            return true
        } else {
            return false
        }
    }

    finalTitleText() {
        if (this.job_title_text_displayTarget.classList.contains('hidden') && this.job_titleTarget.value != null) {
            return true
        } else {
            return false
        }
    }

    allChecked() {
        let allChecked = true
        document.querySelectorAll('.checkmark-input').forEach(function (item, index) {
            if (!item.checked)
                allChecked = false;
        });
        if (!allChecked) {

            return false;
        } else {
            // signature_display
            // this.error_containerTarget.classList.remove('show');
            // this.error_containerTarget.classList.add('hide');
            this.signature_displayTarget.classList.remove('hidden');
        }
    }


    checkTerms() {
        if (this.termsTarget.checked && this.claim_authorisationTarget.checked && this.data_permissionTarget.checked) {
            this.error_containerTarget.classList.remove('show');
            this.error_containerTarget.classList.add('hide');
            return true;
        } else {

            return false;
        }
    }

    submit_single(event) {
        if (this.checkTerms() === false) {
            event.preventDefault();

        } else if (this.signature_pad.isEmpty()) {
            event.preventDefault();

        } else {
            event.preventDefault();
            document.getElementById('signature').value = this.signature_pad.toDataURL()

            if (this.submitUniform() === true) {

                document.querySelector('form').submit();
            }

        }
    }

    clear() {
        this.signature_pad.clear();
        document.getElementById('signature').value = null;

    }


    formatName(string) {
        const noSpecialCharacters = string.replace(/[^a-zA-Z ]/g, '');
        let c_name = noSpecialCharacters.charAt(0).toUpperCase() + noSpecialCharacters.slice(1);
        return c_name
    }

    industriesViewSelector(id) {
        const array = [36, 37, 38, 39]

        if (array.includes(parseInt(id))) {

            this.job_title_select_displayTarget.classList.add("hidden");
            this.job_title_text_displayTarget.classList.remove("hidden");

        } else {

            this.job_title_text_displayTarget.classList.add("hidden");
            this.job_title_select_displayTarget.classList.remove("hidden");

        }
    }

    dynIndustriesViewSelector(id,currentStep) {
        const array = [36, 37, 38, 39]
        let selectClassCheck = document.getElementById(`dyn_job_title_select_display_${currentStep}`)
        let textClassCheck = document.getElementById(`dyn_job_title_text_display_${currentStep}`)


        if (array.includes(parseInt(id))) {

            selectClassCheck.classList.add("hidden");
            textClassCheck.classList.remove("hidden");

        } else {

            textClassCheck.classList.add("hidden");
            selectClassCheck.classList.remove("hidden");

        }
    }

    validateEmail(email_before) {
        let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (email_before.match(regexEmail)) {
            return true;
        } else {
            return false;
        }
    }


    validateDate(date) {
        let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (date.match(regexEmail)) {
            return true;
        } else {
            return false;
        }
    }

    validateText(text) {
        const noSpecialCharacters = text.replace(/[^a-zA-Z0-9 ]/g, '');
        let c_text = noSpecialCharacters.charAt(0).toUpperCase() + noSpecialCharacters.slice(1);
        return c_text
    }

    isValidPostcode(p) {
        const postcodeRegEx = /[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}/i;
        let validated_postcode = postcodeRegEx.test(p);
        return validated_postcode
    }

    replaceFormatPostCodeFormat(postcode) {
        const postcodeRegEx = /(^[A-Z]{1,2}[0-9]{1,2})([0-9][A-Z]{2}$)/i;
        let correct_format = postcode.replace(postcodeRegEx, "$1 $2");
        return correct_format

    }

    showNotif(msg, type) {

        this.error_textTarget.innerHTML = msg
        if (type === 'success') {
            this.error_containerTarget.classList.remove('bg-red-50')
            this.iconTarget.classList.remove('text-red-400')
            this.error_titleTarget.classList.remove('text-red-800')
            this.error_text_containerTarget.classList.remove('text-red-700')

            this.error_containerTarget.classList.add('bg-green-50')
            this.iconTarget.classList.add('text-green-400')
            this.error_titleTarget.classList.add('text-green-800')
            this.error_text_containerTarget.classList.add('text-green-700')


        } else {
            this.error_containerTarget.classList.add('bg-red-50')
            this.iconTarget.classList.add('text-red-400')
            this.error_titleTarget.classList.add('text-red-800')
            this.error_text_containerTarget.classList.add('text-red-700')

            this.error_containerTarget.classList.remove('bg-green-50')
            this.iconTarget.classList.remove('text-green-400')
            this.error_titleTarget.classList.remove('text-green-800')
            this.error_text_containerTarget.classList.remove('text-green-700')
        }
        this.error_containerTarget.classList.remove('hide');
        this.error_containerTarget.classList.add('show');

    }

    add_employment(event) {

        event.preventDefault()

        let position_and_job_title = this.check_position_and_job_title()
        let main_start_date = this.validate_main_start_date_submit()
        let main_end_date = this.validate_main_end_date_submit()
        let NxtBtnChecker = this.checkNextBtn(position_and_job_title, main_start_date, main_end_date)


        if (this.end_date_displayTarget.classList.contains('hidden')) {
            this.error_containerTarget.classList.remove('hidden')

            this.showNotif(`This is because you have indicated still work for ${this.employer_nameTarget.value}`, 'error')

        } else {

            if (parseInt(localStorage.getItem('temp_id')) <= 2 && NxtBtnChecker === true && this.checkDynBtn(parseInt(localStorage.getItem('temp_id'))) === true) {
                localStorage.setItem('temp_id', parseInt(localStorage.getItem('temp_id')) + 1)


                this.error_containerTarget.classList.add('hidden')
                var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, parseInt(localStorage.getItem('temp_id')))
                this.add_itemTarget.insertAdjacentHTML('beforebegin', content)


            }

        }
    }

    add_employment_existing(event) {

        event.preventDefault()
            if (parseInt(localStorage.getItem('temp_id')) <= 2  && this.checkDynBtn(parseInt(localStorage.getItem('temp_id'))) === true) {
                localStorage.setItem('temp_id', parseInt(localStorage.getItem('temp_id')) + 1)


                this.error_containerTarget.classList.add('hidden')
                var content = this.templateTarget.innerHTML.replace( parseInt(localStorage.getItem('temp_id')))
                this.add_itemTarget.insertAdjacentHTML('beforebegin', content)


            }

    }

    remove_employment(event) {

        let aValue = localStorage.getItem('temp_id')

        event.preventDefault()
        let item = event.target.closest(".nested-fields")
        let inputs = item.getElementsByTagName("input")
        for (var i = 0; i < inputs.length; i++) {
            if (inputs[i].type == 'number') {
                inputs[i].value = ''
            }
        }
        if (parseInt(aValue) === 1) {

        }
        localStorage.setItem('temp_id', parseInt(aValue) - 1)
        item.style.display = 'none'
    }


    dyn_validate_start_date() {

        let currentStep = parseInt(localStorage.getItem('current_index'))
        let start_date_day = document.getElementById(`dyn_day_start_${currentStep}`).value
        let start_date_month = document.getElementById(`dyn_month_start_${currentStep}`).value
        let start_date_year = document.getElementById(`dyn_year_start_${currentStep}`).value
        let final_dyn_start_date = document.getElementById(`final_dyn_start_date_${currentStep}`)


        if (start_date_month === '04') {
            document.getElementById(`date29_${currentStep}`).classList.remove("hidden");
            document.getElementById(`date30_${currentStep}`).classList.remove("hidden");
            document.getElementById(`date31_${currentStep}`).classList.add("hidden");
        } else if (start_date_month === '06') {
            document.getElementById(`date29_${currentStep}`).classList.remove("hidden");
            document.getElementById(`date30_${currentStep}`).classList.remove("hidden");
            document.getElementById(`date31_${currentStep}`).classList.add("hidden");
        } else if (start_date_month === '09') {
            document.getElementById(`date29_${currentStep}`).classList.remove("hidden");
            document.getElementById(`date30_${currentStep}`).classList.remove("hidden");
            document.getElementById(`date31_${currentStep}`).classList.add("hidden");
        } else if (start_date_month === '11') {
            document.getElementById(`date29_${currentStep}`).classList.remove("hidden");
            document.getElementById(`date30_${currentStep}`).classList.remove("hidden");
            document.getElementById(`date31_${currentStep}`).classList.add("hidden");
        } else {
            document.getElementById(`date29_${currentStep}`).classList.remove("hidden");
            document.getElementById(`date30_${currentStep}`).classList.remove("hidden");
            document.getElementById(`date31_${currentStep}`).classList.remove("hidden");
        }

        if (start_date_year) {
            let leapYear = start_date_year % 4 === 0

            if (!leapYear && start_date_month === '02') {

                document.getElementById(`date29_${currentStep}`).classList.add("hidden");
                document.getElementById(`date30_${currentStep}`).classList.add("hidden");
                document.getElementById(`date31_${currentStep}`).classList.add("hidden");

            } else if (leapYear && start_date_month === '02') {

                document.getElementById(`date29_${currentStep}`).classList.remove("hidden");
                document.getElementById(`date30_${currentStep}`).classList.add("hidden");
                document.getElementById(`date31_${currentStep}`).classList.add("hidden");


            }
        }

        if (start_date_day && start_date_month && start_date_year) {
            const myDateStart = new Date(`${start_date_day}-${start_date_month}-${start_date_year}`);
            final_dyn_start_date.value = myDateStart
            if ( final_dyn_start_date.value === 'Invalid Date')
            {
                document.getElementById(`dyn_day_start_${currentStep}`).selectedIndex = 0;
                document.getElementById(`dyn_month_start_${currentStep}`).selectedIndex = 0;
                document.getElementById(`dyn_year_start_${currentStep}`).selectedIndex = 0;
                final_dyn_start_date.value = null
            }

        }
    }


    dyn_validate_end_date() {

        let currentStep = parseInt(localStorage.getItem('current_index'))
        let end_date_day = document.getElementById(`dyn_end_date_day_${currentStep}`).value
        let end_date_month = document.getElementById(`dyn_end_month_${currentStep}`).value
        let end_date_year = document.getElementById(`dyn_end_date_year_${currentStep}`).value
        let final_dyn_end_date = document.getElementById(`dyn_final_end_date_${currentStep}`)

        if (end_date_month === '04') {
            document.getElementById(`end_date29_${currentStep}`).classList.remove("hidden");
            document.getElementById(`end_date30_${currentStep}`).classList.remove("hidden");
            document.getElementById(`end_date31_${currentStep}`).classList.add("hidden");
        } else if (end_date_month === '06') {
            document.getElementById(`end_date29_${currentStep}`).classList.remove("hidden");
            document.getElementById(`end_date30_${currentStep}`).classList.remove("hidden");
            document.getElementById(`end_date31_${currentStep}`).classList.add("hidden");
        } else if (end_date_month === '09') {
            document.getElementById(`end_date29_${currentStep}`).classList.remove("hidden");
            document.getElementById(`end_date30_${currentStep}`).classList.remove("hidden");
            document.getElementById(`end_date31_${currentStep}`).classList.add("hidden");
        } else if (end_date_month === '11') {
            document.getElementById(`end_date29_${currentStep}`).classList.remove("hidden");
            document.getElementById(`end_date30_${currentStep}`).classList.remove("hidden");
            document.getElementById(`end_date31_${currentStep}`).classList.add("hidden");
        } else {
            document.getElementById(`end_date29_${currentStep}`).classList.remove("hidden");
            document.getElementById(`end_date30_${currentStep}`).classList.remove("hidden");
            document.getElementById(`end_date31_${currentStep}`).classList.remove("hidden");
        }

        if (end_date_year) {
            let leapYear = end_date_year % 4 === 0

            if (!leapYear && end_date_month === '02') {

                document.getElementById(`end_date29_${currentStep}`).classList.add("hidden");
                document.getElementById(`end_date30_${currentStep}`).classList.add("hidden");
                document.getElementById(`end_date31_${currentStep}`).classList.add("hidden");

            } else if (leapYear && end_date_month === '02') {

                document.getElementById(`end_date29_${currentStep}`).classList.remove("hidden");
                document.getElementById(`end_date30_${currentStep}`).classList.add("hidden");
                document.getElementById(`end_date31_${currentStep}`).classList.add("hidden");


            }
        }

        if (end_date_day && end_date_month && end_date_year) {

            const myDateEnd = new Date(`${end_date_day}-${end_date_month}-${end_date_year}`);
            console.log(end_date_day +"  "+ end_date_month +"  "+ end_date_year)
            final_dyn_end_date.value = myDateEnd
            if ( final_dyn_end_date.value === 'Invalid Date')
            {
                document.getElementById(`dyn_end_date_day_${currentStep}`).selectedIndex = 0;
                document.getElementById(`dyn_end_month_${currentStep}`).selectedIndex = 0;
                document.getElementById(`dyn_end_date_year_${currentStep}`).selectedIndex = 0;
                final_dyn_end_date.value = null
            }else{
                this.dyn_add_buttonTarget.classList.remove('hidden')
            }


        }
    }


    validate_dob_start_date() {

//'dob_date_day','dob_date29','dob_date30','dob_date31','dob_date_month','dob_date_year','dob_full_date'

        if (this.dob_date_monthTarget.value === '04') {
            this.dob_date29Target.classList.remove("hidden");
            this.dob_date30Target.classList.remove("hidden");
            this.dob_date31Target.classList.add("hidden");
        } else if (this.dob_date_monthTarget.value === '06') {
            this.dob_date29Target.classList.remove("hidden");
            this.dob_date30Target.classList.remove("hidden");
            this.dob_date30Target.classList.add("hidden");
        } else if (this.dob_date_monthTarget.value === '09') {
            this.dob_date29Target.classList.remove("hidden");
            this.dob_date30Target.classList.remove("hidden");
            this.dob_date30Target.classList.add("hidden");
        } else if (this.dob_date_monthTarget.value === '11') {
            this.dob_date29Target.classList.remove("hidden");
            this.dob_date30Target.classList.remove("hidden");
            this.dob_date31Target.classList.add("hidden");
        } else {
            this.dob_date29Target.classList.remove("hidden");
            this.dob_date30Target.classList.remove("hidden");
            this.dob_date31Target.classList.remove("hidden");
        }

        if (this.dob_date_yearTarget.value) {
            let leapYear = this.dob_date_yearTarget.value % 4 === 0

            if (!leapYear && this.dob_date_monthTarget.value === '02') {
                this.dob_date29Target.classList.add("hidden");
                this.dob_date30Target.classList.add("hidden");
                this.dob_date31Target.classList.add("hidden");
            } else if (leapYear && this.dob_date_monthTarget.value === '02') {
                this.dob_date29Target.classList.remove("hidden");
                this.dob_date30Target.classList.add("hidden");
                this.dob_date31Target.classList.add("hidden");
            }
        }

        if (this.dob_date_dayTarget.value && this.dob_date_monthTarget.value && this.dob_date_yearTarget.value) {
            const myDate = new Date(`${this.dob_date_yearTarget.value}-${this.dob_date_monthTarget.value}-${this.dob_date_dayTarget.value}`);
            this.dob_full_dateTarget.value = myDate
            if ( this.dob_full_dateTarget.value === 'Invalid Date')
            {
                this.dob_date_dayTarget.selectedIndex = 0;
                this.dob_date_monthTarget.selectedIndex = 0;
                this.dob_date_yearTarget.selectedIndex = 0;
                this.dob_full_dateTarget.value = null
            }
        }
    }

    validate_main_start_date() {

        if (this.main_start_date_monthTarget.value === '04') {
            this.main_date29Target.classList.remove("hidden");
            this.main_date30Target.classList.remove("hidden");
            this.main_date31Target.classList.add("hidden");
        } else if (this.main_start_date_monthTarget.value === '06') {
            this.main_date29Target.classList.remove("hidden");
            this.main_date30Target.classList.remove("hidden");
            this.main_date31Target.classList.add("hidden");
        } else if (this.main_start_date_monthTarget.value === '09') {
            this.main_date29Target.classList.remove("hidden");
            this.main_date30Target.classList.remove("hidden");
            this.main_date31Target.classList.add("hidden");
        } else if (this.main_start_date_monthTarget.value === '11') {
            this.main_date29Target.classList.remove("hidden");
            this.main_date30Target.classList.remove("hidden");
            this.main_date31Target.classList.add("hidden");
        } else {
            this.main_date29Target.classList.remove("hidden");
            this.main_date30Target.classList.remove("hidden");
            this.main_date31Target.classList.remove("hidden");
        }

        if (this.main_start_date_yearTarget.value) {
            let leapYear = this.main_start_date_yearTarget.value % 4 === 0

            if (!leapYear && this.main_start_date_monthTarget.value === '02') {
                this.main_date29Target.classList.add("hidden");
                this.main_date30Target.classList.add("hidden");
                this.main_date31Target.classList.add("hidden");
            } else if (leapYear && this.main_start_date_monthTarget.value === '02') {
                this.main_date29Target.classList.remove("hidden");
                this.main_date30Target.classList.add("hidden");
                this.main_date31Target.classList.add("hidden");
            }
        }

        if (this.main_start_date_dayTarget.value && this.main_start_date_monthTarget.value && this.main_start_date_yearTarget.value) {
            const myDate = new Date(`${this.main_start_date_yearTarget.value}-${this.main_start_date_monthTarget.value}-${this.main_start_date_dayTarget.value}`);
            this.main_final_start_dateTarget.value = myDate

            if ( this.main_final_start_dateTarget.value === 'Invalid Date')
            {
                this.main_start_date_dayTarget.selectedIndex = 0;
                this.main_start_date_monthTarget.selectedIndex = 0;
                this.main_start_date_yearTarget.selectedIndex = 0;
                this.main_final_start_dateTarget.value = null
            }
        }
    }

    validate_main_end_date() {


        if (this.main_end_date_monthTarget.value === '04') {
            this.main_end_date29Target.classList.remove("hidden");
            this.main_end_date30Target.classList.remove("hidden");
            this.main_end_date31Target.classList.add("hidden");
        } else if (this.main_end_date_monthTarget.value === '06') {
            this.main_end_date29Target.classList.remove("hidden");
            this.main_end_date30Target.classList.remove("hidden");
            this.main_end_date31Target.classList.add("hidden");
        } else if (this.main_end_date_monthTarget.value === '09') {
            this.main_end_date29Target.classList.remove("hidden");
            this.main_end_date30Target.classList.remove("hidden");
            this.main_end_date31Target.classList.add("hidden");
        } else if (this.main_end_date_monthTarget.value === '11') {
            this.main_end_date29Target.classList.remove("hidden");
            this.main_end_date30Target.classList.remove("hidden");
            this.main_end_date31Target.classList.add("hidden");
        } else {
            this.main_end_date29Target.classList.remove("hidden");
            this.main_end_date30Target.classList.remove("hidden");
            this.main_end_date31Target.classList.remove("hidden");
        }

        if (this.main_end_date_yearTarget.value) {
            let leapYear = this.main_end_date_yearTarget.value % 4 === 0

            if (!leapYear && this.main_end_date_monthTarget.value === '02') {
                this.main_end_date29Target.classList.add("hidden");
                this.main_end_date30Target.classList.add("hidden");
                this.main_end_date31Target.classList.add("hidden");
            } else if (leapYear && this.main_end_date_monthTarget.value === '02') {
                this.main_end_date29Target.classList.remove("hidden");
                this.main_end_date30Target.classList.add("hidden");
                this.main_end_date31Target.classList.add("hidden");
            }
        }

        if (this.main_end_date_dayTarget.value && this.main_end_date_monthTarget.value && this.main_end_date_yearTarget.value) {
            const myDate = new Date(`${this.main_end_date_yearTarget.value}-${this.main_end_date_monthTarget.value}-${this.main_end_date_dayTarget.value}`);
            this.main_final_end_dateTarget.value = myDate
            if ( this.main_final_end_dateTarget.value === 'Invalid Date')
            {
                this.main_end_date_dayTarget.selectedIndex = 0;
                this.main_end_date_monthTarget.selectedIndex = 0;
                this.main_end_date_yearTarget.selectedIndex = 0;
                this.main_final_end_dateTarget.value = null
            }
        }
    }


    submitUniform() {
        if (this.first_nameTarget.value.trim() === "" || this.last_nameTarget.value.trim() === "" || this.ni_numberTarget.value.trim() === "" || this.dob_full_dateTarget.value.trim() === "" || this.address1Target.value.trim() === "" || this.address2Target.value.trim() === "" || this.cityTarget.value.trim() === "" || this.countyTarget.value.trim() === "" || this.postcodeTarget.value.trim() === "" || this.emailTarget.value.trim() === "" || this.validateEmail(this.emailTarget.value) === false) {

            this.error_containerTarget.classList.remove('hidden')
            this.showNotif('Please ensure all fields are filled', 'error')
            return false


        } else {
            this.error_containerTarget.classList.add('hidden')
            return true
        }
    }


}

