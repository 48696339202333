import { Controller } from 'stimulus';
import SignaturePad from "signature_pad";
import { PostcodeLookup } from "@ideal-postcodes/postcode-lookup";

let data = new FormData();
export default class extends Controller {
	static targets = ['claim', 'step1Modal', 'step2Modal', 'step3Modal', 'step4Modal', 'step5Modal', 
	'step6Modal', 'step7Modal', 'step8Modal','submitCompensationBtn', 'selectYearWFH', 'submitStep2', 
	'EmployerName', 'Industry', 'submitStep3', 'submitStep4', 'submitStep5', 'submitStep6', 'pyeeStart', 'pyeeEnd',
	'signatureCheck', 'signatureError'];
	claimProcess() {
		const canvas = document.querySelector('canvas');
		if (canvas){
			this.signature_pad = new SignaturePad(canvas, {drawOnly:true});
		}
		this.claimTarget.style.display ='none';
		this.step1ModalTarget.style.display = 'block';
		document.getElementById('step1Form').scrollIntoView({
			behavior: "smooth",
			block: 'center'
		});
	}
	

	enableNextWFH(){
		this.submitCompensationBtnTarget.removeAttribute('disabled');
		this.submitCompensationBtnTarget.classList.add('enabled-button');
		this.submitCompensationBtnTarget.classList.remove('disabled-button');
	}
	disableNextWFH(){
		this.submitCompensationBtnTarget.setAttribute('disabled', true);
		this.submitCompensationBtnTarget.classList.add('disabled-button');
		this.submitCompensationBtnTarget.classList.add('enabled-button');
	}
	selectedYearsWFH(e){
		if(this.selectYearWFHTarget.value.split(',').indexOf(e.currentTarget.value) === -1) {
			e.currentTarget.classList.add('selected-button');
			this.selectYearWFHTarget.value = this.selectYearWFHTarget.value + ',' + e.currentTarget.value
		}
		else {
			e.currentTarget.classList.remove('selected-button');
			let result = this.removeArrayElement(this.selectYearWFHTarget.value.split(','), e.currentTarget.value)
			this.selectYearWFHTarget.value = result.join(',')
		}

		if(this.selectYearWFHTarget.value.length >= 1) {
			this.enableNextWFH();
		}
		else {
			this.disableNextWFH();
		}
		console.log(this.selectYearWFHTarget.value)
	}
	submitWFHStep1()
	{
		if(document.getElementsByClassName("work-covid-no")[0].classList.contains('employment-button'))
		{
			this.step1ModalTarget.style.display ='none';
			this.step8ModalTarget.style.display = 'block';
			document.getElementById('step1Form').scrollIntoView({
				behavior: "smooth",
				block: 'center'
			});
		}
		else
		{
			document.getElementById('amountSpan').textContent = ""+(this.selectYearWFHTarget.value.split(',').length-1)*312
			document.getElementById('years-step5').textContent = this.selectYearWFHTarget.value.replace(",", "\n")
			if (this.selectYearWFHTarget.value.split(',').indexOf("2020/2021") === -1){
				data.append('worked2020', 'no')
			}
			else{
				data.append('worked2020', 'yes')
			}
			if (this.selectYearWFHTarget.value.split(',').indexOf("2021/2022") === -1)
				data.append('worked2021', 'no')
			else{
				data.append('worked2021', 'yes')
			}
			
			this.step1ModalTarget.style.display ='none';
			this.step2ModalTarget.style.display = 'block';
			document.getElementById('step2Form').scrollIntoView({
				behavior: "smooth",
				block: 'center'
			});
		}
	}
	doneWfh(e){
		if (e.currentTarget.classList.contains('work-covid-yes'))
		{
			if (e.currentTarget.classList.contains('employment-button')){
				document.getElementById("wfh-step1-years").classList.add('wfh-step1-years');
				e.currentTarget.classList.remove('employment-button');
				this.disableNextWFH();
			}
			else{
				e.currentTarget.classList.add('employment-button')
				document.getElementById("wfh-step1-years").classList.remove('wfh-step1-years');
				document.getElementsByClassName("work-covid-no")[0].classList.remove('employment-button');
				if(this.selectYearWFHTarget.value.length >= 1) {
					this.enableNextWFH();
				}
				else {
					this.disableNextWFH();
				}
			}
		}
		else
		{
			if (e.currentTarget.classList.contains('employment-button')){
				e.currentTarget.classList.remove('employment-button');
				this.disableNextWFH();
			}
			else{
				e.currentTarget.classList.add('employment-button')
				document.getElementById("wfh-step1-years").classList.add('wfh-step1-years');
				document.getElementsByClassName("work-covid-yes")[0].classList.remove('employment-button');
				this.enableNextWFH();
			}
		}
	}
	
	step2CheckNext(){
		return ((document.getElementsByClassName('work-required-yes')[0].classList.contains('employment-button') ||
		document.getElementsByClassName('work-required-no')[0].classList.contains('employment-button')) && 
		(document.getElementsByClassName('work-claimed-yes')[0].classList.contains('employment-button') ||
		document.getElementsByClassName('work-claimed-no')[0].classList.contains('employment-button')) )
	}

	requiredWfh(e){
		if (e.currentTarget.classList.contains('employment-button')){
			e.currentTarget.classList.remove('employment-button');
		}
		else{
			e.currentTarget.classList.add('employment-button')
		}
		if (document.getElementsByClassName('work-required-yes')[0].classList.contains('employment-button') &&
		document.getElementsByClassName('work-required-no')[0].classList.contains('employment-button')){
			document.getElementsByClassName('work-required-yes')[0].classList.remove('employment-button')
			document.getElementsByClassName('work-required-no')[0].classList.remove('employment-button')
			e.currentTarget.classList.add('employment-button')
		}
		console.log("Required : " + this.step2CheckNext())
		if (this.step2CheckNext()){
			this.submitStep2Target.removeAttribute('disabled');
			this.submitStep2Target.classList.add('enabled-button');
			this.submitStep2Target.classList.remove('disabled-button');
		}
		else{
			this.submitStep2Target.setAttribute('disabled', true);
			this.submitStep2Target.classList.add('disabled-button');
			this.submitStep2Target.classList.add('enabled-button');
		}
	}

	claimedWfh(e){
		if (e.currentTarget.classList.contains('employment-button')){
			e.currentTarget.classList.remove('employment-button');
		}
		else{
			e.currentTarget.classList.add('employment-button')
		}

		if (document.getElementsByClassName('work-claimed-yes')[0].classList.contains('employment-button') &&
			document.getElementsByClassName('work-claimed-no')[0].classList.contains('employment-button')){
			document.getElementsByClassName('work-claimed-yes')[0].classList.remove('employment-button')
			document.getElementsByClassName('work-claimed-no')[0].classList.remove('employment-button')
			e.currentTarget.classList.add('employment-button')
		}

		console.log("Claimed : " + this.step2CheckNext())
		if (this.step2CheckNext()){
			this.submitStep2Target.removeAttribute('disabled');
			this.submitStep2Target.classList.add('enabled-button');
			this.submitStep2Target.classList.remove('disabled-button');
		}
		else{
			this.submitStep2Target.setAttribute('disabled', true);
			this.submitStep2Target.classList.add('disabled-button');
			this.submitStep2Target.classList.add('enabled-button');
		}
	}
	submitWFHStep2(){
		if((document.getElementsByClassName('work-required-yes')[0].classList.contains('employment-button') ||
			document.getElementsByClassName('work-claimed-yes')[0].classList.contains('employment-button')))
		{
			this.step2ModalTarget.style.display ='none';
			this.step8ModalTarget.style.display = 'block';
			document.getElementById('step1Form').scrollIntoView({
				behavior: "smooth",
				block: 'center'
			});
		}
		else
		{
			data.append('claimed_allowance', 'yes')
			this.step2ModalTarget.style.display ='none';
			this.step3ModalTarget.style.display = 'block';
			document.getElementById('step3Form').scrollIntoView({
				behavior: "smooth",
				block: 'center'
			});
		}
	}

	validateEmployerDetails(){
		if (this.EmployerNameTarget.value.length > 0 && this.IndustryTarget.value != '')
		{
			this.submitStep3Target.removeAttribute('disabled');
			this.submitStep3Target.classList.add('enabled-button');
			this.submitStep3Target.classList.remove('disabled-button');
		}
		else
		{
			this.submitStep3Target.setAttribute('disabled', true);
			this.submitStep3Target.classList.add('disabled-button');
			this.submitStep3Target.classList.add('enabled-button');
		}
	}

	submitWFHStep3(){
		document.getElementById('employerName-step5').textContent = this.EmployerNameTarget.value
		document.getElementById('employerNamestep4').textContent = this.EmployerNameTarget.value
		data.append('industry', this.IndustryTarget.value)
		data.append('employer_name', this.EmployerNameTarget.value)
		this.step3ModalTarget.style.display ='none';
		this.step4ModalTarget.style.display = 'block';
		document.getElementById('step4Form').scrollIntoView({
			behavior: "smooth",
			block: 'center'
		});
	}

	submitWFHStep4(){
		if (this.pyeeStartTarget.value!="" || this.pyeeStartTarget.value != ""){
			document.getElementById('pyeeRef-step5').textContent = this.pyeeStartTarget.value + "/" + this.pyeeEndTarget.value
			data.append('paye', this.pyeeStartTarget.value + "/" + this.pyeeEndTarget.value)
		}
		else{
			data.append('paye', "")
		}
		this.step4ModalTarget.style.display ='none';
		this.step5ModalTarget.style.display = 'block';
		document.getElementById('step5Form').scrollIntoView({
			behavior: "smooth",
			block: 'center'
		});
	}

	submitWFHStep5(){
		this.step5ModalTarget.style.display ='none';
		this.step6ModalTarget.style.display = 'block';
		document.getElementById('step6Form').scrollIntoView({
			behavior: "smooth",
			block: 'center'
		});
	}

	clear() {
    this.signature_pad.clear();
		this.signatureErrorTarget.checked = false;
  }

  signatureCheck(event){
    let dots = this.signature_pad.toData()
		if (event.currentTarget.checked)
		{
			if(this.signature_pad.isEmpty()) {
				event.preventDefault();
				this.signatureErrorTarget.classList.remove('hide')
				this.signatureErrorTarget.innerHTML = 'Please sign before proceeding...'
				event.currentTarget.checked = false
			}
				else if (dots && dots[0].points.length <= 10) {
				event.preventDefault();
				this.signatureErrorTarget.classList.remove('hide')
				this.signatureErrorTarget.innerHTML = 'Please add a full signature. Clear and try again'
				event.currentTarget.checked = false
			}
			else{
				this.signatureErrorTarget.classList.add('hide')
				this.submitStep6Target.removeAttribute('disabled');
				this.submitStep6Target.classList.add('enabled-button');
				this.submitStep6Target.classList.remove('disabled-button');
			}
		}
		else{
			this.submitStep6Target.setAttribute('disabled', true);
			this.submitStep6Target.classList.add('disabled-button');
			this.submitStep6Target.classList.add('enabled-button');
		}
  }

  step5CheckBoxes(){
		if (document.querySelector('.step5-first-checkbox').checked && document.querySelector('.step5-second-checkbox').checked &&
		document.querySelector('.step5-third-checkbox').checked && document.querySelector('.step5-fourth-checkbox').checked){
			this.submitStep5Target.removeAttribute('disabled');
			this.submitStep5Target.classList.add('enabled-button');
			this.submitStep5Target.classList.remove('disabled-button');
		}
		else{
			this.submitStep5Target.setAttribute('disabled', true);
			this.submitStep5Target.classList.add('disabled-button');
			this.submitStep5Target.classList.add('enabled-button');
		}
  }

  signatureNext(event){
    let dots = this.signature_pad.toData()
    let signatureModal = this.signatureModalTarget;
    let step7Modal = this.step7ModalTarget;
    let urlAddress = window.location.href.split('/');
    let templateId = window.location.href.split('/').pop();
    let clientPostPackCode = urlAddress[urlAddress.length - 2];
    data.append('step', 7);
    data.append('template_id', templateId);
		data.append('still_working_here', 'no')

    if(this.signature_pad.isEmpty()) {
      event.preventDefault();
      this.signatureErrorTarget.classList.remove('hide')
      this.signatureErrorTarget.innerHTML = 'Please sign before proceeding>>'
    }
    else if (dots && dots[0].points.length <= 10) {
      event.preventDefault();
      this.signatureErrorTarget.classList.remove('hide')
      this.signatureErrorTarget.innerHTML = 'Please add a full signature. Clear and try again>>'
     }
     else if(!document.querySelector('.signature-checkbox').checked) {
      event.preventDefault();
      this.signatureErrorTarget.classList.remove('hide');
      this.signatureErrorTarget.innerHTML = 'Please tick the box above to proceed';
     }
     else {
		event.preventDefault();
		data.append('signature', this.signature_pad.toDataURL());
		Rails.ajax({
			url: `/onboarding_wfh_update/${clientPostPackCode}`,
			type: 'patch',
			data: data,
			success: function(data) {
				document.getElementById("step6Form").style.display = 'none'
				document.getElementById("step7Form").style.display = 'block'
				document.getElementById('step7Form').scrollIntoView({
					behavior: "smooth",
					block: 'center'
				});
			},
			error: function(data) {}
		})
    }
  }

	openPyeeSupport(){
		var pyeeSupportForm = document.getElementById('pyeeSupport');
		var pyeeSupportOverlay = document.getElementById('pyeeSupportOverlay');
		pyeeSupportForm.classList.remove('hidden');
		pyeeSupportOverlay.classList.remove('hidden');  
	}
	closePyeeSupport() {
		var pyeeSupportForm = document.getElementById('pyeeSupport');
		var pyeeSupportOverlay = document.getElementById('pyeeSupportOverlay');
		pyeeSupportForm.classList.add('hidden');
		pyeeSupportOverlay.classList.add('hidden');
	}

	removeArrayElement(arr, value) {
    return arr.filter(function(ele){
      return ele != value;
    });
  }
}
