import { Controller } from "stimulus"
export default class extends Controller {
    static targets = [ "source", "target", "client", "industry", 'job_title' ];

    connect(){
        console.log('position select')

    }

    handleSelectChange() {
        this.populateSelect(this.sourceTarget.value);
    }

    removeOptions(selectElement) {
        var i, L = selectElement.options.length - 1;
        for(i = L; i >= 0; i--) {
            selectElement.remove(i);
        }
    }

    populateSelect(sourceId, targetId = null) {

        let position_select = this.targetTarget
        this.removeOptions(position_select);

        Rails.ajax({
            type: 'GET',
            dataType: 'script',
            url: `/get_positions/${sourceId}`,
            credentials: "include",
            error: function(jqXHR, textStatus, errorThrown)  {
                console.log("AJAX Error:", textStatus)
                alert('There was an error.')
            },
            success: (data) =>  {
                let positions = data['positions_by_industry'];
                for (let i = 0; i < positions.length; i++) {
                    let opt = document.createElement('option');
                    opt.value = positions[i]["name"];
                    opt.innerHTML = positions[i]["name"];
                    position_select.appendChild(opt);

                }
                this.industryTarget.value = this.sourceTarget.options[this.sourceTarget.selectedIndex].innerHTML;
                this.job_titleTarget.value = this.targetTarget.options[this.targetTarget.selectedIndex].innerHTML;
            }
        })

    }

    submitEmployments(){
        var form_url = document.querySelector('form').action
        var match = form_url.match(/(\d+)/)
        Rails.ajax({
            type: 'PUT',
            dataType: 'script',
            url: `add_employments/${match[0]}`,
            credentials: "include",
            error: function(jqXHR, textStatus, errorThrown)  {
                console.log("AJAX Error:", textStatus)
                alert('There was an error.')
            },
            success: (data) =>  {
                console.log("Submit OK!", data)
                //let positions = data['positions_by_industry'];
                //for (let i = 0; i < positions.length; i++) {
                //    let opt = document.createElement('option');
                //    opt.value = positions[i]["name"];
                //    opt.innerHTML = positions[i]["name"];
                //    position_select.appendChild(opt);
//
                //}

            }
        })
    }

    validate(e){
        e.preventDefault();
        var ddl = document.getElementById("position");
        var selectedValue = ddl.options[ddl.selectedIndex].text;
        if (selectedValue == "Select One")
        {
            alert("Please select an Industry, followed by a Position.");
        } else {
            console.log('selected')
           //document.getElementById('client-list-form').submit()
        }
    }

    addTextPosition(){
        if (this.industryTarget.value == 39){
            console.log('not listed')
        }
    }

    handleJobTitleChange() {
        if (this.targetTarget){
            this.job_titleTarget.value = this.targetTarget.value
        }
    }


}
