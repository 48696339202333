import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['form','error_container', 'icon', 'error_title', 'error_text_container', 'error_text', 'child'];

connect(){

}
    submitForm(event) {
        event.preventDefault();
        let isValid = this.validateForm(this.formTarget);

        // If our form is invalid, prevent default on the event
        // so that the form is not submitted
        console.log(isValid)


            if (isValid === true){
                document.querySelector('form').submit();
            }
            else{
                this.showNotif('Please ensure all fields are filled', 'error')
            }


    }

   submitDynamicForm(event) {
       event.preventDefault();
      let all_new_records =   document.querySelectorAll('[data-new-record]')
       let arr = []
        all_new_records.forEach((field) => {


           if (window.getComputedStyle(field).display === 'none') {

               console.log(window.getComputedStyle(field).display)
           }
           else{
               let isValid =  this.validateDynForm(field)
                if(isValid === true){
                    arr.push(true)

                }
               else {
                    arr.push(false)
               }
           }

        });
     if (arr.includes(false))
     {
         this.showNotif('Please ensure all fields are filled', 'error')
     }
     else
     {
         document.querySelector('form').submit();

     }
    }

    validateForm() {
        let isValid = true;
        // Tell the browser to find any required fields
        let requiredFieldSelectors = 'text_field:required, input:required, select:required';
        let requiredFields = this.formTarget.querySelectorAll(requiredFieldSelectors);

        requiredFields.forEach((field) => {
            // For each required field, check to see if the value is empty
            // if so, we focus the field and set our value to false

            if (!field.disabled && !field.value.trim() ) {
                field.focus();

                isValid = false;
            }
        });

        return isValid;

    }

    validateDynForm(field) {
        let isValid = true;
        // Tell the browser to find any required fields
        let requiredFieldSelectors = 'text_field:required, input:required, select:required';
        let requiredFields = field.querySelectorAll(requiredFieldSelectors);

        requiredFields.forEach((field) => {
            // For each required field, check to see if the value is empty
            // if so, we focus the field and set our value to false

            if (!field.disabled && !field.value.trim() ) {
                field.focus();

                isValid = false;
            }
        });

        return isValid;
    }


    showNotif(msg, type) {

        this.error_textTarget.innerHTML = msg
        if (type === 'success') {
            this.error_containerTarget.classList.remove('bg-red-50')
            this.iconTarget.classList.remove('text-red-400')
            this.error_titleTarget.classList.remove('text-red-800')
            this.error_text_containerTarget.classList.remove('text-red-700')

            this.error_containerTarget.classList.add('bg-green-50')
            this.iconTarget.classList.add('text-green-400')
            this.error_titleTarget.classList.add('text-green-800')
            this.error_text_containerTarget.classList.add('text-green-700')


        } else {
            this.error_containerTarget.classList.add('bg-red-50')
            this.iconTarget.classList.add('text-red-400')
            this.error_titleTarget.classList.add('text-red-800')
            this.error_text_containerTarget.classList.add('text-red-700')

            this.error_containerTarget.classList.remove('bg-green-50')
            this.iconTarget.classList.remove('text-green-400')
            this.error_titleTarget.classList.remove('text-green-800')
            this.error_text_containerTarget.classList.remove('text-green-700')
        }
        this.error_containerTarget.classList.remove('hide');
        this.error_containerTarget.classList.add('show');

    }



}