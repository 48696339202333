import { Controller } from "stimulus";
// import SignaturePad from "signature_pad";

let step1Data = new FormData();

let urlAddress = window.location.href.split("/");
let templateId = window.location.href.split("/").pop();
let clientPostPackCode = urlAddress[urlAddress.length - 2];

export default class extends Controller {
  static targets = [
    "questionModal", "compensationPaymentAnswer", "cardModal", "title", "firstName", "lastName", "phoneNumber", "submitClientDetailsBtn",
    "cardBtnSpinner", "cardBtnArrow", "thanksModal", "claimerName", "tryAgainModal"
  ];

  connect() {
    this.carousel();
  }

  carousel() {
    const delay = 3000; //ms

    const slides = document.querySelector(".slides");
    const slidesCount = slides.childElementCount;
    const maxLeft = (slidesCount - 1) * 100 * -1;

    let current = 0;

    function changeSlide(next = true) {
      if (next) {
        current += current > maxLeft ? -100 : current * -1;
      }
      slides.style.left = current + "%";
    }

    setInterval(changeSlide, delay);
  }

  openDialog() {
    var dialog = document.getElementById("dialog");
    var closeButton = document.getElementById("close");
    var overlay = document.getElementById("overlay");
    dialog.classList.remove("hidden");
    overlay.classList.remove("hidden");
  }

  closeDialog() {
    var dialog = document.getElementById("dialog");
    var overlay = document.getElementById("overlay");
    dialog.classList.add("hidden");
    overlay.classList.add("hidden");
  }

  openPrivacyPolicy() {
    var dialogPrivacyPolicy = document.getElementById("dialogPrivacyPolicy");
    var overlayPrivacyPolicy = document.getElementById("overlayPrivacyPolicy");
    dialogPrivacyPolicy.classList.remove("hidden");
    overlayPrivacyPolicy.classList.remove("hidden");
  }

  closePrivacyPolicy() {
    var dialogPrivacyPolicy = document.getElementById("dialogPrivacyPolicy");
    var overlayPrivacyPolicy = document.getElementById("overlayPrivacyPolicy");
    dialogPrivacyPolicy.classList.add("hidden");
    overlayPrivacyPolicy.classList.add("hidden");
  }

  openTermsConditions() {
    var dialogTermsConditions = document.getElementById(
      "dialogTermsConditions"
    );
    var overlayTermsConditions = document.getElementById(
      "overlayTermsConditions"
    );
    dialogTermsConditions.classList.remove("hidden");
    overlayTermsConditions.classList.remove("hidden");
  }

  closeTermsConditions() {
    var dialogTermsConditions = document.getElementById(
      "dialogTermsConditions"
    );
    var overlayTermsConditions = document.getElementById(
      "overlayTermsConditions"
    );
    dialogTermsConditions.classList.add("hidden");
    overlayTermsConditions.classList.add("hidden");
  }

  openAuthoriseForm(e) {
    if (e.currentTarget.id === "r40") {
      document.getElementById("r40-Form").classList.remove("hidden");
      document.getElementById("64-8-Form").classList.add("hidden");
    } else {
      document.getElementById("64-8-Form").classList.remove("hidden");
      document.getElementById("r40-Form").classList.add("hidden");
    }
    var authoriseForm = document.getElementById("authoriseForm");
    var authoriseOverlay = document.getElementById("authoriseOverlay");
    authoriseForm.classList.remove("hidden");
    authoriseOverlay.classList.remove("hidden");
  }

  closeAuthoriseForm() {
    var authoriseForm = document.getElementById("authoriseForm");
    var authoriseOverlay = document.getElementById("authoriseOverlay");
    authoriseForm.classList.add("hidden");
    authoriseOverlay.classList.add("hidden");
  }

  compenstionPaymentSelector(e){
    this.clearAnswer();
    e.currentTarget.classList.add('selected-button');
    this.questionModalTarget.style.display = 'none';
    this.cardModalTarget.style.display = 'block';
    // if(e.currentTarget.innerHTML === "Yes"){
    //   this.cardModalTarget.style.display = 'block';
    //   window.scrollTo({ top: 0, behavior: "smooth" });
    //   // document.getElementById('clientForm').scrollIntoView({
    //   //   behavior: "smooth",
    //   //   block: 'start'
    //   // });
    // } else {
    //   this.tryAgainModalTarget.style.display = 'block';
    //   window.scrollTo({ top: 0, behavior: "smooth" });
    //   // document.getElementById('tryAgainForm').scrollIntoView({
    //   //   behavior: "smooth",
    //   //   block: 'start'
    //   // });
    // }
  }

  clearAnswer(){
    this.compensationPaymentAnswerTargets.forEach(answer => {
      answer.classList.remove('selected-button');
    });
  }

  tryAgainBtn(){
    this.clearAnswer();
    this.tryAgainModalTarget.style.display = 'none';
    this.questionModalTarget.style.display = 'block';
    window.scrollTo({ top: 0, behavior: "smooth" });
    // document.getElementById('questionForm').scrollIntoView({
    //   behavior: "smooth",
    //   block: 'center'
    // });
  }

  submitPersonalDetails() {
    let firstName, lastName, phoneNumber, title;
    this.cardBtnArrowTarget.classList.add("hidden");
    this.cardBtnSpinnerTarget.classList.remove("hidden");
    firstName = this.firstNameTarget.value;
    lastName = this.lastNameTarget.value;
    phoneNumber = this.phoneNumberTarget.value;
    title = this.titleTarget.value;
    let cardModal = this.cardModalTarget;
    let thanksModal = this.thanksModalTarget;
    cardModal.style.display = "none";
    thanksModal.style.display = "block";
    this.claimerNameTargets.forEach((elem) => {
      elem.innerHTML = `${title} ${lastName}`;
    });

    if (firstName != "" && lastName != "" && /^(01|07)\d{9}$/.test(phoneNumber) && phoneNumber != "") {
      step1Data.append("title", title);
      step1Data.append("first_name", firstName);
      step1Data.append("last_name", lastName);
      step1Data.append("mobile_phone", phoneNumber);
      step1Data.append("template_id", templateId);
      step1Data.append("step", "1");

      Rails.ajax({
        url: `/onboarding_pha_short_update/${clientPostPackCode}`,
        type: "patch",
        data: step1Data,
        success: function (data) {
          console.log(data);

          document.getElementById("clientForm").style.display = "none";
          document.getElementById("thanksForm").style.display = "block";
          window.scrollTo({ top: 0, behavior: "smooth" });
          // document.getElementById("thanksForm").scrollIntoView({
          //   behavior: "smooth",
          //   block: "center",
          // });
        },
        error: function (data) {},
      });
    }

    // //  Initialize signature
    // const canvas = document.querySelector("canvas");
    // canvas.style.width='100%';
    // canvas.style.height='100%';
    // canvas.width  = canvas.offsetWidth;
    // canvas.height = canvas.offsetHeight;

    // if (canvas) {
    //   //this.resizeCanvas(canvas);
    //   this.signature_pad = new SignaturePad(canvas, { drawOnly: true });
    // }
  }

  // signature(event) {
  //   WjLander = JSON.parse(localStorage.getItem("WjLander"));
  //   if (WjLander.signature) {
  //     let signature = WjLander.signature;
  //     step4Data.append("signature", signature);
  //     step4Data.append("template_id", templateId);
  //     step4Data.append("step", "4");
  //     Rails.ajax({
  //       url: `/onboarding_pha_short_update/${clientPostPackCode}`,
  //       type: "patch",
  //       data: step4Data,
  //       success: function (data) {
  //         if (WjLander) {
  //           currentPage = 4;
  //           WjLander.currentPage = currentPage;
  //           localStorage.setItem("WjLander", JSON.stringify(WjLander));
  //         }
  //         document.getElementById("employmentForm").style.display = "none";
  //         document.getElementById("insuranceForm").style.display = "block";
  //         document.getElementById("insuranceForm").scrollIntoView({
  //           behavior: "smooth",
  //           block: "center",
  //         });
  //       },
  //       error: function (data) {
  //         console.log(data);
  //       },
  //     });
  //   } else {
  //     let signatureModal = this.signatureModalTarget;
  //     let insuranceModal = this.insuranceModalTarget;
  //     let dots = this.signature_pad.toData();

  //     // let checkConsentone = this.checkConsentOneTarget.checked
  //     // let checkConsentwo = this.checkConsentTwoTarget.checked;
  //     // let checkConsentthree = this.checkConsentThreeTarget.checked;
  //     // let checkConsentfour = this.checkConsentFourTarget.checked;

  //     if (this.signature_pad.isEmpty()) {
  //       event.preventDefault();
  //       this.signatureErrorTarget.classList.remove("hide");
  //       this.signatureErrorTarget.innerHTML = "Please sign before proceeding";
  //     } else if (dots && dots[0].points.length <= 10) {
  //       event.preventDefault();
  //       this.signatureErrorTarget.classList.remove("hide");
  //       this.signatureErrorTarget.innerHTML =
  //         "Please add a full signature. Clear and try again";
  //     }
  //     else {
  //       this.signatureBtnArrowTarget.classList.add("hidden");
  //       this.signatureBtnSpinnerTarget.classList.remove("hidden");
  //       event.preventDefault();
  //       WjLander.signature = this.signature_pad.toDataURL();
  //       localStorage.setItem("WjLander", JSON.stringify(WjLander));
  //       step4Data.append("signature", this.signature_pad.toDataURL());
  //       step4Data.append("template_id", templateId);
  //       step4Data.append("step", "4");
  //       Rails.ajax({
  //         url: `/onboarding_pha_short_update/${clientPostPackCode}`,
  //         type: "patch",
  //         data: step4Data,
  //         success: function (data) {
  //           if (WjLander) {
  //             currentPage = 4;
  //             WjLander.currentPage = currentPage;
  //             localStorage.setItem("WjLander", JSON.stringify(WjLander));
  //           }
  //           signatureModal.style.display = "none";
  //           insuranceModal.style.display = "block";
  //           document.getElementById("insuranceForm").scrollIntoView({
  //             behavior: "smooth",
  //             block: "center",
  //           });
  //         },
  //         error: function (data) {
  //           console.log(data);
  //         },
  //       });
  //     }
  //   }
  // }

  // submitInsuranceDetails(e) {
  //   let ni_number;
  //   let insuranceModal;
  //   let lenderModal;

  //   WjLander = JSON.parse(localStorage.getItem("WjLander"));

  //   if (WjLander.ni_number) {
  //     ni_number = WjLander.ni_number;
  //   } else {
  //     this.insuranceBtnArrowTarget.classList.add("hidden");
  //     this.insuranceBtnSpinnerTarget.classList.remove("hidden");
  //     ni_number =
  //       document.querySelector("#nin-char-2").value +
  //       document.querySelector("#nin-number-1").value +
  //       document.querySelector("#nin-number-2").value +
  //       document.querySelector("#nin-number-3").value +
  //       document.querySelector("#nin-char-1").value;
  //     WjLander.ni_number = ni_number;
  //     localStorage.setItem("WjLander", JSON.stringify(WjLander));
  //   }

  //   // let clientFullName = this.clientFullNameTarget;

  //   step5Data.append("ni_number", ni_number.toUpperCase().replace(/ /g, ""));
  //   step5Data.append("template_id", templateId);
  //   step5Data.append("step", "5");

  //   Rails.ajax({
  //     url: `/onboarding_wj_update/${clientPostPackCode}`,
  //     type: "patch",
  //     data: step5Data,
  //     success: function (data) {
  //       if (WjLander) {
  //         currentPage = 5;
  //         WjLander.currentPage = currentPage;
  //         localStorage.setItem("WjLander", JSON.stringify(WjLander));
  //       }
  //       if (!WjLander.ni_number) {
  //         insuranceModal = this.insuranceModalTarget;
  //         lenderModal = this.lenderModalTarget;
  //         insuranceModal.style.display = "none";
  //         lenderModal.style.display = "block";
  //       } else {
  //         document.getElementById("insuranceForm").style.display = "none";
  //         document.getElementById("lenderForm").style.display = "block";
  //       }
  //       document.getElementById("lenderForm").scrollIntoView({
  //         behavior: "smooth",
  //         block: "start",
  //       });
  //     },
  //     error: function (data) {
  //       console.log(data);
  //     },
  //   });

  //   // let title =  step1Data.get('title') != '' ? step1Data.get('title') : "" ;
  //   // let first_name =  step1Data.get('first_name') != '' ? step1Data.get('first_name') : "" ;
  //   // let last_name =  step1Data.get('last_name') != '' ? step1Data.get('last_name') : "" ;
  //   // let dob = new Date(step1Data.get('date_of_birth'));
  //   // let day = dob.getDate();
  //   // day = day < 10 ? '0' + day : '' + day;
  //   // let month = dob.getMonth() + 1;
  //   // month = month < 10 ? '0' + month : '' + month;
  //   // let year = dob.getFullYear();
  //   // document.getElementById('full_name').textContent = title + ' ' + first_name + ' ' + last_name ;
  //   // document.getElementById('address1').textContent = step1Data.get('address1') ;
  //   // document.getElementById('address2').textContent = step1Data.get('address2') ;
  //   // document.getElementById('detailspostcity').textContent = step1Data.get('city');
  //   // document.getElementById('detailspostcode').textContent = step1Data.get('postcode');
  //   // document.getElementById('dob').textContent = day + '/' + month + '/' + year;
  //   // document.getElementById('ni_number').textContent = step7Data.get('ni_number');
  //   // document.getElementById('detailsconsentForm').scrollIntoView({
  //   //   behavior: 'smooth',
  //   //   block: 'start'
  //   // });

  //   // this.scrollAbc();
  // }

  waitForElement(selector) {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }

  validateClientDetails(e) {
    let allDetailsVerified = true;
    let firstName = this.firstNameTarget.value;
    let lastName = this.lastNameTarget.value;
    let phoneNumber = this.phoneNumberTarget.value;
    let title = this.titleTarget.value;
    switch (e.currentTarget.id) {
      case "title":
        this.titleTarget.classList.add("selected-option");
        break;
    }

    if (
      firstName == "" ||
      lastName == "" ||
      title == ""
    ) {
      allDetailsVerified = false;
    }

    if (phoneNumber == "" || !/^(01|07)\d{9}$/.test(phoneNumber)) {
      allDetailsVerified = false;
    }

    if (allDetailsVerified == false) {
      this.submitClientDetailsBtnTarget.setAttribute("disabled", true);
      this.submitClientDetailsBtnTarget.classList.add("disabled-button");
      this.submitClientDetailsBtnTarget.classList.remove("enabled-button");
    } else {
      console.log("good to go");
      this.submitClientDetailsBtnTarget.removeAttribute("disabled");
      this.submitClientDetailsBtnTarget.classList.remove("disabled-button");
      this.submitClientDetailsBtnTarget.classList.add("enabled-button");
    }
  }

  validatePhoneNumber(e) {
    let phoneNumber = e.target.value;
    let firstName = this.firstNameTarget.value;
    let lastName = this.lastNameTarget.value;
    let allDetailsVerified = true;

    document.querySelector(".user-phonenumber-error").innerHTML = "Hello";
    document.querySelector(".user-phonenumber-error").style.cssText =
      "opacity: 0";

    setTimeout(() => {
      if (phoneNumber == "" || !/^(01|07)\d{9}$/.test(phoneNumber)) {
        document.querySelector(".user-phonenumber-error").style.cssText =
          "opacity: 1";
        document.querySelector(".user-phonenumber-error").style.cssText =
          "color: #E10000;";
        document.querySelector(".user-phonenumber-error").innerHTML =
          "Please enter a valid phone number";
      } else {
        document.querySelector(".user-phonenumber-error").innerHTML = "Hello";
        document.querySelector(".user-phonenumber-error").style.cssText =
          "opacity: 0";
      }
    }, 1000);

    if (
      firstName == "" ||
      lastName == ""
    ) {
      allDetailsVerified = false;
    }

    if (phoneNumber == "" || !/^(01|07)\d{9}$/.test(phoneNumber)) {
      allDetailsVerified = false;
    }

    if (allDetailsVerified == false) {
      this.submitClientDetailsBtnTarget.setAttribute("disabled", true);
      this.submitClientDetailsBtnTarget.classList.add("disabled-button");
      this.submitClientDetailsBtnTarget.classList.remove("enabled-button");
    } else {
      console.log("good to go");
      this.submitClientDetailsBtnTarget.removeAttribute("disabled");
      this.submitClientDetailsBtnTarget.classList.remove("disabled-button");
      this.submitClientDetailsBtnTarget.classList.add("enabled-button");
    }
  }

  validateNiNumber(e) {
    let regex = new RegExp(
      "^(?!BG|GB|KN|NK|NT|TN|ZZ)[^DFIQUV][^DFIQUVo][0-9]{6}[ABCD]$"
    );

    if (e.target.value.length == 2) {
      let class_lists = Object.values(e.target.classList);
      let class_list = class_lists.find((val) => val.includes("inputs"));
      let val = parseInt(class_list.split("_").pop()) + 1;
      document.querySelector(`.inputs_${val}`).focus();
    }

    let ni_number =
      document.querySelector("#nin-char-2").value +
      document.querySelector("#nin-number-1").value +
      document.querySelector("#nin-number-2").value +
      document.querySelector("#nin-number-3").value +
      document.querySelector("#nin-char-1").value;

    if (ni_number.length === 9 && this.stripNi(ni_number).match(regex)) {
      this.enableInsuranceBtnTarget.removeAttribute("disabled");
      this.enableInsuranceBtnTarget.classList.remove("disabled-button");
      this.enableInsuranceBtnTarget.classList.add("enabled-button");
    } else {
      this.enableInsuranceBtnTarget.setAttribute("disabled", true);
      this.enableInsuranceBtnTarget.classList.add("disabled-button");
      this.enableInsuranceBtnTarget.classList.remove("enabled-button");
    }
  }

  canvasDraw() {
    // this.signatureBtnTarget.classList.remove('disabled-button');
    // this.signatureBtnTarget.classList.add('enabled-button');
    // this.signatureBtnTarget.removeAttribute('disabled');
    this.signHerePlaceholderTarget.classList.add("hidden");
  }

  stripNi(ni_value) {
    return ni_value.toUpperCase().replace(/ /g, "");
  }

  clear() {
    this.signature_pad.clear();
    this.signHerePlaceholderTarget.classList.remove("hidden");
  }

  removeArrayElement(arr, value) {
    return arr.filter(function (ele) {
      return ele != value;
    });
  }

  swapFields(element1, element2) {
    element2.parentNode.insertBefore(element2, element1);
  }
}
