import { Controller } from 'stimulus'

export default class extends Controller {

    initialize() {
        this.hide()
    }

    connect() {
        setTimeout(() => {
            this.show()
            setTimeout(() => {this.close()}, 3000);
        }, 200)
    }

    close() {
        this.hide()
        setTimeout(() => {
            this.element.remove()
        }, 1100)

    }

    show() {
        this.element.setAttribute(
            'style',
            "transition: 1s; transform:translate(0, 0);",
        )
    }

    hide() {
        this.element.setAttribute(
            'style',
            "transition: 1s; transform:translate(400px, 0);",
        )
    }
}