// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('trix/dist/trix')
require("chartkick")
require("chart.js")

window.SecurePad = import('signature_pad/dist/signature_pad.min.js')

import "../stylesheets/application.scss"
import "../stylesheets/onboarding_ttrt.scss"
import "../stylesheets/onboarding_pha.scss"
import "../stylesheets/homepage.scss"
import "../stylesheets/onboarding_wj.scss"
import "../stylesheets/onboarding_bd.scss"
const images = require.context('../images', true)
import "controllers"
import ahoy from "ahoy.js";
window.ahoy = ahoy

window.$ = window.jQuery = jquery;
import Rails from "@rails/ujs"
window.Rails = Rails
//import gtag from './ga_analytics'
//window.gtag = gtag
require("@rails/actiontext")
document.addEventListener("turbolinks:load", () => {
    $(".se-pre-con").fadeOut("slow");
})

// Import style for flatpickr
require("flatpickr/dist/flatpickr.css")
//Trix.config.blockAttributes.section = { tagName: "section", nestable: true }
//
//addEventListener("trix-initialize", event => {
//    const buttonHTML  = '<button type="button" class="trix-button" data-trix-attribute="section" title="Custom Section">CS</button>'
//    const groupElement = event.target.toolbarElement.querySelector(".trix-button-group--block-tools")
//    groupElement.insertAdjacentHTML("beforeend", buttonHTML)
//})
