import { Controller } from "stimulus"
import { CharacterCount } from "stimulus-form-utilities"
export default class extends Controller {
    static targets = [ 'input', 'countValue' ]

    initialize() {
        this.maxValue     = this.data.get('max-value')     || '60'
        this.warningClass = this.data.get('warning-class') || 'text-red-500'
        this.update()
    }

    update() {
        var count = this.inputTarget.value.length

        if (count > 0) {
            var message = `${count}/${this.maxValue}  with link before over 1 sms.`
        }

        this.setMessage(message)

        count > this.maxValue ? this.addWarningClass() : this.removeWarningClass()
    }

    setMessage(message) {
        this.countValueTarget.textContent = message
    }

    addWarningClass() {
        this.countValueTarget.classList.add(this.warningClass)
    }

    removeWarningClass() {
        this.countValueTarget.classList.remove(this.warningClass)
    }
}
