import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["add_item", "template"]

    add_lender(event) {
        event.preventDefault()
        var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
        this.add_itemTarget.insertAdjacentHTML('beforebegin', content)
    }

    remove_lender(event) {
        event.preventDefault()
        let item = event.target.closest(".nested-fields")
        let inputs = item.getElementsByTagName("input")
        for (var i=0; i < inputs.length; i++){
            if (inputs[i].type == 'number'){
                inputs[i].value = ''
            }
        }
        item.style.display = 'none'
    }
}
