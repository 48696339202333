import { Controller } from "stimulus";
import SignaturePad from "signature_pad";
import { PostcodeLookup } from "@ideal-postcodes/postcode-lookup";

let step1Data = new FormData();
let step2Data = new FormData();
let step3Data = new FormData();
let step4Data = new FormData();
let step5Data = new FormData();
let step6Data = new FormData();

let urlSplit = window.location.href.split("/").pop();
let sourceRef = " " 
let templateId = urlSplit.split("?")[0];

let currentPage;
let TTRTV2Lander = {};

let signatureCopy;
let hasSignature;

export default class extends Controller {
  static targets = [
    "claim", "employmentBtn", "cardDetails", "checkQuestionModal", "compensationModal", "lenderModal", "employmentModal", "cardModal",
    "personalDetailsModal", "addressModal", "insuranceModal", "signatureModal", "tryAgainModal", "totalAmountReceived",
    "totalAmountReceivedError", "claimProcessStartBtn", "annualIncomeToggle", "signatureBtn", "claimBtnArrow", "employmentBtnArrow",
    "cardBtnArrow", "signatureBtnArrow", "insuranceBtnArrow", "lenderBtnArrow", "compensationBtnArrow", "claimBtnSpinner",
    "employmentBtnSpinner", "cardBtnSpinner", "signatureBtnSpinner", "insuranceBtnSpinner", "lenderBtnSpinner", "compensationBtnSpinner",
    "addressDetails", "selectDobYear", "selectMonth", "selectDay", "addressSelector", "addressLine1", "addressLine2", "addressLine3",
    "addressPostTown", "addressPostCode", "line1", "line2", "line3", "postTown", "postCode", "firstName", "lastName", "emailAddress",
    "phoneNumber", "questionsModal", "error_text", "error_text_container", "enableInsuranceBtn", "signHerePlaceholder", "thanksModal",
    "claimerName", "selectYear", "modal", "selectedYears", "selectBank", "selectedBanks", "amountReceived", "submitCompensationBtn",
    "selectedEmployment", "findPostAddress", "postalAddress", "selectedWashYes", "selectedWashNo", "selectedHomeYes", "selectedHomeNo",
    "selectedPetrolYes", "selectedPetrolNo", "currentEmployment", "currentSalaryBracket", "selectedPetrolNa", "selectedMarriedNo",
    "selectedMarriedYes", "ni_error_text", "closePlus", "openMinus", "amountReceivedError", "signatureError", "submitPostalAddressBtn",
    "submitClientDetailsBtn", "submitQualifiersBtn", "checkMarried", "checkWashUniform", "checkWfh", "checkPetrol", "detailsconsentModal",
    "checkConsentOne", "checkConsentTwo", "checkConsentThree", "checkConsentFour", "submitDetailConsentBtn", "clientFullName", "title",
    "lenderBankName", "amountDeducted", "otherBank", "lenderOtherBankModal", "lenderOtherBankMobileModal", "otherBankField", "range", 
    "value", "rangeOutput", "rangeOutputWrapper", "payoutYearToggle", "removeLenderBtn", "addLenderBtn", "lenderDropdown", "payoutYear", 
    "addLenderForm", "addLenderFormWrapper", "employmentStatusToggle",
  ];

  connect() {
    sourceRef = urlSplit.split("?")[1];
    sourceRef = sourceRef && sourceRef.split("source_ref=")
    sourceRef = sourceRef && sourceRef[1].split("&")[0]
    setTimeout(() => this.scrollToTop(), 200)
    TTRTV2Lander = JSON.parse(localStorage.getItem("TTRTV2Lander"));
    if (!TTRTV2Lander) {
      let localCache = {};
      currentPage = 0;
      localCache.currentPage = currentPage;
      localStorage.setItem("TTRTV2Lander", JSON.stringify(localCache));
    } else {
      switch (TTRTV2Lander.currentPage) {
        case 1:
          document.getElementById("checkQuestionForm").style.display = "none";
          this.claimProcess();
          break;
        case 2:
          document.getElementById("checkQuestionForm").style.display = "none";
          this.postcodeSetup();
          setTimeout(this.submitEmploymentDetails,1000);
          break;
        case 3:
          document.getElementById("checkQuestionForm").style.display = "none";
          this.claimerNameTargets.forEach((elem) => {
            elem.innerHTML = `${TTRTV2Lander.title} ${TTRTV2Lander.lastName}`;
          });
          this.submitPersonalDetails();
          window.scrollTo({ top: 0, behavior: "smooth" });
          break;
        case 4:
          document.getElementById("checkQuestionForm").style.display = "none";
          this.claimerNameTargets.forEach((elem) => {
            elem.innerHTML = `${TTRTV2Lander.title} ${TTRTV2Lander.lastName}`;
          });
          this.signature();
          break;
        case 5:
          document.getElementById("checkQuestionForm").style.display = "none";
          this.claimerNameTargets.forEach((elem) => {
            elem.innerHTML = `${TTRTV2Lander.title} ${TTRTV2Lander.lastName}`;
          });
          this.submitInsuranceDetails();
          break;
        case 6:
          document.getElementById("checkQuestionForm").style.display = "none";
          this.claimerNameTargets.forEach((elem) => {
            elem.innerHTML = `${TTRTV2Lander.title} ${TTRTV2Lander.lastName}`;
          });
          this.submitEmployment();
          break;
        default:
          break;
      }
    }
    this.carousel();
  }

  carousel() {
    const delay = 3000; //ms

    const slides = document.querySelector(".slides");
    const slidesCount = slides.childElementCount;
    const maxLeft = (slidesCount - 1) * 100 * -1;

    let current = 0;

    function changeSlide(next = true) {
      if (next) {
        current += current > maxLeft ? -100 : current * -1;
      }
      slides.style.left = current + "%";
    }

    setInterval(changeSlide, delay);
  }

  postcodeSetup() {
    window.PostcodeLookup = PostcodeLookup;
    PostcodeLookup.setup({
      context: "#postcode_lookup_field",
      apiKey: "ak_l3jx9ksxXzVXkIr1BMiX1AVixM721",
      outputFields: {
        line_1: "#line_1",
        line_2: "#line_2",
        line_3: "#line_3",
        post_town: "#post_town",
        postcode: "#postcode",
      },
    });
  }

  openDialog() {
    var dialog = document.getElementById("dialog");
    var closeButton = document.getElementById("close");
    var overlay = document.getElementById("overlay");
    dialog.classList.remove("hidden");
    overlay.classList.remove("hidden");
  }

  closeDialog() {
    var dialog = document.getElementById("dialog");
    var overlay = document.getElementById("overlay");
    dialog.classList.add("hidden");
    overlay.classList.add("hidden");
  }

  openPrivacyPolicy() {
    var dialogPrivacyPolicy = document.getElementById("dialogPrivacyPolicy");
    var overlayPrivacyPolicy = document.getElementById("overlayPrivacyPolicy");
    dialogPrivacyPolicy.classList.remove("hidden");
    overlayPrivacyPolicy.classList.remove("hidden");
  }

  closePrivacyPolicy() {
    var dialogPrivacyPolicy = document.getElementById("dialogPrivacyPolicy");
    var overlayPrivacyPolicy = document.getElementById("overlayPrivacyPolicy");
    dialogPrivacyPolicy.classList.add("hidden");
    overlayPrivacyPolicy.classList.add("hidden");
  }

  openTermsConditions() {
    var dialogTermsConditions = document.getElementById(
      "dialogTermsConditions"
    );
    var overlayTermsConditions = document.getElementById(
      "overlayTermsConditions"
    );
    dialogTermsConditions.classList.remove("hidden");
    overlayTermsConditions.classList.remove("hidden");
  }

  closeTermsConditions() {
    var dialogTermsConditions = document.getElementById(
      "dialogTermsConditions"
    );
    var overlayTermsConditions = document.getElementById(
      "overlayTermsConditions"
    );
    dialogTermsConditions.classList.add("hidden");
    overlayTermsConditions.classList.add("hidden");
  }

  openAuthoriseForm(e) {
    if (e.currentTarget.id === "r40") {
      document.getElementById("r40-Form").classList.remove("hidden");
      document.getElementById("64-8-Form").classList.add("hidden");
    } else {
      document.getElementById("64-8-Form").classList.remove("hidden");
      document.getElementById("r40-Form").classList.add("hidden");
    }
    var authoriseForm = document.getElementById("authoriseForm");
    var authoriseOverlay = document.getElementById("authoriseOverlay");
    authoriseForm.classList.remove("hidden");
    authoriseOverlay.classList.remove("hidden");
  }

  closeAuthoriseForm() {
    var authoriseForm = document.getElementById("authoriseForm");
    var authoriseOverlay = document.getElementById("authoriseOverlay");
    authoriseForm.classList.add("hidden");
    authoriseOverlay.classList.add("hidden");
  }

  claimProcess() {
    let checkQuestionModal;
    let employmentModal;
    let totalAmountReceived;
    let net = 0.0;
    let tax = 0.0;
    let gross = 0.0;

    TTRTV2Lander = JSON.parse(localStorage.getItem("TTRTV2Lander"));
    if (TTRTV2Lander.totalAmountReceived) {
      totalAmountReceived = TTRTV2Lander.totalAmountReceived;
      tax = (totalAmountReceived * (11 / 100)).toFixed(2);
      gross = (tax * 5).toFixed(2);
      net = (gross - tax).toFixed(2);
      step3Data.append("lenders", "Marketing");
      step3Data.append("offer_amount", totalAmountReceived);
      step3Data.append("tax", tax);
      step3Data.append("gross", gross);
      step3Data.append("net", net);
      document.getElementById("checkQuestionForm").style.display = "none";
      document.getElementById("employmentForm").style.display = "block";
    } else {
      totalAmountReceived = this.totalAmountReceivedTarget.value;
      if (totalAmountReceived.split(".")[0].length <= 5) {
        TTRTV2Lander.totalAmountReceived = totalAmountReceived;
        localStorage.setItem("TTRTV2Lander", JSON.stringify(TTRTV2Lander));
        checkQuestionModal = this.checkQuestionModalTarget;
        employmentModal = this.employmentModalTarget;
        this.claimBtnArrowTarget.classList.add("hidden");
        this.claimBtnSpinnerTarget.classList.remove("hidden");
      }

      if (totalAmountReceived > 0) {
        tax = (totalAmountReceived * (11 / 100)).toFixed(2);
        gross = (tax * 5).toFixed(2);
        net = (gross - tax).toFixed(2);
      }

      if (totalAmountReceived.split(".")[0].length <= 5) {
        step3Data.append("lenders", "Marketing");
        step3Data.append("offer_amount", totalAmountReceived);
        step3Data.append("tax", tax);
        step3Data.append("gross", gross);
        step3Data.append("net", net);
        

        step1Data.append("template_id", templateId);
        sourceRef && step1Data.append("source_ref", sourceRef);
        step1Data.append("step", "1");
        Rails.ajax({
          url: `/onboarding_ttrt_v2_update`,
          type: "patch",
          data: step1Data,
          success: function (data) {
            console.log(data);
            if (TTRTV2Lander.currentPage == "1") {
              document.getElementById("checkQuestionForm").style.display = "none";
              document.getElementById("employmentForm").style.display = "block";
            } else {
              checkQuestionModal.style.display = "none";
              employmentModal.style.display = "block";
              if (TTRTV2Lander) {
                currentPage = 1;
                TTRTV2Lander.currentPage = currentPage;
                localStorage.setItem("TTRTV2Lander", JSON.stringify(TTRTV2Lander));
              }
            }
            window.scrollTo({ top: 0, behavior: "smooth" });
            // document.getElementById("clientForm").scrollIntoView({
            //   behavior: "smooth",
            //   block: "center",
            // });
          },
          error: function (data) {},
        });
      } else {
        this.totalAmountReceivedErrorTarget.classList.remove("hide");
        this.totalAmountReceivedErrorTarget.innerHTML =
          "Please enter 5 digits or less for amount received to continue";
      }
    }

    this.postcodeSetup();
  }

  submitEmploymentDetails() {
    let employmentStatus;
    let incomeBracket2022;
    let totalAmountReceived, tax, gross, net;

    TTRTV2Lander = JSON.parse(localStorage.getItem("TTRTV2Lander"));
    if (TTRTV2Lander.employmentStatus && TTRTV2Lander.incomeBracket2022) {
      employmentStatus = TTRTV2Lander.employmentStatus;
      incomeBracket2022 = TTRTV2Lander.incomeBracket2022;
      totalAmountReceived = TTRTV2Lander.totalAmountReceived;
      tax = (totalAmountReceived * (11 / 100)).toFixed(2)
      gross = (tax * 5).toFixed(2);
      net = (gross - tax).toFixed(2);
      step3Data.append("employment_status", employmentStatus);
      step3Data.append("income_bracket_2022", incomeBracket2022);
      step3Data.append("lenders", "Marketing");
      step3Data.append("offer_amount", totalAmountReceived);
      step3Data.append("tax", tax);
      step3Data.append("gross", gross);
      step3Data.append("net", net);
      document.getElementById("employmentForm").style.display = "none";
      document.getElementById("clientForm").style.display = "block";
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      this.employmentBtnArrowTarget.classList.add("hidden");
      this.employmentBtnSpinnerTarget.classList.remove("hidden");
      employmentStatus = this.currentEmploymentTarget.value.split("-").map((value) => value.charAt(0).toUpperCase() + value.slice(1)).join(" ");
      incomeBracket2022 = this.currentSalaryBracketTarget.value;
      TTRTV2Lander.employmentStatus = employmentStatus;
      TTRTV2Lander.incomeBracket2022 = incomeBracket2022;
      localStorage.setItem("TTRTV2Lander", JSON.stringify(TTRTV2Lander));
      let employmentModal = this.employmentModalTarget;
      let cardModal = this.cardModalTarget;
      step3Data.append("employment_status", employmentStatus);
      step3Data.append("income_bracket_2022", incomeBracket2022);

      step2Data.append("template_id", templateId);
      sourceRef && step2Data.append("source_ref", sourceRef);
      step2Data.append("step", "2");
      Rails.ajax({
        url: `/onboarding_ttrt_v2_update`,
        type: "patch",
        data: step2Data,
        success: function (data) {
          console.log(data);
          if (TTRTV2Lander) {
            currentPage = 2;
            TTRTV2Lander.currentPage = currentPage;
            localStorage.setItem("TTRTV2Lander", JSON.stringify(TTRTV2Lander));
          }
          employmentModal.style.display = "none";
          cardModal.style.display = "block";
          window.scrollTo({ top: 0, behavior: "smooth" });
          // document.getElementById("checkQuestionForm").scrollIntoView({
          //   behavior: "smooth",
          //   block: "center",
          // });
        },
        error: function (data) {
        },
      });
    }

    document.querySelector(".idpc-button").setAttribute("data-action", "onboarding-ttrt-v2#displaySearchAddress");
    document.querySelector(".idpc-button").setAttribute("data-onboarding-ttrt-v2-target", "findPostAddress");
    document.querySelector(".idpc-input").setAttribute("data-action", "onboarding-ttrt-v2#handlePostalAddress");
    document.querySelector(".idpc-input").setAttribute("data-onboarding-ttrt-v2-target", "postalAddress");
    document.querySelector(".idpc-input").style.cssText = "--tw-ring-color: #F8F8F8; border-color: darkgray; padding-top: 15px; padding-bottom: 15px; border-radius: 4px; width: 100%;";
    document.querySelector(".idpc-button").classList.add("inline-flex", "items-center", "my-5", "px-4", "py-3", "border",
                                                          "border-transparent", "text-xs", "font-bold", "rounded-lg", "text-white",
                                                          "focus:outline-none"
                                                        );
    document.querySelector(".idpc-button").style.cssText = "background-color: #6998ab;";
    if (!document.querySelector(".idpc-button").innerHTML.includes("span")) {
      let spanElement = document.createElement("span");
      spanElement.classList.add("lg:ml-10", "ml-22", "-mr-1", "h-5", "w-5", "mb-1", "pt-1.5", "text-right");
      let fontElement = document.createElement("i");
      fontElement.classList.add("fas", "fa-arrow-right");
      spanElement.appendChild(fontElement);
      document.querySelector(".idpc-button").appendChild(spanElement);
    }
    document.querySelector(".idpc-error").style.cssText = "color: #E10000";
    document.querySelector(".idpc-error").classList.add("text-xs");
  }

  submitPersonalDetails() {
    // let firstName, lastName, emailAddress, phoneNumber, title, dob, address1, address2, postCode, postCity, termsCheckBox;
    let firstName, lastName, emailAddress, phoneNumber, title, dob, address1, address2, postCode, postCity;

    TTRTV2Lander = JSON.parse(localStorage.getItem("TTRTV2Lander"));

    if (TTRTV2Lander.firstName && TTRTV2Lander.lastName && TTRTV2Lander.emailAddress && TTRTV2Lander.phoneNumber && TTRTV2Lander.title && TTRTV2Lander.dob && TTRTV2Lander.address1 && TTRTV2Lander.address2 && TTRTV2Lander.postCity && TTRTV2Lander.postCode) {
      console.log(TTRTV2Lander.phoneNumber)
      step4Data.append("mobile_phone", TTRTV2Lander.phoneNumber);
      step5Data.append("mobile_phone", TTRTV2Lander.phoneNumber);
      step6Data.append("mobile_phone", TTRTV2Lander.phoneNumber);
      document.getElementById("clientForm").style.display = "none";
      document.getElementById("signatureForm").style.display = "block";
      window.scrollTo({ top: 0, behavior: "smooth" });
      // document.getElementById("signatureForm").scrollIntoView({
      //   behavior: "smooth",
      //   block: "center",
      // });
    } else {
      this.cardBtnArrowTarget.classList.add("hidden");
      this.cardBtnSpinnerTarget.classList.remove("hidden");
      firstName = this.firstNameTarget.value;
      lastName = this.lastNameTarget.value;
      emailAddress = this.emailAddressTarget.value;
      phoneNumber = this.phoneNumberTarget.value;
      let day = this.selectDayTarget.value;
      let month = this.selectMonthTarget.value;
      let year = this.selectDobYearTarget.value;
      title = this.titleTarget.value;
      dob = `${year}-${month}-${day}`;
      // termsCheckBox = document.querySelector('.checkmark-input').checked;
      TTRTV2Lander.firstName = firstName;
      TTRTV2Lander.lastName = lastName;
      TTRTV2Lander.emailAddress = emailAddress;
      TTRTV2Lander.phoneNumber = phoneNumber;
      TTRTV2Lander.title = title;
      TTRTV2Lander.dob = dob;
      localStorage.setItem("TTRTV2Lander", JSON.stringify(TTRTV2Lander));
      let cardModal = this.cardModalTarget;
      let signatureModal = this.signatureModalTarget;
      cardModal.style.display = "none";
      signatureModal.style.display = "block";
      this.claimerNameTargets.forEach((elem) => {
        elem.innerHTML = `${title} ${lastName}`;
      });

      if (
        emailAddress != "" &&
        /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(emailAddress) &&
        firstName != "" &&
        lastName != "" &&
        /^(01|07)\d{9}$/.test(phoneNumber) &&
        phoneNumber != "" &&
        dob != "" &&
        document.querySelector(".idpc-error").style.display == "none"
      ) {
        address1 = this.addressLine1Target.innerHTML;
        address2 =
          this.addressLine2Target.innerHTML +
          " " +
          this.addressLine3Target.innerHTML;
        postCode = this.addressPostCodeTarget.innerHTML;
        postCity = this.addressPostTownTarget.innerHTML;

        TTRTV2Lander.address1 = address1;
        TTRTV2Lander.address2 = address2;
        TTRTV2Lander.postCode = postCode;
        TTRTV2Lander.postCity = postCity;
        localStorage.setItem("TTRTV2Lander", JSON.stringify(TTRTV2Lander));
        step4Data.append("mobile_phone", phoneNumber);
        step5Data.append("mobile_phone", phoneNumber);
        step6Data.append("mobile_phone", phoneNumber);
        
        step3Data.append("title", title);
        step3Data.append("first_name", firstName);
        step3Data.append("last_name", lastName);
        step3Data.append("email", emailAddress);
        step3Data.append("mobile_phone", phoneNumber);
        step3Data.append("date_of_birth", dob);
        step3Data.append("address1", address1);
        step3Data.append("address2", address2);
        step3Data.append("postcode", postCode);
        step3Data.append("city", postCity);
        step3Data.append("template_id", templateId);
        step3Data.append("step", "3");
        sourceRef && step3Data.append("source_ref", sourceRef);
        currentPage = 3;
        TTRTV2Lander.currentPage = currentPage;
        localStorage.setItem("TTRTV2Lander", JSON.stringify(TTRTV2Lander));

        Rails.ajax({
          url: `/onboarding_ttrt_v2_update`,
          type: "patch",
          data: step3Data,
          success: function (data) {
            console.log(data);

            document.getElementById("clientForm").style.display = "none";
            document.getElementById("signatureForm").style.display = "block";
            window.scrollTo({ top: 0, behavior: "smooth" });
            // document.getElementById("signatureForm").scrollIntoView({
            //   behavior: "smooth",
            //   block: "center",
            // });
          },
          error: function (data) {},
        });
      }
    }

    //  Initialize signature
    const canvas = document.querySelector("canvas");
    canvas.style.width='100%';
    canvas.style.height='100%';
    canvas.width  = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;

    if (canvas) {
      //this.resizeCanvas(canvas);
      this.signature_pad = new SignaturePad(canvas, { 
        drawOnly: true,
        onEnd: function() {
          hasSignature = true;
        },
      });
    }
  }
  
  resizeCanvas(){
    var isNotMobile = true; //initiate as true
    // device detection
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
        isNotMobile = false;
    }
    if(isNotMobile && document.getElementById("signatureForm").style.display == "block") {
      const canvas = document.querySelector("canvas");
      if (hasSignature) {
        signatureCopy = this.signature_pad.toDataURL();
      }
      canvas.style.width='100%';
      canvas.style.height='100%';
      var ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext("2d").scale(ratio, ratio);
      if (hasSignature) {
        this.signature_pad.fromDataURL(signatureCopy);
      } else {
        this.signature_pad.clear();
      }
    }
  }
  
  signature(event) {
    TTRTV2Lander = JSON.parse(localStorage.getItem("TTRTV2Lander"));
    if (TTRTV2Lander.signature) {
      let signature = TTRTV2Lander.signature;
      step4Data.append("mobile_phone", TTRTV2Lander.phoneNumber);
      step5Data.append("mobile_phone", TTRTV2Lander.phoneNumber);
      step6Data.append("mobile_phone", TTRTV2Lander.phoneNumber);
      document.getElementById("signatureForm").style.display = "none";
      document.getElementById("insuranceForm").style.display = "block";
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      let signatureModal = this.signatureModalTarget;
      let insuranceModal = this.insuranceModalTarget;
      let dots = this.signature_pad.toData();

      // let checkConsentone = this.checkConsentOneTarget.checked
      // let checkConsentwo = this.checkConsentTwoTarget.checked;
      // let checkConsentthree = this.checkConsentThreeTarget.checked;
      // let checkConsentfour = this.checkConsentFourTarget.checked;

      if (this.signature_pad.isEmpty()) {
        event.preventDefault();
        this.signatureErrorTarget.classList.remove("hide");
        this.signatureErrorTarget.innerHTML = "Please sign before proceeding";
      } else if (dots && dots[0].points.length <= 10) {
        event.preventDefault();
        this.signatureErrorTarget.classList.remove("hide");
        this.signatureErrorTarget.innerHTML =
          "Please add a full signature. Clear and try again";
      }
      // else if (!checkConsentone && !checkConsentwo && !checkConsentthree && !checkConsentfour){
      //   event.preventDefault();
      //   this.signatureErrorTarget.classList.remove('hide');
      //   this.signatureErrorTarget.innerHTML = 'Please tick all the boxes below to proceed';
      // }
      // else if(!document.querySelector('.signature-checkbox').checked){
      //   event.preventDefault();
      //   this.signatureErrorTarget.classList.remove('hide');
      //   this.signatureErrorTarget.innerHTML = 'Please tick the box below to proceed';
      // }
      else {
        this.signatureBtnArrowTarget.classList.add("hidden");
        this.signatureBtnSpinnerTarget.classList.remove("hidden");
        event.preventDefault();
        TTRTV2Lander.signature = this.signature_pad.toDataURL();
        localStorage.setItem("TTRTV2Lander", JSON.stringify(TTRTV2Lander));
        step4Data.append("signature", this.signature_pad.toDataURL());
        step4Data.append("template_id", templateId);
        step4Data.append("step", "4");
        Rails.ajax({
          url: `/onboarding_ttrt_v2_update`,
          type: "patch",
          data: step4Data,
          success: function (data) {
            if (TTRTV2Lander) {
              currentPage = 4;
              TTRTV2Lander.currentPage = currentPage;
              localStorage.setItem("TTRTV2Lander", JSON.stringify(TTRTV2Lander));
            }
            signatureModal.style.display = "none";
            insuranceModal.style.display = "block";
            window.scrollTo({ top: 0, behavior: "smooth" });
            // document.getElementById("insuranceForm").scrollIntoView({
            //   behavior: "smooth",
            //   block: "center",
            // });
          },
          error: function (data) {
            console.log(data);
          },
        });
      }
    }
  }

  submitInsuranceDetails(e) {
    let ni_number;
    let insuranceModal;
    let lenderModal;

    TTRTV2Lander = JSON.parse(localStorage.getItem("TTRTV2Lander"));

    if (TTRTV2Lander.ni_number) {
      ni_number = TTRTV2Lander.ni_number;
      step4Data.append("mobile_phone", TTRTV2Lander.phoneNumber);
      step5Data.append("mobile_phone", TTRTV2Lander.phoneNumber);
      step6Data.append("mobile_phone", TTRTV2Lander.phoneNumber);

      document.getElementById("insuranceForm").style.display = "none";
      document.getElementById("lenderForm").style.display = "block";
    } else {
      this.insuranceBtnArrowTarget.classList.add("hidden");
      this.insuranceBtnSpinnerTarget.classList.remove("hidden");
      ni_number =
        document.querySelector("#nin-char-2").value +
        document.querySelector("#nin-number-1").value +
        document.querySelector("#nin-number-2").value +
        document.querySelector("#nin-number-3").value +
        document.querySelector("#nin-char-1").value;
      TTRTV2Lander.ni_number = ni_number;
      localStorage.setItem("TTRTV2Lander", JSON.stringify(TTRTV2Lander));

      insuranceModal = this.insuranceModalTarget;
      lenderModal = this.lenderModalTarget;

      // let clientFullName = this.clientFullNameTarget;

      step5Data.append("ni_number", ni_number.toUpperCase().replace(/ /g, ""));
      step5Data.append("template_id", templateId);
      step5Data.append("step", "5");

      Rails.ajax({
        url: `/onboarding_ttrt_v2_update`,
        type: "patch",
        data: step5Data,
        success: function (data) {
          if (TTRTV2Lander) {
            currentPage = 5;
            TTRTV2Lander.currentPage = currentPage;
            localStorage.setItem("TTRTV2Lander", JSON.stringify(TTRTV2Lander));
          }
          insuranceModal.style.display = "none";
          lenderModal.style.display = "block";
          window.scrollTo({ top: 0, behavior: "smooth" });
        },
        error: function (data) {
          console.log(data);
        },
      });
    }
    // let title =  step1Data.get('title') != '' ? step1Data.get('title') : "" ;
    // let first_name =  step1Data.get('first_name') != '' ? step1Data.get('first_name') : "" ;
    // let last_name =  step1Data.get('last_name') != '' ? step1Data.get('last_name') : "" ;
    // let dob = new Date(step1Data.get('date_of_birth'));
    // let day = dob.getDate();
    // day = day < 10 ? '0' + day : '' + day;
    // let month = dob.getMonth() + 1;
    // month = month < 10 ? '0' + month : '' + month;
    // let year = dob.getFullYear();
    // document.getElementById('full_name').textContent = title + ' ' + first_name + ' ' + last_name ;
    // document.getElementById('address1').textContent = step1Data.get('address1') ;
    // document.getElementById('address2').textContent = step1Data.get('address2') ;
    // document.getElementById('detailspostcity').textContent = step1Data.get('city');
    // document.getElementById('detailspostcode').textContent = step1Data.get('postcode');
    // document.getElementById('dob').textContent = day + '/' + month + '/' + year;
    // document.getElementById('ni_number').textContent = step7Data.get('ni_number');
    // document.getElementById('detailsconsentForm').scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'start'
    // });

    // this.scrollToTop();
  }

  submitEmployment() {
    let extraInfo;
    let lendersArray = [];
    let payoutYearsArray = [];
    TTRTV2Lander = JSON.parse(localStorage.getItem("TTRTV2Lander"));

    if (TTRTV2Lander.extraInfo) {
      step4Data.append("mobile_phone", TTRTV2Lander.phoneNumber);
      step5Data.append("mobile_phone", TTRTV2Lander.phoneNumber);
      step6Data.append("mobile_phone", TTRTV2Lander.phoneNumber);
      document.getElementById("insuranceForm").style.display = "none";
      document.getElementById("thanksForm").style.display = "block";
    } else {
      this.lenderBtnArrowTarget.classList.add("hidden");
      this.lenderBtnSpinnerTarget.classList.remove("hidden");
      this.lenderDropdownTargets.forEach((elem) => {
        if (elem.value) {
          lendersArray.push(elem.value);
        }
      });
      this.payoutYearTargets.forEach((elem) => {
        if (elem.value) {
          payoutYearsArray.push(elem.value);
        }
      });
      extraInfo = $.map(lendersArray, function(v, i) {
        return [v, payoutYearsArray[i]];
      });
      extraInfo = extraInfo.toString();
      TTRTV2Lander.extraInfo = extraInfo;
      localStorage.setItem("TTRTV2Lander", JSON.stringify(TTRTV2Lander));
      let lenderModal = this.lenderModalTarget;
      let thanksModal = this.thanksModalTarget;

      step6Data.append("extra_info", extraInfo);
      step6Data.append("template_id", templateId);
      step6Data.append("step", "6");

      Rails.ajax({
        url: `/onboarding_ttrt_v2_update`,
        type: "patch",
        data: step6Data,
        success: function (data) {
          if (TTRTV2Lander) {
            currentPage = 6;
            TTRTV2Lander.currentPage = currentPage;
            localStorage.setItem("TTRTV2Lander", JSON.stringify(TTRTV2Lander));
          }

          lenderModal.style.display = "none";
          thanksModal.style.display = "block";
          window.scrollTo({ top: 0, behavior: "smooth" });
        },
        error: function (data) {
          console.log(data);
        },
      });
    }
  }

  displaySearchAddress() {
    document.querySelector(".idpc-button").click();
    this.waitForElement(".idpc-select").then((elm) => {
      document
        .querySelector(".idpc-select")
        .setAttribute("data-action", "onboarding-ttrt-v2#populateAddress");
      document.querySelector(".idpc-select").classList.add("mt-4", "w-full");
      document.querySelector(".idpc-select").style.cssText =
        "--tw-ring-color: #F8F8F8; border-color: darkgray; padding-top: 15px; padding-bottom: 15px; border-radius: 4px;";
      document.querySelector(".idpc-button").style.cssText =
        "background-color: #6998ab;";
    });

    if (!document.querySelector(".idpc-select")) {
      this.clearPostalAddress();
    }

    this.waitForElement(".idpc-button").then((element) => {
      let select_address = document.querySelector(".idpc-select-container");
      this.swapFields(element, select_address);
      element.style.cssText = "background-color: #A1BFC6;";
      document.querySelector(".idpc-button").style.cssText = "background-color: #6998ab;";
      let spanElement = document.createElement("span");
      spanElement.classList.add("lg:ml-10", "ml-22", "-mr-1", "h-5", "w-5", "mb-1", "pt-1.5", "text-right");
      let fontElement = document.createElement("i");
      fontElement.classList.add("fas", "fa-arrow-right");
      spanElement.appendChild(fontElement);
      if (element.childElementCount > 0) {
        element.removeChild(spanElement);
      }
      this.findPostAddressTarget.appendChild(spanElement);
      element.appendChild(spanElement);
    });
    // document.querySelector('.idpc-select').setAttribute('data-onboarding-ppi-target', 'addressSelector');
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    // this.checkQuestion();
  }

  waitForElement(selector) {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }

  populateAddress() {
    this.addressLine1Target.innerHTML = this.line1Target.value;
    this.addressLine2Target.innerHTML = this.line2Target.value;
    this.addressLine3Target.innerHTML = this.line3Target.value;
    this.addressPostTownTarget.innerHTML = this.postTownTarget.value;
    this.addressPostCodeTarget.innerHTML = this.postCodeTarget.value;
    let title = this.titleTarget.value;
    let firstName = this.firstNameTarget.value;
    let lastName = this.lastNameTarget.value;
    let emailAddress = this.emailAddressTarget.value;
    let phoneNumber = this.phoneNumberTarget.value;
    let day = this.selectDayTarget.value;
    let month = this.selectMonthTarget.value;
    let year = this.selectDobYearTarget.value;
    let allDetailsVerified = true;

    if (
      firstName == "" ||
      lastName == "" ||
      day == "" ||
      month == "" ||
      year == "" ||
      title == ""
    ) {
      allDetailsVerified = false;
    }

    if (
      emailAddress == "" ||
      !/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(emailAddress)
    ) {
      allDetailsVerified = false;
    }

    if (phoneNumber == "" || !/^(01|07)\d{9}$/.test(phoneNumber)) {
      allDetailsVerified = false;
    }

    // if(!(document.querySelector('.checkmark-input').checked)) {
    //   allDetailsVerified = false;
    // }

    if (this.addressLine1Target.innerHTML === "") {
      allDetailsVerified = false;
    }

    if (allDetailsVerified == false) {
      this.submitClientDetailsBtnTarget.setAttribute("disabled", true);
      this.submitClientDetailsBtnTarget.classList.add("disabled-button");
      this.submitClientDetailsBtnTarget.classList.remove("enabled-button");
    } else {
      console.log("good to go");
      this.submitClientDetailsBtnTarget.removeAttribute("disabled");
      this.submitClientDetailsBtnTarget.classList.remove("disabled-button");
      this.submitClientDetailsBtnTarget.classList.add("enabled-button");
    }
  }

  clearPostalAddress() {
    this.addressLine1Target.innerHTML = "";
    this.addressLine2Target.innerHTML = "";
    this.addressLine3Target.innerHTML = "";
    this.addressPostTownTarget.innerHTML = "";
    this.addressPostCodeTarget.innerHTML = "";
  }

  handlePostalAddress() {
    if (document.querySelector(".idpc-select")) {
      document.querySelector(".idpc-select").style.display = "none";
      this.addressLine1Target.innerHTML = "";
      this.addressLine2Target.innerHTML = "";
      this.addressLine3Target.innerHTML = "";
      this.addressPostTownTarget.innerHTML = "";
      this.addressPostCodeTarget.innerHTML = "";
      this.submitClientDetailsBtnTarget.setAttribute("disabled", true);
      this.submitClientDetailsBtnTarget.classList.add("disabled-button");
      this.submitClientDetailsBtnTarget.classList.remove("enabled-button");
    }
  }

  showContent(e) {
    if (
      e.currentTarget.closest("dl").childNodes[3].classList.contains("hidden")
    ) {
      e.currentTarget.closest("dl > dt > button").childNodes[1].style.color =
        "#47c9a7";
      e.currentTarget.closest("dl > dt > button").style.color = "#47c9a7";
      e.currentTarget
        .closest("dl > dt > button")
        .childNodes[3].classList.add("hidden");
      e.currentTarget
        .closest("dl > dt > button")
        .childNodes[5].classList.remove("hidden");
      e.currentTarget.closest("dl").childNodes[3].classList.remove("hidden");
    } else {
      e.currentTarget.closest("dl > dt > button").childNodes[1].style.color =
        "#233E97";
      e.currentTarget.closest("dl > dt > button").style.color = "#233E97";
      e.currentTarget
        .closest("dl > dt > button")
        .childNodes[3].classList.remove("hidden");
      e.currentTarget
        .closest("dl > dt > button")
        .childNodes[5].classList.add("hidden");
      e.currentTarget.closest("dl").childNodes[3].classList.add("hidden");
    }
  }

  updateValue(e) {
    const min = e.currentTarget.min
    const max = e.currentTarget.max
    const val = e.currentTarget.value
    const newVal = Number(((val - min) * 100) / (max - min));
    
    e.currentTarget.style.backgroundSize = `${newVal}% 100%`;
    this.rangeOutputTarget.innerHTML = `£ ${e.currentTarget.value}`;
    this.rangeOutputWrapperTarget.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;

    if(e.currentTarget.value === "0") {
      this.claimProcessStartBtnTarget.setAttribute("disabled", true);
      this.claimProcessStartBtnTarget.classList.add("disabled-button");
      this.claimProcessStartBtnTarget.classList.remove("enabled-button");
    } else {
      this.claimProcessStartBtnTarget.removeAttribute("disabled");
      this.claimProcessStartBtnTarget.classList.add("enabled-button");
      this.claimProcessStartBtnTarget.classList.remove("disabled-button");
    }
  }

  totalAmountField(e) {
    if (e.target.value >= 1) {
      if (e.target.value <= 50000) {
        this.claimProcessStartBtnTarget.removeAttribute("disabled");
        this.claimProcessStartBtnTarget.classList.add("enabled-button");
        this.claimProcessStartBtnTarget.classList.remove("disabled-button");
        // this.rangeTarget.value = e.currentTarget.value;
        // this.rangeTarget.style.backgroundSize = `${(this.rangeTarget.value - this.rangeTarget.min) * 100 / (this.rangeTarget.max - this.rangeTarget.min)}% 100%`;
        // this.rangeOutputTarget.innerHTML = `£ ${e.currentTarget.value}`;
        // this.rangeOutputWrapperTarget.style.left=`calc(${(this.rangeTarget.value - this.rangeTarget.min) * 100 / (this.rangeTarget.max - this.rangeTarget.min)}% + (${2 - (this.rangeTarget.value - this.rangeTarget.min) * 100 / (this.rangeTarget.max - this.rangeTarget.min) * 0.15}px))`;
      } else {
        this.claimProcessStartBtnTarget.setAttribute("disabled", true);
        this.claimProcessStartBtnTarget.classList.add("disabled-button");
        this.claimProcessStartBtnTarget.classList.remove("enabled-button");    
      }
    } else {
      // this.rangeTarget.value = 0;
      // this.rangeTarget.style.backgroundSize = '0% 100%'
      this.claimProcessStartBtnTarget.setAttribute("disabled", true);
      this.claimProcessStartBtnTarget.classList.add("disabled-button");
      this.claimProcessStartBtnTarget.classList.remove("enabled-button");
      // this.rangeOutputTarget.innerHTML = `£ 0`;
      // this.rangeOutputWrapperTarget.style.left=`0px`;
    }
  }

  amountField(e) {
    if (e.target.value.length > 0) {
      let tax = e.target.value * (11 / 100);
      this.amountDeductedTarget.value = tax.toFixed(2);
    } else {
      this.amountDeductedTarget.value = "";
    }
    if (
      e.target.value.length > 0 &&
      this.selectedYearsTarget.value.length > 2
    ) {
      this.submitCompensationBtnTarget.removeAttribute("disabled");
      this.submitCompensationBtnTarget.classList.add("enabled-button");
      this.submitCompensationBtnTarget.classList.remove("disabled-button");
    } else {
      this.submitCompensationBtnTarget.setAttribute("disabled", true);
      this.submitCompensationBtnTarget.classList.add("disabled-button");
      this.submitCompensationBtnTarget.classList.remove("enabled-button");
    }
  }

  validateDetailConsent() {
    let allDetailsVerified = true;
    let checkConsentone = this.checkConsentOneTarget.checked;
    let checkConsentwo = this.checkConsentTwoTarget.checked;
    let checkConsentthree = this.checkConsentThreeTarget.checked;
    let checkConsentfour = this.checkConsentFourTarget.checked;

    if (
      checkConsentone &&
      checkConsentwo &&
      checkConsentthree &&
      checkConsentfour
    ) {
      console.log("good to go");
      this.signatureBtnTarget.removeAttribute("disabled");
      this.signatureBtnTarget.classList.remove("disabled-button");
      this.signatureBtnTarget.classList.add("enabled-button");
    } else {
      this.signatureBtnTarget.setAttribute("disabled", true);
      this.signatureBtnTarget.classList.add("disabled-button");
      this.signatureBtnTarget.classList.remove("enabled-button");
    }
  }

  validateClientDetails(e) {
    let allDetailsVerified = true;
    let firstName = this.firstNameTarget.value;
    let lastName = this.lastNameTarget.value;
    let emailAddress = this.emailAddressTarget.value;
    let phoneNumber = this.phoneNumberTarget.value;
    let day = this.selectDayTarget.value;
    let month = this.selectMonthTarget.value;
    let year = this.selectDobYearTarget.value;
    let title = this.titleTarget.value;
    switch (e.currentTarget.id) {
      case "title":
        this.titleTarget.classList.add("selected-option");
        break;
      case "day":
        this.selectDayTarget.classList.add("selected-option");
        break;
      case "month":
        this.selectMonthTarget.classList.add("selected-option");
        break;
      case "year":
        this.selectDobYearTarget.classList.add("selected-option");
        break;
    }

    if (
      firstName == "" ||
      lastName == "" ||
      day == "" ||
      month == "" ||
      year == "" ||
      title == ""
    ) {
      allDetailsVerified = false;
    }

    if (
      emailAddress == "" ||
      !/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(emailAddress)
    ) {
      allDetailsVerified = false;
    }

    if (phoneNumber == "" || !/^(01|07)\d{9}$/.test(phoneNumber)) {
      allDetailsVerified = false;
    }

    // if(!(document.querySelector('.checkmark-input').checked)) {
    //   allDetailsVerified = false;
    // }

    if (this.addressLine1Target.innerHTML === "") {
      allDetailsVerified = false;
    }

    if (allDetailsVerified == false) {
      this.submitClientDetailsBtnTarget.setAttribute("disabled", true);
      this.submitClientDetailsBtnTarget.classList.add("disabled-button");
      this.submitClientDetailsBtnTarget.classList.remove("enabled-button");
    } else {
      console.log("good to go");
      this.submitClientDetailsBtnTarget.removeAttribute("disabled");
      this.submitClientDetailsBtnTarget.classList.remove("disabled-button");
      this.submitClientDetailsBtnTarget.classList.add("enabled-button");
    }
  }

  validateEmailAddress(e) {
    let emailAddress = e.target.value;
    let firstName = this.firstNameTarget.value;
    let lastName = this.lastNameTarget.value;
    let phoneNumber = this.phoneNumberTarget.value;
    let day = this.selectDayTarget.value;
    let month = this.selectMonthTarget.value;
    let year = this.selectDobYearTarget.value;
    let allDetailsVerified = true;

    document.querySelector(".required-email-error").innerHTML = "Hello";
    document.querySelector(".required-email-error").style.cssText =
      "opacity: 0";
    setTimeout(() => {
      if (emailAddress == "") {
        document.querySelector(".required-email-error").style.cssText =
          "opacity: 1";
        document.querySelector(".required-email-error").style.cssText =
          "color: #E10000;";
        document.querySelector(".required-email-error").innerHTML =
          "Please, enter your email";
      } else if (!/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(emailAddress)) {
        document.querySelector(".required-email-error").style.cssText =
          "opacity: 1";
        document.querySelector(".required-email-error").style.cssText =
          "color: #E10000;";
        document.querySelector(".required-email-error").innerHTML =
          "Email is not correct";
      } else {
        document.querySelector(".required-email-error").innerHTML = "Hello";
        document.querySelector(".required-email-error").style.cssText =
          "opacity: 0";
      }
    }, 1000);

    if (
      firstName == "" ||
      lastName == "" ||
      day == "" ||
      month == "" ||
      year == ""
    ) {
      allDetailsVerified = false;
    }

    if (
      emailAddress == "" ||
      !/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(emailAddress)
    ) {
      allDetailsVerified = false;
    }

    if (phoneNumber == "" || !/^(01|07)\d{9}$/.test(phoneNumber)) {
      allDetailsVerified = false;
    }

    // if(!(document.querySelector('.checkmark-input').checked)) {
    //   allDetailsVerified = false;
    // }

    if (this.addressLine1Target.innerHTML === "") {
      allDetailsVerified = false;
    }

    if (allDetailsVerified == false) {
      this.submitClientDetailsBtnTarget.setAttribute("disabled", true);
      this.submitClientDetailsBtnTarget.classList.add("disabled-button");
      this.submitClientDetailsBtnTarget.classList.remove("enabled-button");
    } else {
      console.log("good to go");
      this.submitClientDetailsBtnTarget.removeAttribute("disabled");
      this.submitClientDetailsBtnTarget.classList.remove("disabled-button");
      this.submitClientDetailsBtnTarget.classList.add("enabled-button");
    }
  }

  validatePhoneNumber(e) {
    let phoneNumber = e.target.value;
    let firstName = this.firstNameTarget.value;
    let lastName = this.lastNameTarget.value;
    let emailAddress = this.emailAddressTarget.value;
    let day = this.selectDayTarget.value;
    let month = this.selectMonthTarget.value;
    let year = this.selectDobYearTarget.value;
    let allDetailsVerified = true;

    document.querySelector(".user-phonenumber-error").innerHTML = "Hello";
    document.querySelector(".user-phonenumber-error").style.cssText =
      "opacity: 0";

    setTimeout(() => {
      if (phoneNumber == "" || !/^(01|07)\d{9}$/.test(phoneNumber)) {
        document.querySelector(".user-phonenumber-error").style.cssText =
          "opacity: 1";
        document.querySelector(".user-phonenumber-error").style.cssText =
          "color: #E10000;";
        document.querySelector(".user-phonenumber-error").innerHTML =
          "Please enter a valid phone number";
      } else {
        document.querySelector(".user-phonenumber-error").innerHTML = "Hello";
        document.querySelector(".user-phonenumber-error").style.cssText =
          "opacity: 0";
      }
    }, 1000);

    if (
      firstName == "" ||
      lastName == "" ||
      day == "" ||
      month == "" ||
      year == ""
    ) {
      allDetailsVerified = false;
    }

    if (
      emailAddress == "" ||
      !/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(emailAddress)
    ) {
      allDetailsVerified = false;
    }

    if (phoneNumber == "" || !/^(01|07)\d{9}$/.test(phoneNumber)) {
      allDetailsVerified = false;
    }

    // if(!(document.querySelector('.checkmark-input').checked)) {
    //   allDetailsVerified = false;
    // }

    if (this.addressLine1Target.innerHTML === "") {
      allDetailsVerified = false;
    }

    if (allDetailsVerified == false) {
      this.submitClientDetailsBtnTarget.setAttribute("disabled", true);
      this.submitClientDetailsBtnTarget.classList.add("disabled-button");
      this.submitClientDetailsBtnTarget.classList.remove("enabled-button");
    } else {
      console.log("good to go");
      this.submitClientDetailsBtnTarget.removeAttribute("disabled");
      this.submitClientDetailsBtnTarget.classList.remove("disabled-button");
      this.submitClientDetailsBtnTarget.classList.add("enabled-button");
    }
  }

  validateNiNumber(e) {
    let regex = new RegExp(
      "^(?!BG|GB|KN|NK|NT|TN|ZZ)[^DFIQUV][^DFIQUVo][0-9]{6}[ABCD]$"
    );

    if (e.target.value.length == 2) {
      let class_lists = Object.values(e.target.classList);
      let class_list = class_lists.find((val) => val.includes("inputs"));
      let val = parseInt(class_list.split("_").pop()) + 1;
      document.querySelector(`.inputs_${val}`).focus();
    }

    let ni_number =
      document.querySelector("#nin-char-2").value +
      document.querySelector("#nin-number-1").value +
      document.querySelector("#nin-number-2").value +
      document.querySelector("#nin-number-3").value +
      document.querySelector("#nin-char-1").value;

    if (ni_number.length === 9 && this.stripNi(ni_number).match(regex)) {
      this.enableInsuranceBtnTarget.removeAttribute("disabled");
      this.enableInsuranceBtnTarget.classList.remove("disabled-button");
      this.enableInsuranceBtnTarget.classList.add("enabled-button");
    } else {
      this.enableInsuranceBtnTarget.setAttribute("disabled", true);
      this.enableInsuranceBtnTarget.classList.add("disabled-button");
      this.enableInsuranceBtnTarget.classList.remove("enabled-button");
    }
  }

  canvasDraw() {
    // this.signatureBtnTarget.classList.remove('disabled-button');
    // this.signatureBtnTarget.classList.add('enabled-button');
    // this.signatureBtnTarget.removeAttribute('disabled');
    this.signHerePlaceholderTarget.classList.add("hidden");
  }

  stripNi(ni_value) {
    return ni_value.toUpperCase().replace(/ /g, "");
  }

  clear() {
    hasSignature = false;
    this.signature_pad.clear();
    this.signHerePlaceholderTarget.classList.remove("hidden");
  }

  annualIncome(e) {
    const selectedIncomes = document.querySelectorAll(".annual-income-button");
    if (selectedIncomes.length > 0) {
      selectedIncomes.forEach((income) => {
        income.classList.remove("annual-income-button");
      });
    }

    if (
      Object.values(e.currentTarget.classList).indexOf(
        "annual-income-button"
      ) === -1
    ) {
      e.currentTarget.classList.add("annual-income-button");
      this.currentSalaryBracketTarget.value = e.currentTarget.innerHTML;
    }

    if (
      document.querySelectorAll(".employment-button").length > 0 &&
      document.querySelectorAll(".annual-income-button").length > 0
    ) {
      this.cardDetailsTarget.classList.remove("disabled-button");
      this.cardDetailsTarget.classList.add("enabled-button");
      this.cardDetailsTarget.removeAttribute("disabled");
    } else {
      this.cardDetailsTarget.classList.add("disabled-button");
      this.cardDetailsTarget.classList.remove("enabled-button");
      this.cardDetailsTarget.setAttribute("disabled", "true");
    }
  }

  selectLender() {
    this.payoutYearToggleTargets.forEach((elem) => {
      elem.style.maxHeight = "500px";
      elem.style.transition = "max-height .3s ease-in";
    });
  }

  selectPayoutYear(e) {
    this.addLenderBtnTarget.removeAttribute("disabled");
    this.addLenderBtnTarget.classList.remove("disabled-button");
    this.addLenderBtnTarget.classList.add("bg-denim");

    this.employmentBtnTarget.classList.remove("disabled-button");
    this.employmentBtnTarget.classList.add("enabled-button");
    this.employmentBtnTarget.removeAttribute("disabled");
  }

  addLender() {
    const div = document.createElement("div");
    div.setAttribute("data-onboarding-ttrt-v2-target","addLenderForm");
    div.innerHTML = this.addLenderFormTarget.innerHTML;
    this.addLenderFormWrapperTarget.appendChild(div);

    if (this.payoutYearToggleTargets.length > 1){
      this.payoutYearToggleTargets[this.payoutYearToggleTargets.length - 1].style.maxHeight = "0";
    }

    this.removeLenderBtnTarget.classList.remove("hidden");

    this.addLenderBtnTarget.setAttribute("disabled", "true");
    this.addLenderBtnTarget.classList.add("disabled-button");
    this.addLenderBtnTarget.classList.remove("bg-denim");

    this.employmentBtnTarget.classList.add("disabled-button");
    this.employmentBtnTarget.classList.remove("enabled-button");
    this.employmentBtnTarget.setAttribute("disabled", "true");
  }

  removeLender() {
    if (this.addLenderFormTargets.length > 1){
      this.addLenderFormTargets[this.addLenderFormTargets.length - 1].remove();
      console.log(this.addLenderFormTargets);
    }
    if(this.addLenderFormTargets.length == 1) {
      this.removeLenderBtnTarget.classList.add("hidden");
      
      this.addLenderBtnTarget.removeAttribute("disabled");
      this.addLenderBtnTarget.classList.remove("disabled-button");
      this.addLenderBtnTarget.classList.add("bg-denim");
  
      this.employmentBtnTarget.classList.remove("disabled-button");
      this.employmentBtnTarget.classList.add("enabled-button");
      this.employmentBtnTarget.removeAttribute("disabled");
    }
  }

  employmentStatus(e) {
    const selectedEmployments = document.querySelectorAll(".employment-button");
    const annualIncomeToggle = this.annualIncomeToggleTarget;
    const employmentStatusToggle = this.employmentStatusToggleTarget;

    if (selectedEmployments.length > 0) {
      selectedEmployments.forEach((employment) => {
        employment.classList.remove("employment-button");
      });
    }
    if (
      Object.values(e.currentTarget.classList).indexOf("employment-button") ===
      -1
    ) {
      e.currentTarget.classList.add("employment-button");
      this.currentEmploymentTarget.value = e.currentTarget.innerHTML;
      // annualIncomeToggle.classList.remove("hidden");
      employmentStatusToggle.style.transition = "max-height .25s ease-in-out";
      employmentStatusToggle.style.maxHeight = "0";
      annualIncomeToggle.style.transition = "max-height .5s ease-in-out";
      annualIncomeToggle.style.maxHeight = "500px";
      this.cardDetailsTarget.classList.remove("hidden");
    }

    if (
      document.querySelectorAll(".employment-button").length > 0 &&
      document.querySelectorAll(".annual-income-button").length > 0
    ) {
      this.cardDetailsTarget.classList.remove("disabled-button");
      this.cardDetailsTarget.classList.add("enabled-button");
      this.cardDetailsTarget.removeAttribute("disabled");
    } else {
      this.cardDetailsTarget.classList.add("disabled-button");
      this.cardDetailsTarget.classList.remove("enabled-button");
      this.cardDetailsTarget.setAttribute("disabled", "true");
    }
  }

  removeArrayElement(arr, value) {
    return arr.filter(function (ele) {
      return ele != value;
    });
  }

  swapFields(element1, element2) {
    element2.parentNode.insertBefore(element2, element1);
  }
}
