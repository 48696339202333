import { Controller } from "stimulus"
import SignaturePad from "signature_pad";
export default class extends Controller {
  static targets = [ 'signature', 'modal', 'container', 'ni_number',  'error_title', 'error_text', 'error_text_container', 'error_container', 'icon', 'data_permission', 'terms', 'authorisation', 'additional_terms', 'part_one', 'part_two', 'paye' ]

  connect() {

      var canvas = document.querySelector("canvas");
      if (canvas){
      console.log('sig pad')
        //  Initialize signature
        canvas.style.width='100%';
        canvas.style.height='100%';
        canvas.width  = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;
      // The class we should toggle on the container
      this.toggleClass = this.data.get('class') || 'hidden';

      // The HTML for the background element
      this.backgroundHtml = this.data.get('backgroundHtml') || this._backgroundHTML();

      // The ID of the background to hide/remove
      this.backgroundId = this.data.get('backgroundId') || 'modal-background';

      // Let the user close the modal by clicking on the background
      this.allowBackgroundClose = (this.data.get('allowBackgroundClose') || 'true') === 'true';

      // Prevent the default action of the clicked element (following a link for example) when opening the modal
      this.preventDefaultActionOpening = (this.data.get('preventDefaultActionOpening') || 'true') === 'true';

      // Prevent the default action of the clicked element (following a link for example) when closing the modal
      this.preventDefaultActionClosing = (this.data.get('preventDefaultActionClosing') || 'true') === 'true';

     // let viewWidth = window.matchMedia("(max-width: 400px)");
     // this.mediaQuery(viewWidth); // Call listener function at run time
     // x.addListener(mediaQuery);
     // screen.add_event


      //this.resizeCanvas(canvas);
      this.signature_pad = new SignaturePad(canvas);
    }
  }

  clear() {
    this.signature_pad.clear();

  }
    checkTerms() {
        if (this.termsTarget.checked && this.data_permissionTarget.checked && this.additional_termsTarget.checked){
            this.error_containerTarget.classList.remove('show');
            this.error_containerTarget.classList.add('hide');
            return true;
        } else{
            this.showNotif('Please tick the boxes above to proceed', 'error');
            return false;
        }
    }

  submit(event) {
      var transferAuthorisation = document.getElementById('transfer_authorisation') || document.getElementById('data-permission')
      var processLater = document.getElementById('can_use_in_future') || document.getElementById('claim-authorisation')
      var acceptsTerms = document.getElementById('accepts_terms') || document.getElementById('terms-and-conditions')

    if (this.signature_pad.isEmpty()){

      event.preventDefault();
    } else if (this.data_permissionTarget.checked === true && this.termsTarget.checked === true && this.auhtorisationTarget.checked === true ){
          document.getElementById('signature').value = this.signature_pad.toDataURL()
        $('#new_signed_doc').submit();

      }
      else {
        alert('You must accept the agreement with Brooksdale Ltd & accept the Terms and Conditions');

      }

  }

    submit_single(event) {
        if (this.checkTerms() === false) {
            event.preventDefault();
        }
        else if (this.signature_pad.isEmpty()){
            event.preventDefault();
            this.showNotif('Please sign before continuing.', 'error')
        }
        else {
            event.preventDefault();
            document.getElementById('signature').value = this.signature_pad.toDataURL()
            document.querySelector('form').submit();
        }
    }

    submitOptOut(event) {

        $('#new_signed_doc').submit()
    }
    mediaQuery(x) {

      if (x.matches) { // If media query matches

          var canvas = document.querySelector("canvas")

          var ratio =  Math.max(window.devicePixelRatio || 1, 1);

          canvas.width = canvas.offsetWidth * ratio;

          canvas.height = canvas.offsetHeight * ratio;

          canvas.getContext("2d").scale(ratio, ratio);

      } else {

          document.body.style.backgroundColor = "pink";

      }

  }


  resizeCanvas(canvas) {
    var ratio =  Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);
    console.log('resize canvas')
  }
  stripNi(ni_value){
       console.log('strip ni value')
      return ni_value.toUpperCase().replace(/ /g,'')
  }

  checkNiOnType(){
      let regex = new RegExp("/\A[A-CEGHJ-PR-TW-Z]{1}[A-CEGHJ-NPR-TW-Z]{1}[0-9]{6}[A-D]{0,1}\z/i");
      if (this.ni_numberTarget.value.length >= 9){
          if(!this.ni_numberTarget.value.match(regex)){
              console.log('mismatch')
              // there is a mismatch, hence show the error message


              this.stripNi(this.ni_numberTarget.value);
          }
      }
  }

  nextField(){
    if(this.part_oneTarget.value.length === 3){
        this.part_oneTarget.style.border = '0.6155714392662048px solid #D8E1E5'
        this.part_twoTarget.focus()
    }
  }

  checkPartOne(){
          if (!(this.part_oneTarget.value.match(/^([0-9]{3})$/))) {
              this.part_oneTarget.style.border = '1px solid red'
              return false
          } else {
              this.part_oneTarget.style.border = '0.6155714392662048px solid #D8E1E5'
              return true
          }


  }

  checkPartTwo(){
      if (!(this.part_twoTarget.value.match(/^(\w{3,10})$/))){
          this.part_twoTarget.style.border = '1px solid red'
          return false
      } else {
          this.part_twoTarget.style.border = '0.6155714392662048px solid #D8E1E5'
          return true
      }
  }

  validateNiNumber(e){
      e.preventDefault()
      let ni_field = this.ni_numberTarget
      ni_field.value = this.stripNi(ni_field.value);
      let regex = new RegExp("^(?!BG|GB|KN|NK|NT|TN|ZZ)[^DFIQUV][^DFIQUVo][0-9]{6}[ABCD]$")
      if (ni_field.value.length < 1){
          console.log('blank')
          this.showNotif('Please add your full National Insurance number', 'error');
      }
      else if (ni_field.value.length < 9 ) {
          console.log('mismatch')
          // there is a mismatch, hence show the error message
          this.showNotif('Please add your full National Insurance number', 'error');
      }
       else if (!ni_field.value.match(regex) ) {
          console.log('mismatch')
          // there is a mismatch, hence show the error message
          this.showNotif('The format of your National Insurance number seems incorrect, please try again.', 'error');

        }
        
        else if (ni_field.value.match(regex)){
            console.log('good to go')
          // else, do not display message
          this.error_containerTarget.classList.remove('show');
          this.error_containerTarget.classList.add('hide');
          document.querySelector('form').submit();
        }
  }

  validatePaye(e){
    event.preventDefault();
    console.log('submitting')
    if(this.checkPartOne() && this.checkPartTwo()){
        this.error_containerTarget.classList.remove('show');
        this.error_containerTarget.classList.add('hide');
        let paye = `${this.part_oneTarget.value}/${this.part_twoTarget.value}`;
        this.payeTarget.value = paye
        document.querySelector('form').submit()

      } else if (!this.checkPartOne() || !this.checkPartTwo()){
            this.showNotif('Please check PAYE field is complete.', 'error');
      }
      else {
          this.showNotif('Please check PAYE reference is complete.', 'error');
      }
  }

  clearNi(){
    document.getElementById('ni_number').value = ''
    document.getElementById('ni_error').classList.add('hidden');
  }
    disconnect() {
        this.close();
    }

    open(e) {


        console.log('open')
        var transferAuthorisation = document.getElementById('transfer_authorisation')
        var processLater = document.getElementById('can_use_in_future')
        var acceptsTerms = document.getElementById('accepts_terms')
        if (transferAuthorisation.checked == true && processLater.checked == true && acceptsTerms.checked == true){
            document.getElementById('checkbox-errors').classList.add('hidden');

        if (this.preventDefaultActionOpening) {
            e.preventDefault();
        }

        e.target.blur();

        // Lock the scroll and save current scroll position
        this.lockScroll();

        // Unhide the modal
        this.containerTarget.classList.remove(this.toggleClass);

        // Insert the background
        if (!this.data.get("disable-backdrop")) {
            document.body.insertAdjacentHTML('beforeend', this.backgroundHtml);
            this.background = document.querySelector(`#${this.backgroundId}`);
        }

            let canvas = document.querySelector("canvas");
            this.newSigPad()
            this.resizeCanvas(canvas);
        }

        else {
            document.getElementById('checkbox-errors').classList.remove('hidden');

        }


        //if(window.innerHeight > window.innerWidth){
        //    alert("Please switch your phone to side to view then click 'Sign Now'.");
        //    this.close(e)
        //}
    }
    newSigPad(){
        let canvas = document.querySelector("canvas");
        canvas.height = canvas.offsetHeight || 300 ;
        canvas.width = canvas.offsetWidth|| 450 ;
        canvas.getContext("2d");
        this.signature_pad = new SignaturePad(canvas);

        this.resizeCanvas(canvas);
  }
    close(e) {
        if (e && this.preventDefaultActionClosing) {
            e.preventDefault();
        }
        //document.getElementById('overlay').classList.remove('overlay')
        // Unlock the scroll and restore previous scroll position
        this.unlockScroll();

        // Hide the modal
        this.containerTarget.classList.add(this.toggleClass);

        // Remove the background
        if (this.background) { this.background.remove() }
    }

    closeBackground(e) {
        if (this.allowBackgroundClose && e.target === this.containerTarget) {
            this.close(e);
        }
    }

    closeWithKeyboard(e) {
        if (e.keyCode === 27 && !this.containerTarget.classList.contains(this.toggleClass)) {
            this.close(e);
        }
    }

    _backgroundHTML() {
        return '<div id="modal-background" class="fixed top-0 left-0 w-full h-full" style="background-color: rgba(0, 0, 0, 0.8); z-index: 9998;"></div>';
    }

    lockScroll() {
        // Add right padding to the body so the page doesn't shift
        // when we disable scrolling
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
        document.body.style.paddingRight = `${scrollbarWidth}px`;

        // Save the scroll position
        this.saveScrollPosition();

        // Add classes to body to fix its position
        document.body.classList.add('fixed', 'inset-x-0', 'overflow-hidden');

        // Add negative top position in order for body to stay in place
        document.body.style.top = `-${this.scrollPosition}px`;
    }

    unlockScroll() {
        // Remove tweaks for scrollbar
        document.body.style.paddingRight = null;

        // Remove classes from body to unfix position
        document.body.classList.remove('fixed', 'inset-x-0', 'overflow-hidden');

        // Restore the scroll position of the body before it got locked
        this.restoreScrollPosition();

        // Remove the negative top inline style from body
        document.body.style.top = null;
    }

    limitIncome(element){
        var max_chars = 6;

        if(element.value.length > max_chars) {
            element.value = element.value.substr(0, max_chars);
        }
    }

    saveScrollPosition() {
        this.scrollPosition = window.pageYOffset || document.body.scrollTop;
    }

    restoreScrollPosition() {
        document.documentElement.scrollTop = this.scrollPosition;
    }

    showNotif(msg, type) {
        console.log('show notif')
        this.error_textTarget.innerHTML  = msg
        if (type === 'success'){
            this.error_containerTarget.classList.remove('bg-red-50')
            this.iconTarget.classList.remove('text-red-400')
            this.error_titleTarget.classList.remove('text-red-800')
            this.error_text_containerTarget.classList.remove('text-red-700')

            this.error_containerTarget.classList.add('bg-green-50')
            this.iconTarget.classList.add('text-green-400')
            this.error_titleTarget.classList.add('text-green-800')
            this.error_text_containerTarget.classList.add('text-green-700')


        } else {
            this.error_containerTarget.classList.add('bg-red-50')
            this.iconTarget.classList.add('text-red-400')
            this.error_titleTarget.classList.add('text-red-800')
            this.error_text_containerTarget.classList.add('text-red-700')

            this.error_containerTarget.classList.remove('bg-green-50')
            this.iconTarget.classList.remove('text-green-400')
            this.error_titleTarget.classList.remove('text-green-800')
            this.error_text_containerTarget.classList.remove('text-green-700')
        }
        this.error_containerTarget.classList.remove('hide');
        this.error_containerTarget.classList.add('show');

    }
}
