import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['customUrl','queryParams', 'textareaLink', 'errorMessage', 'pcpSubmitButton', 'runAt', 'datePicker'];

    connect(){
        console.log("pcp-mailer-loaded")
        this.runAtTarget.classList.add("hidden");
        this.datePickerTarget.setAttribute("required", "false");
    }
    queryparams(event){
        var domain_link = this.customUrlTarget.value;
        var query_parm = this.queryParamsTarget.value;
        domain_link = domain_link
        var link_concat = domain_link + query_parm
        console.log(link_concat)
        this.textareaLinkTarget.value = link_concat
        // const regex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[^\s#]*)[^#]*$/;

        // if (regex.test(link_concat)) {
        //     this.errorMessageTarget.classList.add('hidden');
        //     console.log("Valid URL");
        // } else {
        //     this.errorMessageTarget.classList.remove('hidden');
        //     console.log("Invalid URL");
        // }
    }
    handleChange(event) {
        console.log("Value changed:", event.target.value);
        if (event.target.value === "Send now") {
          this.runAtTarget.classList.add("hidden");
          this.datePickerTarget.setAttribute("required", "false");
        } else {
          this.runAtTarget.classList.remove("hidden");
          this.datePickerTarget.setAttribute("required", "true");
        }

    }
}