import { Controller } from "stimulus";
import SignaturePad from "signature_pad";
let data = new FormData();
export default class extends Controller {
  static targets = [
    "claimProcessStartBtn",
    "compensationPaymentAnswer",
    "hideable",
    "selectYear",
    "selectedYears",
    "tryAgainModal",
    "checkQuestionModal",
    "employmentModal",
    "workAllownceAnswer",
    "claimedAllownceAnswer",
    "cardDetails",
    "cardModal",
    "selectIndustry",
    "companyName",
    "submitClientDetailsBtn",
    "payeModal",
    "payeFirst",
    "payeSecond",
    "payeNumber",
    "cmpName",
    "detailsConsentModal",
    "checkConsentOne",
    "checkConsentTwo",
    "checkConsentThree",
    "checkConsentFour",
    "submitDetailConsentBtn",
    "signatureModal",
    "signHerePlaceholder",
    "signatureError",
    "thanksModal",
    "signatureBtn",
    "trueSignature",
    "workedYear2020",
    "workedYear2021",
    "reliefAmount"
  ];

  goToTop() {
    this.scrollAbc();
  }

  clearCss() {
    const selectedCompenstionPayment = document.querySelectorAll(
      ".selected-button"
    );
    if (selectedCompenstionPayment.length > 0) {
      selectedCompenstionPayment.forEach(answer => {
        answer.classList.remove("selected-button");
      });
    }
  }

  compenstionPaymentSelector(event) {
    this.clearCss();
    event.currentTarget.classList.add("selected-button");
    if (
      this.compensationPaymentAnswerTargets[0].classList.contains(
        "selected-button"
      )
    ) {
      this.claimProcessStartBtnTarget.setAttribute("disabled", "");
      this.claimProcessStartBtnTarget.classList.remove("enabled-button");
      this.claimProcessStartBtnTarget.classList.add("disabled-button");
      this.hideableTarget.hidden = false;
    } else {
      this.claimProcessStartBtnTarget.removeAttribute("disabled");
      this.claimProcessStartBtnTarget.classList.add("enabled-button");
      this.claimProcessStartBtnTarget.classList.remove("disabled-button");
      this.hideableTarget.hidden = true;
    }
  }

  multiSelector(event) {
    if (
      this.selectedYearsTarget.value
        .split(",")
        .indexOf(event.currentTarget.value) === -1
    ) {
      event.currentTarget.classList.add("selected-button");
      this.selectedYearsTarget.value =
        this.selectedYearsTarget.value + "," + event.currentTarget.value;
    } else {
      event.currentTarget.classList.remove("selected-button");
      let result = this.removeArrayElement(
        this.selectedYearsTarget.value.split(","),
        event.currentTarget.value
      );
      this.selectedYearsTarget.value = result.join(",");
    }

    if (this.selectedYearsTarget.value.length > 1) {
      this.claimProcessStartBtnTarget.removeAttribute("disabled");
      this.claimProcessStartBtnTarget.classList.remove("disabled-button");
      this.claimProcessStartBtnTarget.classList.add("enabled-button");
    } else {
      this.claimProcessStartBtnTarget.setAttribute("disabled", true);
      this.claimProcessStartBtnTarget.classList.add("disabled-button");
      this.claimProcessStartBtnTarget.classList.remove("enabled-button");
    }
  }

  removeArrayElement(arr, value) {
    return arr.filter(function(ele) {
      return ele != value;
    });
  }

  claimProcess() {
    this.checkQuestionModalTarget.style.display = "none";
    if (
      this.compensationPaymentAnswerTargets[0].classList.contains(
        "selected-button"
      )
    ) {
      this.employmentModalTarget.style.display = "block";
      document.getElementById("employmentForm").scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    } else {
      this.tryAgainModalTarget.style.display = "block";
      document.getElementById("tryAgainForm").scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    }
  }

  workAllownceSelector(event) {
    this.clearCss();
    event.currentTarget.classList.add("selected-button");
    if (
      document.querySelectorAll(".selected-button").length > 0 &&
      document.querySelectorAll(".employment-button").length > 0
    ) {
      this.cardDetailsTarget.removeAttribute("disabled");
      this.cardDetailsTarget.classList.add("enabled-button");
      this.cardDetailsTarget.classList.remove("disabled-button");
    } else {
      this.cardDetailsTarget.setAttribute("disabled", "");
      this.cardDetailsTarget.classList.remove("enabled-button");
      this.cardDetailsTarget.classList.add("disabled-button");
    }
  }

  claimedAllownceSelector(event) {
    const selectedCompenstionPayment = document.querySelectorAll(
      ".employment-button"
    );
    if (selectedCompenstionPayment.length > 0) {
      selectedCompenstionPayment.forEach(answer => {
        answer.classList.remove("employment-button");
      });
    }

    event.currentTarget.classList.add("employment-button");
    if (
      document.querySelectorAll(".employment-button").length > 0 &&
      document.querySelectorAll(".selected-button").length > 0
    ) {
      this.cardDetailsTarget.removeAttribute("disabled");
      this.cardDetailsTarget.classList.add("enabled-button");
      this.cardDetailsTarget.classList.remove("disabled-button");
    } else {
      this.cardDetailsTarget.setAttribute("disabled", "");
      this.cardDetailsTarget.classList.remove("enabled-button");
      this.cardDetailsTarget.classList.add("disabled-button");
    }
  }

  submitCardDetails() {
    this.employmentModalTarget.style.display = "none";
    if (
      this.workAllownceAnswerTargets[1].classList.contains("selected-button") &&
      this.claimedAllownceAnswerTargets[1].classList.contains(
        "employment-button"
      )
    ) {
      this.clearCss();
      let selectedYears = this.selectedYearsTarget.value.split(",");
      if (selectedYears.includes("2020/2021")) {
        this.workedYear2020Target.value = true;
      } else {
        this.workedYear2020Target.value = false;
      }
      if (selectedYears.includes("2021/2022")) {
        this.workedYear2021Target.value = true;
      } else {
        this.workedYear2021Target.value = false;
      }
      if (this.workedYear2020Target.value && this.workedYear2021Target.value) {
        this.reliefAmountTarget.innerHTML = "£624";
      } else {
        this.reliefAmountTarget.innerHTML = "£312";
      }
      let work = {};
      work["claimed_allowance"] = false;
      work["covid_related"] = true;
      work["employer_contribution_wfh"] = 0;
      work["worked_2020"] = this.workedYear2020Target.value;
      work["worked_2021"] = this.workedYear2021Target.value;
      data.append("work_from_homes_attributes", JSON.stringify(work));

      this.cardModalTarget.style.display = "block";
      document.getElementById("clientForm").scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    } else {
      this.tryAgainModalTarget.style.display = "block";
      document.getElementById("tryAgainForm").scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    }
  }

  validateClientDetails() {
    if (
      !this.companyNameTarget.value == "" &&
      this.companyNameTarget.value.length > 2 &&
      this.selectIndustryTarget.value
    ) {
      this.submitClientDetailsBtnTarget.removeAttribute("disabled");
      this.submitClientDetailsBtnTarget.classList.add("enabled-button");
      this.submitClientDetailsBtnTarget.classList.remove("disabled-button");
    } else {
      this.submitClientDetailsBtnTarget.setAttribute("disabled", "");
      this.submitClientDetailsBtnTarget.classList.remove("enabled-button");
      this.submitClientDetailsBtnTarget.classList.add("disabled-button");
    }
  }

  submitPersonalDetails() {
    this.cmpNameTarget.innerHTML = this.companyNameTarget.value;
    this.cardModalTarget.style.display = "none";
    const selectedCompenstionPayment = document.querySelectorAll(
      ".selected-button"
    );
    if (selectedCompenstionPayment.length > 0) {
      selectedCompenstionPayment.forEach(answer => {
        answer.classList.remove("selected-button");
      });
    }
    this.payeModalTarget.style.display = "block";
    document.getElementById("payeForm").scrollIntoView({
      behavior: "smooth",
      block: "center"
    });
  }

  openPrivacyPolicy() {
    var dialogPrivacyPolicy = document.getElementById("dialogPrivacyPolicy");
    var overlayPrivacyPolicy = document.getElementById("overlayPrivacyPolicy");
    dialogPrivacyPolicy.classList.remove("hidden");
    overlayPrivacyPolicy.classList.remove("hidden");
  }

  closePrivacyPolicy() {
    var dialogPrivacyPolicy = document.getElementById("dialogPrivacyPolicy");
    var overlayPrivacyPolicy = document.getElementById("overlayPrivacyPolicy");
    dialogPrivacyPolicy.classList.add("hidden");
    overlayPrivacyPolicy.classList.add("hidden");
  }

  submitPayeDetails() {
    if (this.payeFirstTarget.value && this.payeSecondTarget.value) {
      this.payeNumberTarget.innerHTML =
        this.payeFirstTarget.value + "/" + this.payeSecondTarget.value;
    }

    this.cmpNameTargets[1].innerHTML = this.companyNameTarget.value;
    if (this.workedYear2020Target.value) {
      this.workedYear2020Target.innerHTML = "2020/2021";
      let obj = {};
      obj["employer_name"] = this.companyNameTarget.value;
      obj["start_date"] = "06/04/2020";
      obj["end_date"] = "05/04/2021";
      obj["tax_year"] = "2020/2021";
      data.append("employments_attributes[]", JSON.stringify(obj));
    }
    if (this.workedYear2021Target.value) {
      this.workedYear2021Target.innerHTML = "2021/2022";
      let obj = {};
      obj["employer_name"] = this.companyNameTarget.value;
      obj["start_date"] = "06/04/2021";
      obj["end_date"] = "05/04/2022";
      obj["tax_year"] = "2021/2022";
      data.append("employments_attributes[]", JSON.stringify(obj));
    }
    this.payeModalTarget.style.display = "none";
    this.detailsConsentModalTarget.style.display = "block";
    document.getElementById("payeForm").scrollIntoView({
      behavior: "smooth",
      block: "center"
    });
  }

  validateDetailConsent() {
    let checkConsentone = this.checkConsentOneTarget.checked;
    let checkConsentwo = this.checkConsentTwoTarget.checked;
    let checkConsentthree = this.checkConsentThreeTarget.checked;
    let checkConsentfour = this.checkConsentFourTarget.checked;

    if (
      checkConsentone &&
      checkConsentwo &&
      checkConsentthree &&
      checkConsentfour
    ) {
      this.submitDetailConsentBtnTarget.removeAttribute("disabled");
      this.submitDetailConsentBtnTarget.classList.remove("disabled-button");
      this.submitDetailConsentBtnTarget.classList.add("enabled-button");
    } else {
      this.submitDetailConsentBtnTarget.setAttribute("disabled", true);
      this.submitDetailConsentBtnTarget.classList.add("disabled-button");
      this.submitDetailConsentBtnTarget.classList.remove("enabled-button");
    }
  }

  openTermsConditions() {
    var dialogTermsConditions = document.getElementById(
      "dialogTermsConditions"
    );
    var overlayTermsConditions = document.getElementById(
      "overlayTermsConditions"
    );
    dialogTermsConditions.classList.remove("hidden");
    overlayTermsConditions.classList.remove("hidden");
  }

  closeTermsConditions() {
    var dialogTermsConditions = document.getElementById(
      "dialogTermsConditions"
    );
    var overlayTermsConditions = document.getElementById(
      "overlayTermsConditions"
    );
    dialogTermsConditions.classList.add("hidden");
    overlayTermsConditions.classList.add("hidden");
  }

  submitDetailConsent() {
    const canvas = document.querySelector("canvas");
    if (canvas) {
      this.signature_pad = new SignaturePad(canvas, { drawOnly: true });
    }
    this.detailsConsentModalTarget.style.display = "none";
    this.signatureModalTarget.style.display = "block";
    document.getElementById("signatureForm").scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
    this.scrollAbc();
  }

  scrollAbc() {
    window.scrollTo({ top: 100, behavior: "smooth" });
  }

  openAuthoriseForm() {
    var authoriseForm = document.getElementById("authoriseForm");
    var authoriseOverlay = document.getElementById("authoriseOverlay");
    authoriseForm.classList.remove("hidden");
    authoriseOverlay.classList.remove("hidden");
  }

  closeAuthoriseForm() {
    var authoriseForm = document.getElementById("authoriseForm");
    var authoriseOverlay = document.getElementById("authoriseOverlay");
    authoriseForm.classList.add("hidden");
    authoriseOverlay.classList.add("hidden");
  }

  canvasDraw() {
    this.signHerePlaceholderTarget.classList.add("hidden");
    if (this.trueSignatureTarget.checked) {
      this.signatureBtnTarget.removeAttribute("disabled");
      this.signatureBtnTarget.classList.remove("disabled-button");
      this.signatureBtnTarget.classList.add("enabled-button");
    }
  }

  clear() {
    this.signature_pad.clear();
    this.signHerePlaceholderTarget.classList.remove("hidden");
    this.signatureBtnTarget.setAttribute("disabled", true);
    this.signatureBtnTarget.classList.add("disabled-button");
    this.signatureBtnTarget.classList.remove("enabled-button");
  }

  validateTrueSignature() {
    if (this.trueSignatureTarget.checked && !this.signature_pad.isEmpty()) {
      this.signatureBtnTarget.removeAttribute("disabled");
      this.signatureBtnTarget.classList.remove("disabled-button");
      this.signatureBtnTarget.classList.add("enabled-button");
    } else {
      this.signatureBtnTarget.setAttribute("disabled", true);
      this.signatureBtnTarget.classList.add("disabled-button");
      this.signatureBtnTarget.classList.remove("enabled-button");
    }
  }
  signature(event) {
    let dots = this.signature_pad.toData();
    let signatureModal = this.signatureModalTarget;
    let thanksModal = this.thanksModalTarget;
    let urlAddress = window.location.href.split("/");
    let templateId = window.location.href.split("/").pop();
    let clientPostPackCode = urlAddress[urlAddress.length - 2];
    data.append("template_id", templateId);
    data.append("paye", this.payeNumberTarget.innerHTML);

    if (this.signature_pad.isEmpty()) {
      event.preventDefault();
      this.signatureErrorTarget.classList.remove("hide");
      this.signatureErrorTarget.innerHTML = "Please sign before proceeding";
    } else if (dots && dots[0].points.length <= 10) {
      event.preventDefault();
      this.signatureErrorTarget.classList.remove("hide");
      this.signatureErrorTarget.innerHTML =
        "Please add a full signature. Clear and try again";
    } else if (!document.querySelector(".signature-checkbox").checked) {
      event.preventDefault();
      this.signatureErrorTarget.classList.remove("hide");
      this.signatureErrorTarget.innerHTML =
        "Please tick the box below to proceed";
    } else {
      data.append("signature", this.signature_pad.toDataURL());
      Rails.ajax({
        url: `/onboarding_ttrt_wfh_update/${clientPostPackCode}`,
        type: "patch",
        data: data,
        success: function(data) {
          signatureModal.style.display = "none";
          thanksModal.style.display = "block";
          document.getElementById("thanksForm").scrollIntoView({
            behavior: "smooth",
            block: "center"
          });
        },
        error: function(data) {}
      });
    }
  }
}
